import style from "./maint.module.css"

const Maintenance = () => {
    return (
        <>
        <div className={style.contenedor}>
            <div className={style.principal}>
                <div className={style.divImagenes}>
                <img className={style.imagen}src="https://res.cloudinary.com/dfmsezslx/image/upload/v1696731569/TECDE/Tecde_vertical_color_black_m98pgy.png" alt="logoTECDE" />
                <img className={style.imagen}src="https://res.cloudinary.com/dfmsezslx/image/upload/v1700522308/TECDE/RefriActive_Img_zfhpl2.png" alt="logoRefri" />
                </div>
            <h1 className={style.titulo}>APLICACIÓN EN MANTENIMIENTO</h1>
            </div>
        </div>
        </>
    )
}

export default Maintenance