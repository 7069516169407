import { ALL_REPORTS, FILTER_REPORTS, SEARCH_REPORTS, COMBINED_FILTER_REPORTS, CLEAR_FILTER_REPORTS, ALERT_SESSION } from "../action-types.js";
import axios from "axios"
import {url, urlFront} from "../../App.js";


export const getAllReports = () => {
    return async (dispatch) => {
        try {
            const config = {
                method: 'get',
                url: `${url}/reports/`,
                headers: {
                    'Content-Type': 'application/json',
                    'X-Is-Public': 'true',
                },
                withCredentials: true,
            };
            const response = await axios(config)
            dispatch({
                type: ALL_REPORTS,
                payload: response.data
            })        
        } catch (error) {
            const errorResponse = {};
            errorResponse.error = error.message;
            if(error.response.status === 401) {
                dispatch({
                    type: ALERT_SESSION,
                    payload: true
                })

                setTimeout(() => {
                    localStorage.clear('globalState')
                    window.location.replace(urlFront)
                }, [3000])
                
            }  
            return errorResponse;    
        } 
    }
}
export const getFilterReports = (filtros) => {
    return async (dispatch) => {
        try {
            const config = {
                method: 'post',
                url: `${url}/reports/filter`,
                headers: {
                    'Content-Type': 'application/json',
                    'X-Is-Public': 'true',
                },
                withCredentials: true,
                data: filtros
            };
            const response = await axios(config)
            if (response.status === 200) {
                dispatch({
                    type: FILTER_REPORTS,
                    payload: response.data.reports
                })
            }
            else if (response.status === 204) {
                dispatch({
                    type: FILTER_REPORTS,
                    payload: ["Sin resultados"]
                })
            }
        } catch (error) {
            const errorResponse = {};
            errorResponse.error = error.message;
            if(error.response.status === 401) {
                dispatch({
                    type: ALERT_SESSION,
                    payload: true
                })

                setTimeout(() => {
                    localStorage.clear('globalState')
                    window.location.replace(urlFront)
                }, [3000])
                
            }  
            return errorResponse;    
        } 
    }
}
export const getSearchReports = (partialId) => {
    return async (dispatch) => {
        try {
            const config = {
                method: 'get',
                url: `${url}/reports/search/${partialId}`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            };
            const response = await axios(config)
            if (response.status === 200) {
                dispatch({
                    type: SEARCH_REPORTS,
                    payload: response.data.reports
                })
            }
            else if (response.status === 204) {
                dispatch({
                    type: SEARCH_REPORTS,
                    payload: ["Sin resultados"]
                })
            }
        } catch (error) {
            const errorResponse = {};
            errorResponse.error = error.message;
            if(error.response.status === 401) {
                dispatch({
                    type: ALERT_SESSION,
                    payload: true
                })

                setTimeout(() => {
                    localStorage.clear('globalState')
                    window.location.replace(urlFront)
                }, [3000])
                
            }  
            return errorResponse;    
        } 
    }
}

export const clearFilterReports = () => {
    return {
      type: CLEAR_FILTER_REPORTS,
    };
  };