import style from "./preBills.module.css"
import NavBar from "../../components/NavBar/NavBar"
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom"
import { useState, useEffect } from "react"
import * as actions from "../../redux/actions"

const MyPreBills = () => {
    const userData = useSelector((state) => state.userLoginNow)
    const myId = userData.id
    const [myQuotes, setMyQuotes] = useState([]);
    const dispatch = useDispatch()
    const allClients = useSelector((state) => state.allClients)
    const allStores = useSelector((state) => state.allStores)
    const allCitys = useSelector((state) => state.allCitys)
    const allPreBills = useSelector((state) => state.allPreBill)
    const data = allPreBills
    const sortedData = data !== undefined && data.length > 0 && [...data].sort((a, b) => new Date(b.aplicationDate) - new Date(a.aplicationDate));
    // UseEffect 
    useEffect(()=> {
      dispatch(actions.allPreBills())
    }, [])
    useEffect(()=> {
      const userQuotes = sortedData !== undefined && sortedData.length > 0 && sortedData.filter((quote) => quote.userId === myId);
      const userSort = userQuotes !== undefined && userQuotes.length > 0 && userQuotes.sort((a, b) => new Date(b.aplicationDate) - new Date(a.aplicationDate));
      setMyQuotes(userSort);
    }, [])
    // Paginado
    const [pagina, setPagina] = useState(1)
    const porPagina = 8
    const ultimoElemento = pagina*porPagina
    const primerElemento = ultimoElemento - porPagina
    const actualQuotes = myQuotes !== undefined && myQuotes.length > 0 && myQuotes.slice(primerElemento, ultimoElemento)
    const totalPages = Math.ceil(myQuotes.length / porPagina);
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);}
    useEffect(() => {setPagina(1)}, [totalPages]);
    const maxPaginasAMostrar = 4; // Número máximo de páginas que se mostrarán en la barra de paginación
    const inicioPaginas = pagina <= Math.ceil(maxPaginasAMostrar / 2) ? 1 : Math.max(1, pagina - Math.floor(maxPaginasAMostrar / 2))
    const finPaginas = Math.min( inicioPaginas + maxPaginasAMostrar - 1,totalPages);
    // Traer nombres 
    const getClientName = (allClients, clientId) => {
    const client = allClients.find((client) => client.id === clientId);
    return client ? client.name : 'Cliente desconocido';
    };
    const getStoreName = (allStores, storeId) => {
    const store = allStores.find((store) => store.id === storeId);
    return store ? store.name : 'Tienda desconocido';};
      const getCityName = (allCitys, cityId) => {
          const city = allCitys && allCitys.find((city) => city.id === cityId);
          return city ? city.city : 'Ciudad Desconocida';
      };
    return (
        <div className={style.divContenedor}>
                  <div className={style.divNavBar}>
        <NavBar />
      </div>
            <div className={style.divPrincipal}>
                <h1 className={style.tituloSeccion}>PreFacturas</h1>
                <h4 className={style.subtitulo}>Mis prefacturas</h4>
                <div className={style.divTabla}>
                  <h5 className={style.subtituloTabla}>Prefacturas creadas por {userData.name + " " + userData.surname}</h5>
                  <table className={style.table}>
            <tr className={style.tr}>
              <th className={style.th}>#</th>
              <th className={style.th}>Reporte</th>
              <th className={style.th}>Cotización</th>
              <th className={style.th}>Fecha</th>
              <th className={style.th}>Cliente</th>
              <th className={style.th}>Ciudad</th>
              <th className={style.th}>Almacen</th>
              <th className={style.th}>Total</th>
              <th className={style.th}>Acciones</th>
              <th className={style.thres}>#</th>
              <th className={style.thres}>Cotización</th>
              <th className={style.thres}>Fecha</th>
              <th className={style.thres}>Cliente</th>
              <th className={style.thres}>Almacen</th>
              <th className={style.thres}>Total</th>
              <th className={style.thres}>Acciones</th>
            </tr>
            {actualQuotes !== undefined && actualQuotes.length > 0 && actualQuotes.map((orden) => (
              <tr className={style.tr} key={orden.id}>
                <td className={style.td}>0{orden.id}</td>
                <td className={style.td}>{orden.reportId}</td>
                <td className={style.td}>{orden.quoteId}</td>
                <td className={style.td}>{orden.date}</td>
                <td className={style.td}>{getClientName(allClients, orden.clientId)}</td>
                <td className={style.td}>{getCityName(allCitys, orden.cityId)}</td>
                <td className={style.td}>{getStoreName(allStores, orden.storeId)}</td>
                <td className={style.td}>$ {orden.total.toLocaleString()}</td>
                <td className={style.tdres}>{orden.id}</td>
                <td className={style.tdres}>{orden.quoteId}</td>
                <td className={style.tdres}>
                  {(() => {
                    var fechaString = orden.aplicationDate;
                    var fecha = new Date(fechaString);
                    var mes = fecha.getMonth() + 1;
                    var dia = fecha.getDate();
                    var nuevaFechaString = (mes < 10 ? '0' : '') + mes + '-' + (dia < 10 ? '0' : '') + dia;
                    return nuevaFechaString;
                  })()}
                </td>
                <td className={style.tdres}>{getClientName(allClients, orden.clientId)}</td>
                <td className={style.tdres}>{getStoreName(allStores, orden.storeId)}</td>
                <td className={style.tdres}>$ {orden.total.toLocaleString()}</td>
                <td className={style.tdetail}>
                  <Link to={`/preBills/view/${orden.id}`}>
                    <button className={style.botonUpdate}><img className={style.icon} src="https://api.iconify.design/material-symbols:open-in-new.svg?color=%23313372" alt="editbuttom" /></button>
                  </Link>
                  <Link to={`/preBills/${orden.id}`}>
                    <button className={style.botonUpdate}><img className={style.icon} src="https://api.iconify.design/material-symbols:edit.svg?color=%23313372" alt="editbuttom" /></button>
                  </Link>
                </td>
              </tr>))}
          </table>
                    {sortedData !== undefined && sortedData.length > 0 && 
                    <div className={style.paginado}>
                    <button className= {style.botonpag} onClick={() => setPagina(1)} disabled={pagina === 1}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:move-last-rtl.svg?color=%23313372" alt="editbuttom" /></button>
                    <button className= {style.botonpag} onClick={() => setPagina(pagina - 1)} disabled={pagina === 1}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:previous-ltr.svg?color=%23313372" alt="editbuttom" /></button>
                    {pageNumbers.slice(inicioPaginas - 1, finPaginas).map((pageNumber) => (
                    <button key={pageNumber} className={pageNumber === pagina ? style.pagina : style.paginaboton} onClick={() => setPagina(pageNumber)}>{pageNumber}</button>))}
                    <button className= {style.botonpag} onClick={() => setPagina(pagina + 1)} disabled={ultimoElemento >= sortedData.length}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:previous-rtl.svg?color=%23313372" alt="editbuttom" /></button>
                    <button className= {style.botonpag} onClick={() => setPagina(totalPages)} disabled={ultimoElemento >= sortedData.length}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:move-last-ltr.svg?color=%23313372" alt="editbuttom" /></button>
                  </div>}
                    <Link to="/preBills">
                    <button type="button" className={style.backbutton}>Volver</button>
                    </Link>
                </div>
            </div>
        </div>
    )
}
export default MyPreBills