import { ALERT_SESSION, GET_CITYS, GET_DEPARTMENTS } from "../action-types";
import axios from "axios"
import {url, urlFront} from "../../App.js";

export const getDepartments = () => {
    return async (dispatch) => {
        try {
            const config = {
                method: 'get',
                url: `${url}/departments/`,
                headers: {
                    'Content-Type': 'application/json',
                    'X-Is-Public': 'true',
                },
                withCredentials: true,
            };
            const response = await axios(config)
            dispatch({
                type: GET_DEPARTMENTS,
                payload: response.data
            })
        } catch (error) {
            const errorResponse = {};
            errorResponse.error = error.message;
            if(error.response.status === 401) {
                dispatch({
                    type: ALERT_SESSION,
                    payload: true
                })

                setTimeout(() => {
                    localStorage.clear('globalState')
                    window.location.replace(urlFront)
                }, [3000])
                
            }  
            return errorResponse;    
        } 
    }
}

export const getCitys = () => {
    return async (dispatch) => {
        try {
            const config = {
                method: 'get',
                url: `${url}/citys/`,
                headers: {
                    'Content-Type': 'application/json',
                    'X-Is-Public': 'true',
                },
                withCredentials: true,
            };
            const response = await axios(config)
            dispatch({
                type: GET_CITYS,
                payload: response.data
            })
        } catch (error) {
            const errorResponse = {};
            errorResponse.error = error.message;
            if(error.response.status === 401) {
                dispatch({
                    type: ALERT_SESSION,
                    payload: true
                })

                setTimeout(() => {
                    localStorage.clear('globalState')
                    window.location.replace(urlFront)
                }, [3000])
                
            }  
            return errorResponse;    
        } 
    }
}