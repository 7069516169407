import style from "./profile.module.css"
import { useNavigate, Link } from "react-router-dom"
import { useState } from "react"
import { useForm, Controller } from 'react-hook-form';
import axios from "axios"
import { url, urlFront } from "../../../App";
import { ALERT_SESSION } from "../../../redux/action-types";
import AlertSession from "../../../components/AlertSession/AlertSession";
import { useDispatch, useSelector } from "react-redux";


const CreateProfile = () => {
    // Importations
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { handleSubmit, control, formState: { errors }, trigger, reset } = useForm()
    const alertSession = useSelector((state) => state.alertSession)
    // Local states
    const [confirmRegistro, setConfirmRegistro] = useState(null)
    const [formDisabled, setFormDisabled] = useState(null)
    // Funciones
    const onSubmit = async (data) => {
        const datos = data
        try {
            setFormDisabled(true);
            const config = {
                method: 'post',
                url: `${url}/technical/create`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                data: datos
            };
            const response = await axios(config)
            if (response.data) {
                if (response.data.success === true) {
                    setConfirmRegistro(true)
                }
                else {
                    setConfirmRegistro(false)
                }
            }
            setTimeout(() => {
                setConfirmRegistro(null);
                reset();
                setFormDisabled(false);
                setTimeout(() => {
                    navigate(`/reportes`);
                }, 100);
            }, 2000);
        } catch (error) {
            console.error("Error al crear perfil:", error);
            setFormDisabled(false);
            setConfirmRegistro(false)

            if(error.response.status === 401) {
                dispatch({
                    type: ALERT_SESSION,
                    payload: true
                })

                setTimeout(() => {
                    localStorage.clear('globalState')
                    window.location.replace(`${urlFront}/inicio`)
                }, [3000])
                
            }  
           
        }
    }
    // Renderizado
    return (
        <div className={style.divContenedor}>
            {alertSession && <AlertSession text1="Su sesión ha vencido" text2="Por favor, vuelva a iniciar sesión" disabledBtn={false}/>}
            <div className={style.divForm}>
                <img className={style.logo2} src="https://res.cloudinary.com/dfmsezslx/image/upload/v1700522308/TECDE/RefriActive_Img_zfhpl2.png" alt="LogoRefriActive" />
                <h2 className={style.tituloSeccion}>Crear perfil de técnico</h2>
                <p className={style.parrafo}>Rellene los campos para <strong>crear el perfil</strong></p>
                <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
                    <div className={style.rowform}>
                        <div className={style.divDatos}>
                            <div className={style.divCampo}>
                                <label className={style.label} htmlFor="name">Nombre completo</label>
                                <div className={style.divInput}>
                                    <Controller name="name"
                                        control={control}
                                        defaultValue={""}
                                        rules={{ required: 'Campo obligatorio', maxLength: { value: 100, message: 'Máximo 15 caracteres' }, minLength: { value: 4, message: 'Minimo 4 caracteres' } }}
                                        render={({ field }) => (
                                            <input className={style.input} type="text" {...field} onChange={(e) => { field.onChange(e); trigger("name"); }} />)} />
                                    <div className={style.errorMenssage}>
                                        {errors.name && <p className={style.errorText}>{errors.name.message}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className={style.divCampo}>
                                <label className={style.label} htmlFor="surname">Apellido completo</label>
                                <div className={style.divInput}>
                                    <Controller name="surname"
                                        control={control}
                                        defaultValue={""}
                                        rules={{ required: 'Campo obligatorio', maxLength: { value: 100, message: 'Máximo 100 caracteres' }, minLength: { value: 2, message: 'Minimo 2 caracteres' } }}
                                        render={({ field }) => (
                                            <input className={style.input} type="text" {...field} onChange={(e) => { field.onChange(e); trigger("name"); }} />)} />
                                    <div className={style.errorMenssage}>
                                        {errors.surname && <p className={style.errorText}>{errors.surname.message}</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="submit" className={style.formbutton} disabled={formDisabled} >Crear perfil</button>
                    {confirmRegistro === true ? (<><p className={style.positivo}>Perfil creado con éxito</p></>) : (confirmRegistro === false ? (<><p className={style.negativo} > No se pudo registrar, vuelve a intentarlo</p></>) : (null))}
                    <Link to="/reportes">
                        <button type="button" className={style.backbutton}>Volver</button>
                    </Link>
                </form>
            </div>
        </div>
    )
}
export default CreateProfile