import style from "./preBills.module.css"
import NavBar from "../../components/NavBar/NavBar"
import { NavLink, Link } from "react-router-dom"
import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/actions"
import axios from "axios";
import { url, urlFront } from "../../App";
import Filtro from "../../components/Filtro/Filtro"
import Search from "../../components/Search/Search";
import { ALERT_SESSION } from "../../redux/action-types";
import AlertSession from "../../components/AlertSession/AlertSession";


const PreBills = () => {
  const dispatch = useDispatch()
  const userData = useSelector((state) => state.userLoginNow)
  const allClients = useSelector((state) => state.allClients)
  const allStores = useSelector((state) => state.allStores)
  const allCitys = useSelector((state) => state.allCitys)
  const allPreBills = useSelector((state) => state.allPreBill)
  const filterQuotes = useSelector((state) => state.filterPreBills)
  const alertSession = useSelector((state) => state.alertSession)
  const [data, setData] = useState(allPreBills)
  const address = [
    { id: 1, address: "CR 7 CL 155 C 30 ED NORTH POINT TO EP 37 Y 38" },
    { id: 2, address: " " },
    { id: 3, address: " " },
    { id: 4, address: " " },
    { id: 5, address: " " },
    { id: 6, address: " " }
  ]
  useEffect(() => {
    if (filterQuotes !== undefined && filterQuotes.length > 0 && filterQuotes[0] !== "Sin resultados") {
      setData(filterQuotes)
    }
    else if (filterQuotes !== undefined && filterQuotes.length > 0 && filterQuotes[0] === "Sin resultados") {
      setData([])
    }
    else {
      setData(allPreBills)
    }
  }, [allPreBills, filterQuotes])
  // UseEffect 
  useEffect(() => {
    if (!allCitys || allCitys.length === 0) {
      dispatch(actions.allCitys())
    }
    if (!allClients || allClients.length === 0) {
      dispatch(actions.allClients())
    }
    if (!allStores || allStores.length === 0) {
      dispatch(actions.allStores())
    }
    if (!allPreBills || allPreBills.length === 0) {
      dispatch(actions.allPreBills())
    }
  }, [])
  // Traer nombres
  const getClientName = (allClients, clientId) => {
    const client = allClients.find((client) => client.id === clientId);
    return client ? client.name : 'Cliente desconocido';
  };
  const getStoreName = (allStores, storeId) => {
    const store = allStores.find((store) => store.id === storeId);
    return store ? store.name : 'Tienda desconocido';
  };
  const getCityName = (allCitys, cityId) => {
    const city = allCitys && allCitys.find((city) => city.id === cityId);
    return city ? city.city : 'Ciudad Desconocida';
  };
  useEffect(() => {
    dispatch(actions.allPreBills())
  }, [])
  const sortedData = data !== undefined && data.length > 0 && [...data].sort((a, b) => new Date(b.id) - new Date(a.id));
  // Paginado
  const [pagina, setPagina] = useState(1)
  const porPagina = 8
  const ultimoElemento = pagina * porPagina
  const primerElemento = ultimoElemento - porPagina
  const actualQuotes = sortedData !== undefined && sortedData.length > 0 && sortedData.slice(primerElemento, ultimoElemento)
  const totalPages = sortedData !== undefined && sortedData.length > 0 && Math.ceil(sortedData.length / porPagina);
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }
  useEffect(() => { setPagina(1) }, [totalPages]);
  const maxPaginasAMostrar = 4; // Número máximo de páginas que se mostrarán en la barra de paginación
  const inicioPaginas = pagina <= Math.ceil(maxPaginasAMostrar / 2) ? 1 : Math.max(1, pagina - Math.floor(maxPaginasAMostrar / 2))
  const finPaginas = Math.min(inicioPaginas + maxPaginasAMostrar - 1, totalPages);
  // Generar pdf
  const handleGeneratePDF = async (data) => {
    const urldata = url
    try {
      const idClient = data.clientId
      const direccionEncontrada = address.find(item => item.id === idClient);
      data.clientId = getClientName(allClients, data.clientId)
      const cityIdClient = data.clientData.cityId
      data.clientData.cityId = getCityName(allCitys, cityIdClient)
      data.store = getStoreName(allStores, data.storeId)
      data.clientData.adreess = direccionEncontrada.address
      const config = {
        method: 'get',
        url: `${urldata}/quotes/generate`,
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      };
      const res = await axios(config)
      const apiKey = res.data.respuesta
      const response = await fetch('https://api.prexview.com/v1/transform', {
        method: 'POST',
        headers: {
          'Authorization': apiKey,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          json: JSON.stringify(data),
          template: 'refriactiveprebills',
          output: 'pdf'
        })
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `PreFactura #${data.id}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    catch (error) {
      console.error('Error:', error);
      if(error.response.status === 401) {
        dispatch({
            type: ALERT_SESSION,
            payload: true
        })

        setTimeout(() => {
            localStorage.clear('globalState')
            window.location.replace(urlFront)
        }, [3000])
        
    }  
    }
  };
  // Renderizado
  return (
    <div className={style.divContenedor}>
      <div className={style.divNavBar}>
        <NavBar />
      </div>
      <div className={style.divPrincipal}>
      {alertSession && <AlertSession text1="Su sesión ha vencido" text2="Por favor, vuelva a iniciar sesión" disabledBtn={false}/>}
        <h1 className={style.tituloSeccion}>PreFacturas</h1>
        <div className={style.botones}>
          {/* <NavLink to={"/preBills/create"}>
                    <button disabled className={style.internSectionButtoms}>Crear prefactura</button>
                  </NavLink> */}
          <NavLink to={"/preBills/user/" + userData.id}>
            <button className={style.internSectionButtoms}> Mis prefacturas </button>
          </NavLink>
        </div>
        <Filtro section="preBills" />
        <Search section="preBills" />
        <div className={style.divTabla}>
          <h4 className={style.subtituloSeccion}>Ultimas prefacturas</h4>
          <table className={style.table}>
            <tr className={style.tr}>
              <th className={style.th}>#</th>
              <th className={style.th}>Reporte</th>
              <th className={style.th}>Cotización</th>
              <th className={style.th}>Fecha</th>
              <th className={style.th}>Cliente</th>
              <th className={style.th}>Ciudad</th>
              <th className={style.th}>Almacen</th>
              <th className={style.th}>Total</th>
              <th className={style.th}>Acciones</th>
              <th className={style.thres}>#</th>
              <th className={style.thres}>Cotización</th>
              <th className={style.thres}>Fecha</th>
              <th className={style.thres}>Cliente</th>
              <th className={style.thres}>Almacen</th>
              <th className={style.thres}>Total</th>
              <th className={style.thres}>Acciones</th>
            </tr>
            {actualQuotes !== undefined && actualQuotes.length > 0 && actualQuotes.map((orden) => (
              <tr className={style.tr} key={orden.id}>
                <td className={style.td}>0{orden.id}</td>
                <td className={style.td}>{orden.reportId}</td>
                <td className={style.td}>{orden.quoteId}</td>
                <td className={style.td}>{orden.date}</td>
                <td className={style.td}>{getClientName(allClients, orden.clientId)}</td>
                <td className={style.td}>{getCityName(allCitys, orden.cityId)}</td>
                <td className={style.td}>{getStoreName(allStores, orden.storeId)}</td>
                <td className={style.td}>$ {orden.total.toLocaleString()}</td>
                <td className={style.tdres}>{orden.id}</td>
                <td className={style.tdres}>{orden.quoteId}</td>
                <td className={style.tdres}>
                  {(() => {
                    var fechaString = orden.aplicationDate;
                    var fecha = new Date(fechaString);
                    var mes = fecha.getMonth() + 1;
                    var dia = fecha.getDate();
                    var nuevaFechaString = (mes < 10 ? '0' : '') + mes + '-' + (dia < 10 ? '0' : '') + dia;
                    return nuevaFechaString;
                  })()}
                </td>
                <td className={style.tdres}>{getClientName(allClients, orden.clientId)}</td>
                <td className={style.tdres}>{getStoreName(allStores, orden.storeId)}</td>
                <td className={style.tdres}>$ {orden.total.toLocaleString()}</td>
                <td className={style.tdetail}>
                  <Link to={`/preBills/view/${orden.id}`}>
                    <button className={style.botonUpdate}><img className={style.icon} src="https://api.iconify.design/material-symbols:open-in-new.svg?color=%23313372" alt="editbuttom" /></button>
                  </Link>
                  <Link to={`/preBills/${orden.id}`}>
                    <button className={style.botonUpdate}><img className={style.icon} src="https://api.iconify.design/material-symbols:edit.svg?color=%23313372" alt="editbuttom" /></button>
                  </Link>
                  <button className={style.botonUpdate} onClick={() => handleGeneratePDF(orden)}> <img className={style.icon} src="https://api.iconify.design/teenyicons:pdf-solid.svg?color=%23313372" alt="editbuttom" /></button>
                </td>
              </tr>))}
          </table>
          {filterQuotes[0] === "Sin resultados" &&
            <div className={style.paginado}>
              <p className={style.mensaje}>Sin resultados en la búsqueda</p>
            </div>}
          {sortedData !== undefined && sortedData.length > 0 &&
            <div className={style.paginado}>
              <button className={style.botonpag} onClick={() => setPagina(1)} disabled={pagina === 1}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:move-last-rtl.svg?color=%23313372" alt="editbuttom" /></button>
              <button className={style.botonpag} onClick={() => setPagina(pagina - 1)} disabled={pagina === 1}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:previous-ltr.svg?color=%23313372" alt="editbuttom" /></button>
              {pageNumbers.slice(inicioPaginas - 1, finPaginas).map((pageNumber) => (
                <button key={pageNumber} className={pageNumber === pagina ? style.pagina : style.paginaboton} onClick={() => setPagina(pageNumber)}>{pageNumber}</button>))}
              <button className={style.botonpag} onClick={() => setPagina(pagina + 1)} disabled={ultimoElemento >= sortedData.length}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:previous-rtl.svg?color=%23313372" alt="editbuttom" /></button>
              <button className={style.botonpag} onClick={() => setPagina(totalPages)} disabled={ultimoElemento >= sortedData.length}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:move-last-ltr.svg?color=%23313372" alt="editbuttom" /></button>
            </div>}
          <NavLink to="/preBills/all">
            <button className={style.backbutton}>Ver todo</button>
          </NavLink>
        </div>
      </div>
    </div>
  )
}


export default PreBills
