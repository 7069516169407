import style from "./bills.module.css"
import NavBar from "../../components/NavBar/NavBar"
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom"
import { useState, useEffect } from "react"
import * as actions from "../../redux/actions"
import Filtro from "../../components/Filtro/Filtro";
import Search from "../../components/Search/Search";


const AllBills = () => {
  const dispatch = useDispatch()
  const allQuotes = useSelector((state) => state.allQuotes)
  const allClients = useSelector((state) => state.allClients)
  const allStores = useSelector((state) => state.allStores)
  const allCitys = useSelector((state) => state.allCitys)
  const allPreBills = useSelector((state) => state.allBills)
  const filterQuotes = useSelector((state) => state.filterBills)
  const [data, setData] = useState(allPreBills)
  useEffect(() => {
    if (filterQuotes !== undefined && filterQuotes.length > 0 && filterQuotes[0] !== "Sin resultados") {
      setData(filterQuotes)
    }
    else if (filterQuotes !== undefined && filterQuotes.length > 0 && filterQuotes[0] === "Sin resultados") {
      setData([])
    }
    else {
      setData(allPreBills)
    }
  }, [allPreBills, filterQuotes])
  const sortedData = data !== undefined && data.length > 0 && [...data].sort((a, b) => new Date(b.id) - new Date(a.id));
  // UseEffect 
  useEffect(() => {
    if (!allCitys || allCitys.length === 0) {
      dispatch(actions.allCitys())
    }
    if (!allClients || allClients.length === 0) {
      dispatch(actions.allClients())
    }
    if (!allStores || allStores.length === 0) {
      dispatch(actions.allStores())
    }
  }, [])
  useEffect(() => {
    dispatch(actions.allBills())
  }, [])
  // Paginado
  const [pagina, setPagina] = useState(1)
  const porPagina = 10
  const ultimoElemento = pagina * porPagina
  const primerElemento = ultimoElemento - porPagina
  const actualQuotes = sortedData !== undefined && sortedData.length > 0 && sortedData.slice(primerElemento, ultimoElemento)
  const totalPages = sortedData !== undefined && sortedData.length > 0 && Math.ceil(sortedData.length / porPagina);
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }
  useEffect(() => { setPagina(1) }, [totalPages]);
  const maxPaginasAMostrar = 4; // Número máximo de páginas que se mostrarán en la barra de paginación
  const inicioPaginas = pagina <= Math.ceil(maxPaginasAMostrar / 2) ? 1 : Math.max(1, pagina - Math.floor(maxPaginasAMostrar / 2))
  const finPaginas = Math.min(inicioPaginas + maxPaginasAMostrar - 1, totalPages);
  // Traer nombres 
  const getClientName = (allClients, clientId) => {
    const client = allClients.find((client) => client.id === parseInt(clientId));
    return client ? client.name : 'Cliente desconocido';
  };
  const getStoreName = (allStores, storeId) => {
    const store = allStores.find((store) => store.id ===  parseInt(storeId));
    return store ? store.name : 'Tienda desconocido';
  };
  const getCityName = (allCitys, cityId) => {
    const city = allCitys && allCitys.find((city) => city.id ===  parseInt(cityId));
    return city ? city.city : 'Ciudad Desconocida';
  };
  // Renderizado
  return (
    <div className={style.divContenedor}>
      <div className={style.divNavBar}>
        <NavBar />
      </div>
      <div className={style.divPrincipal}>
        <h1 className={style.tituloSeccion}>Facturas</h1>
        <h4 className={style.subtitulo}>Todas las facturas</h4>
        <Filtro section="bills" />
        <Search section="bills" />
        <div className={style.divTabla}>
        <table className={style.table}>
            <tr className={style.tr}>
              <th className={style.th}>#</th>
              <th className={style.th}>Prefactura</th>
              <th className={style.th}>Cotización</th>
              <th className={style.th}>Fecha</th>
              <th className={style.th}>Cliente</th>
              <th className={style.th}>Almacen</th>
              <th className={style.th}>Total</th>
              <th className={style.th}>Anulada</th>
              <th className={style.th}>Acciones</th>
              <th className={style.thres}>#</th>
              <th className={style.thres}>Prefactura</th>
              <th className={style.thres}>Fecha</th>
              <th className={style.thres}>Cliente</th>
              <th className={style.thres}>Almacen</th>
              <th className={style.thres}>Total</th>
              <th className={style.thres}>Acciones</th>
            </tr>
            {actualQuotes !== undefined && actualQuotes.length > 0 && actualQuotes.map((orden) => (
              <tr className={style.tr} key={orden.id}>
                <td className={style.td}>{orden.id}</td>
                <td className={style.td}>{orden.preBillId === null ? "000": orden.preBillId }</td>
                <td className={style.td}>{orden.quoteId === null ? "000": orden.quoteId}</td>
                <td className={style.td}>{orden.data.date ? orden.data.date : (orden.data && orden.data.aplicationDate? orden.data.aplicationDate : null)}</td>
                <td className={style.td}>{getClientName(allClients, orden.clientId)}</td>
                <td className={style.td}>{getStoreName(allStores, orden.data.storeId)}</td>
                <td className={style.td}>$ {orden.data.total.toLocaleString()}</td>
                <td className={style.td} style={{ color: orden.deleted ? 'red' : 'green' }}>{orden.deleted ? ("Sí") : ("No")}</td>
                <td className={style.tdres}>{orden.id}</td>
                <td className={style.tdres}>{orden.preBillId}</td>
                <td className={style.tdres}>
                  {(() => {
                    var fechaString = orden.data.date;
                    var fecha = new Date(fechaString);
                    var mes = fecha.getMonth() + 1;
                    var dia = fecha.getDate();
                    var nuevaFechaString = (mes < 10 ? '0' : '') + mes + '-' + (dia < 10 ? '0' : '') + dia;
                    return nuevaFechaString;
                  })()}
                </td>
                <td className={style.tdres}>{getClientName(allClients, orden.clientId)}</td>
                <td className={style.tdres}>{getStoreName(allStores, orden.data.storeId)}</td>
                <td className={style.tdres}>$ {orden.data.total.toLocaleString()}</td>
                <td className={style.tdetail}>
                  <Link to={`/bills/view/${orden.id}`}>
                    <button className={style.botonUpdate}><img className={style.icon} src="https://api.iconify.design/material-symbols:open-in-new.svg?color=%23313372" alt="editbuttom" /></button>
                  </Link>
                  {/* <Link to={`/bills/${orden.id}`}>
                    <button className={style.botonUpdate}><img className={style.icon} src="https://api.iconify.design/material-symbols:edit.svg?color=%23313372" alt="editbuttom" /></button>
                  </Link> */}
                  {/* <button className={style.botonUpdate} onClick={() => handleGeneratePDF(orden)}> <img className={style.icon} src="https://api.iconify.design/teenyicons:pdf-solid.svg?color=%23313372" alt="editbuttom" /></button> */}
                </td>
              </tr>))}
          </table>
          {filterQuotes[0] === "Sin resultados" &&
            <div className={style.paginado}>
              <p className={style.mensaje}>Sin resultados en la búsqueda</p>
            </div>}
          {sortedData !== undefined && sortedData.length > 0 &&
            <div className={style.paginado}>
              <button className={style.botonpag} onClick={() => setPagina(1)} disabled={pagina === 1}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:move-last-rtl.svg?color=%23313372" alt="editbuttom" /></button>
              <button className={style.botonpag} onClick={() => setPagina(pagina - 1)} disabled={pagina === 1}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:previous-ltr.svg?color=%23313372" alt="editbuttom" /></button>
              {pageNumbers.slice(inicioPaginas - 1, finPaginas).map((pageNumber) => (
                <button key={pageNumber} className={pageNumber === pagina ? style.pagina : style.paginaboton} onClick={() => setPagina(pageNumber)}>{pageNumber}</button>))}
              <button className={style.botonpag} onClick={() => setPagina(pagina + 1)} disabled={ultimoElemento >= sortedData.length}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:previous-rtl.svg?color=%23313372" alt="editbuttom" /></button>
              <button className={style.botonpag} onClick={() => setPagina(totalPages)} disabled={ultimoElemento >= sortedData.length}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:move-last-ltr.svg?color=%23313372" alt="editbuttom" /></button>
            </div>}
          <div>
            <Link to="/bills">
              <button type="button" className={style.backbutton}>Volver</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
export default AllBills