import style from "./createBill.module.css"
import NavBar from "../../../components/NavBar/NavBar"
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom"
import { useState, useEffect } from "react"
import * as actions from "../../../redux/actions"
import axios from "axios"
import { url, urlFront } from "../../../App";
import { useForm, Controller } from 'react-hook-form';
import { ALERT_SESSION } from "../../../redux/action-types";
import AlertSession from "../../../components/AlertSession/AlertSession";


const ViewBill = () => {
  // Importations
  const dispatch = useDispatch()
  const { id } = useParams()
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Sumar 1 porque los meses van de 0 a 11
  const day = String(currentDate.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  const { handleSubmit, control, formState: { errors }, trigger, reset, watch, register, setValue } = useForm()
  const address = [
    { id: 1, address: "CR 7 CL 155 C 30 ED NORTH POINT TO EP 37 Y 38" },
    { id: 2, address: "CR 48 # 32B - 139" },
    { id: 3, address: "CL 66 # 65 b - 82" },
    { id: 4, address: "CR 69 B # 77 - 31" },
    { id: 5, address: "CR 16 # 100 - 20, PISO 7" },
    { id: 6, address: " " },
    { id: 7, address: " " },
    { id: 8, address: " " },
    { id: 9, address: " " },
    { id: 10, address: " " },
    { id: 11, address: " " }
  ]
  function getAddressById(addresses, id) {
    const addressObject = addresses.find(address => address.id === id);
    return addressObject ? addressObject.address : " ";
  }
  // Global States
  const userData = useSelector((state) => state.userLoginNow)
  const allClients = useSelector((state) => state.allClients)
  const allParts = useSelector((state) => state.allParts)
  const allCitys = useSelector((state) => state.allCitys)
  const allDepartments = useSelector((state) => state.allDepartments)
  const allStores = useSelector((state) => state.allStores)
  const alertSession = useSelector((state) => state.alertSession)
  let client = watch("clientId")
  // Local states
  const [formDisabled, setFormDisabled] = useState(null)
  const [filterCity, setFilterCity] = useState([])
  const [filterStore, setFilterStore] = useState([])
  const [actualClient, setActualClient] = useState("")
  const [selectedPart, setSelectedPart] = useState("");
  const [quantity, setQuantity] = useState("");
  const [serviceDescription, setServiceDescription] = useState("");
  const [servicePrice, setServicePrice] = useState("");
  const [quoteList, setQuoteList] = useState([])
  const [countItem, setCountItem] = useState(1)
  const [confirmRegistro, setConfirmRegistro] = useState(null);
  const [actualQuote, setActualQuote] = useState({})
  const [quote, setQuote] = useState({})
  // Get de quote
  const getActualQuote = async (preBillsId) => {
    try {
      setFormDisabled(true);
      const config = {
        method: 'get',
        url: `${url}/bills/${preBillsId}`,
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      };
      const response = await axios(config)
      if (response.data) {
        if (response.data.success === true) {
          const preBill = response.data.byId
          setActualQuote(preBill)
          setQuoteList(preBill.data.quoteParts)
        }
      }
    } catch (error) {
      console.error("Error al crear tienda:", error);
      setFormDisabled(false);
      setConfirmRegistro(false)

      if(error.response.status === 401) {
        dispatch({
            type: ALERT_SESSION,
            payload: true
        })

        setTimeout(() => {
            localStorage.clear('globalState')
            window.location.replace(urlFront)
        }, [3000])
        
    }  
    }
  }
  const getActualClient= async (id) => {
    const client = allClients.find((client) => client.id === parseInt(id));
    return client ? client : 'Cliente desconocido';
  }
  useEffect(() => {
    getActualQuote(id)
  }, [dispatch])
  useEffect(() => {
    setValue("comment", actualQuote.comment)
  }, [actualQuote, setValue]);
  useEffect(() => {
    // Calcula el máximo valor de 'item'
    const maxItem = Math.max(...quoteList.map(obj => obj.item), 0);
    setCountItem(maxItem + 1);
  }, [quoteList]);
  //UseEffect
  useEffect(() => {
    dispatch(actions.allStores())
    dispatch(actions.allClients())
    dispatch(actions.allParts())
  }, [dispatch])
  useEffect(() => {
    setActualClient(client)
  }, [client])
  useEffect(() => {
    const fetchClientData = async () => {
      if (actualQuote.data && !actualQuote.data.clientData) {
        const clientData = await getActualClient(actualQuote.clientId);
        setActualQuote((prevQuote) => ({
          ...prevQuote,
          data: {
            ...prevQuote.data,
            clientData,
          },
        }));
      }
    };
  
    fetchClientData();
  }, [actualQuote]);
  // Funciones
  const getClientName = (allClients, clientId) => {
    const client = allClients.find((client) => client.id === parseInt(clientId));
    return client ? client.name : 'Cliente desconocido';
  };
  const getStoreName = (allStores, storeId) => {
    const store = allStores.find((store) => store.id === parseInt(storeId));
    return store ? store.name : 'Tienda desconocido';
  };
  const getCityName = (allCitys, cityId) => {
    const city = allCitys && allCitys.find((city) => city.id === parseInt(cityId));
    return city ? city.city : 'Ciudad Desconocida';
  };
  const handleGeneratePDF = async (data) => {
    const urldata = url
    data.reportRum = actualQuote.data.reportRum
    data.actaEntrega = actualQuote.data.actaEntrega
    data.clientData = actualQuote.data.clientData
    data.quoteParts = actualQuote.data.quoteParts
    data.subtotal = actualQuote.data.subtotal
    data.iva = actualQuote.data.iva
    data.total = actualQuote.data.total
    data.paymentMeth = actualQuote.data.paymentMeth
    data.warranty = actualQuote.data.warranty
    const idClient = data.clientId
    const direccionEncontrada = address.find(item => item.id === idClient);
    data.clientId = getClientName(allClients, data.clientId)
    const cityIdClient = data.clientData.cityId
    data.clientData.cityId = getCityName(allCitys, cityIdClient)
    data.store = getStoreName(allStores, data.data.storeId)
    data.clientData.adreess = direccionEncontrada.address
    try {
      const config = {
        method: 'get',
        url: `${urldata}/quotes/generate`,
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      };
      const res = await axios(config)
      const apiKey = res.data.respuesta
      const response = await fetch('https://api.prexview.com/v1/transform', {
        method: 'POST',
        headers: {
          'Authorization': apiKey,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          json: JSON.stringify(data),
          template: 'refriactivebills',
          output: 'pdf'
        })
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `Factura #${data.id}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    catch (error) {
      console.error('Error:', error);

      if(error.response.status === 401) {
        dispatch({
            type: ALERT_SESSION,
            payload: true
        })

        setTimeout(() => {
            localStorage.clear('globalState')
            window.location.replace(urlFront)
        }, [3000])
        
    }  
    }
  };
  const navigate = useNavigate()
  const handleGoBack = () => {
    navigate(-1); // Navegar hacia atrás, equivalente a history.goBack()
  };
  const onSubmit = async (data) => {
    const datos = {
      comment: data.comment
    }
    try {
      setFormDisabled(true);
      const config = {
        method: 'patch',
        url: `${url}/bills/update/${id}`,
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
        data: datos
      };
      const response = await axios(config)
      if (response.data) {
        if (response.data.success === true) {
          setConfirmRegistro(true)
          setTimeout(() => {
            setConfirmRegistro(null);
            reset();
            setFormDisabled(false);
            setTimeout(() => {
              navigate(`/bills/view/${id}`);
            }, 100);
          }, 2000);
        }
      }
    } catch (error) {
      console.error("Error al crear factura:", error);
      setFormDisabled(false);
      setConfirmRegistro(false)

      if(error.response.status === 401) {
        dispatch({
            type: ALERT_SESSION,
            payload: true
        })

        setTimeout(() => {
            localStorage.clear('globalState')
            window.location.replace(urlFront)
        }, [3000])
        
    }  
    }
  }
  const getPaymentTerms = (paymentMeth) => {
    // Verificar si el método de pago incluye el formato "Crédito XX días"
    const match = paymentMeth.match(/Crédito (\d+) días/);
  
    if (match) {
      // Si es crédito, devolver la cantidad de días
      return `${match[1]} días`;
    } else if (paymentMeth === "Contado") {
      // Si es contado, manejarlo como un caso especial
      return "No aplica";
    } else {
      // Si no es ninguno de los anteriores, manejar casos inesperados o devolver un valor por defecto
      return "Términos no especificados";
    }
  };
  const getPaymentType = (paymentMeth) => {
    // Verificar si el método de pago incluye el formato "Crédito XX días"
    const match = paymentMeth.match(/Crédito (\d+) días/);
  
    if (match) {
      // Si es crédito, devolver la cantidad de días
      return `Crédito`;
    } else if (paymentMeth === "Contado") {
      // Si es contado, manejarlo como un caso especial
      return "Contado";
    } else {
      // Si no es ninguno de los anteriores, manejar casos inesperados o devolver un valor por defecto
      return "Términos no especificados";
    }
  };
  const handleExcel = async (actualQuote) => {
    const created = actualQuote
    try {
      const billToSheet = created.id
      const cliente = [[created.saleOrder], [getPaymentType(created.data.paymentMeth)], [getPaymentTerms(created.data.paymentMeth)], [created.data.clientData.name], [getAddressById(address, created.data.clientData.id)], [created.data.clientData.nit], [created.data.clientData.telefonoPrincipal], [created.data.clientData.emailPrincipal], [getCityName(allCitys, created.data.clientData.cityId)]]
      const quoteParts = []
      for (let index = 0; index < created.data.quoteParts.length; index++) {
        const element = created.data.quoteParts[index];
        const arreglo = [element.description, " ", element.quantity, element.costUnit]
        quoteParts.push(arreglo)
      }
      const extra = [[`FRA ${created.preBillId} RUM ${created.data.reportRum} ${created.data.clientData.name} ${getStoreName(allStores, created.data.storeId)} `]]
      const datos = {
        cliente: cliente,
        quoteParts: quoteParts,
        extra: extra
      }
      const config = {
        method: 'post',
        url: `${url}/postFactura?sheet=${billToSheet}`,
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
        data: datos
      };
      const respuestaPost = await axios(config)
      if (respuestaPost.status === 200) {
        setConfirmRegistro(true)
        setTimeout(() => {
          setConfirmRegistro(null);
        }, 2000);
      }
      else {
        setConfirmRegistro(false)
      }
    } catch (error) {
      console.error("Error al publicar en excel:", error);
      setFormDisabled(false);
      setConfirmRegistro(false)
    }
  }
// Renderizado
return (
  <div className={style.divContenedor}>
    <div className={style.divNavBar}>
      <NavBar />
    </div>
    <div className={style.divPrincipal}>
    {alertSession && <AlertSession text1="Su sesión ha vencido" text2="Por favor, vuelva a iniciar sesión" disabledBtn={false}/>}
      <h1 className={style.tituloSeccion}>Facturas</h1>
      <h4 className={style.subtitulo}>Factura # {id}</h4>
      <h5 className={style.subtitulo} style={{ color: actualQuote.deleted ? 'red' : 'green', fontSize: '1.05rem' }} >Factura {actualQuote.deleted ? "anulada" : "activa"}</h5>
      <div className={style.divForm}>
        <div className={style.divDatos}>
          <div className={style.divCampo}>
            <label className={style.label}>Número de reporte</label>
            <p className={style.input}> {actualQuote !== undefined && actualQuote.data && actualQuote.data.reportId}</p>
          </div>
          <div className={style.divCampo}>
            <label className={style.label}>Fecha</label>
            <p className={style.input}> {actualQuote !== undefined && actualQuote.data && actualQuote.data.date ? actualQuote.data.date : (actualQuote.data && actualQuote.data.aplicationDate? actualQuote.data.aplicationDate : null)}</p>
          </div>
          <div className={style.divCampo}>
            <label className={style.label}>Cliente</label>
            <p className={style.input}>{actualQuote !== undefined && actualQuote.data && getClientName(allClients, actualQuote.clientId)}</p>
          </div>
          <div className={style.divCampo}>
            <label className={style.label}>Ciudad</label>
            <p className={style.input}>{actualQuote !== undefined && actualQuote.data && getCityName(allCitys, actualQuote.data.cityId)}</p>
          </div>
        </div>
        <div className={style.divDatos}>
          <div className={style.divCampo}>
            <label className={style.label}>Almacen</label>
            <p className={style.input}>{actualQuote !== undefined && actualQuote.data && getStoreName(allStores, actualQuote.data.storeId)}</p>
          </div>
          <div className={style.divCampo}>
            <label className={style.label}>Acta de entrega</label>
            <p className={style.input}> {actualQuote !== undefined && actualQuote.data && actualQuote.data.actaEntrega}</p>
          </div>
          <div className={style.divCampo}>
            <label className={style.label}>RUM</label>
            <p className={style.input}> {actualQuote !== undefined && actualQuote.data && actualQuote.data.reportRum}</p>
          </div>
          <div className={style.divCampo}>
            <label className={style.label}>Forma de pago</label>
            <p className={style.input}>{actualQuote !== undefined && actualQuote.data && actualQuote.data.paymentMeth}</p>
          </div>
        </div>
        <div className={style.divDatos}>
          <div className={style.divCampo}>
            <label className={style.label}>Subototal</label>
            <p className={style.input}> ${actualQuote !== undefined && actualQuote.data && actualQuote.data.subtotal !== undefined && actualQuote.data.subtotal.toLocaleString()}</p>
          </div>
          <div className={style.divCampo}>
            <label className={style.label}>IVA</label>
            <p className={style.input}>${actualQuote !== undefined && actualQuote.data && actualQuote.data.iva !== undefined && actualQuote.data.iva.toLocaleString()}</p>
          </div>
          <div className={style.divCampo}>
            <label className={style.label}>Total</label>
            <p className={style.input}>${actualQuote !== undefined && actualQuote.data && actualQuote.data.total !== undefined && actualQuote.data.total.toLocaleString()}</p>
          </div>
        </div>
      </div>
      <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={style.divCampo}>
          <label className={style.label} htmlFor="comment">Comentario</label>
          <div className={style.divInput}>
            <Controller name="comment"
              control={control}
              defaultValue={actualQuote.comment}
              rules={{ required: 'Este campo es obligatorio', maxLength: { value: 100, message: 'Máximo 100 caracteres' }, minLength: { value: 4, message: 'Minimo 4 caracteres' } }}
              render={({ field }) => (
                <textarea className={style.input} type="text" maxLength="100" {...field} onChange={(e) => { field.onChange(e); trigger("comment"); }} />)} />
          </div>
        </div>
        <button type="submit" className={style.backbutton} >Actualizar comentario</button>
      </form>
      <div className={style.divDatos}>
        <h3 className={style.subtitulo}>Resumen de factura</h3>
        <div className={style.divTabla}>
          <table className={style.table}>
            <tr className={style.tr}>
              <th className={style.th}>Item</th>
              <th className={style.th}>Descripción</th>
              <th className={style.th}>Unidad</th>
              <th className={style.th}>Cantidad</th>
              <th className={style.th}>Valor unitario</th>
              <th className={style.th}>Valor total</th>
              <th className={style.thres}>Descripción</th>
              <th className={style.thres}>Cantidad</th>
              <th className={style.thres}>Valor unitario</th>
            </tr>
            {quoteList.map((orden) => (
              <tr className={style.tr} key={orden.item}>
                <td className={style.td}>{orden.item}</td>
                <td className={style.td}>{orden.description}</td>
                <td className={style.td}>{orden.unidad}</td>
                <td className={style.td}>{orden.quantity}</td>
                <td className={style.td}>$ {orden.costUnit.toLocaleString()}</td>
                <td className={style.td}>$ {orden.totalCost.toLocaleString()}</td>
                <td className={style.tdres}>{orden.description}</td>
                <td className={style.tdres}>{orden.quantity}</td>
                <td className={style.tdres}>$ {orden.costUnit.toLocaleString()}</td>
              </tr>))}
          </table>
        </div>
      </div>
      <Link target="blank" to={`https://docs.google.com/spreadsheets/d/1zlXBdg1NOKmFOVOJmCtjvWTP0GacUnBomMDvNXIRqLI/edit#gid=${actualQuote.sheetId}`}>
        <button type="button" className={style.backbutton}>Ver excel de factura</button>
      </Link>
      <button type="button" className={style.pdfbutton} onClick={() => handleExcel(actualQuote)} disabled={actualQuote === undefined || actualQuote.id === undefined}>Enviar a Excel</button>
      <button type="button" className={style.pdfbutton} onClick={() => handleGeneratePDF(actualQuote)} disabled={actualQuote === undefined || actualQuote.id === undefined || actualQuote.preBillId === null}>Descargar PDF</button>
      <button type="button" className={style.backbutton} onClick={handleGoBack}>Volver</button>
      {confirmRegistro === true ? (<><p className={style.positivo}>Información actualizada</p></>) : (confirmRegistro === false ? (<><p className={style.negativo} > No se pudo registrar, vuelve a intentarlo</p></>) : (null))}
    </div>
  </div>
)

}
export default ViewBill