import style from "./superiorBar.module.css";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/actions";
import { NavLink } from "react-router-dom";
import NavbarComponent from "../NavBar/NavBar";
import { urlFront } from '../../App';


const SuperiorBar = () => {
    const [showNavbar, setShowNavbar] = useState(false);
    const userData = useSelector((state) => state.userLoginNow);
    const dispatch = useDispatch();
    const menuRef = useRef(null);
    useEffect(() => {
        function handleDocumentClick(event) {
          if (menuRef.current && !menuRef.current.contains(event.target)) {
            setShowNavbar(false);
          }
        }
        document.addEventListener('click', handleDocumentClick);
        return () => {
          document.removeEventListener('click', handleDocumentClick);
        };
      }, []);
      const toggleNavbar = (event) => {
        event.stopPropagation(); // Evita la propagación del evento al documento
        setShowNavbar(!showNavbar);
      };

    const logout = () => {
        localStorage.clear('globalState')
        dispatch(actions.logout(userData.id));
        window.location.replace(urlFront)
    };

    return (
        <div className={style.superiorContenedor}>
            <div className={style.contenedorwith}>
                <div className={style.datasection2}>
                    <button className={style.buttonNav} onClick={toggleNavbar}>
                        <img className={style.logoUser} src="https://api.iconify.design/ic:baseline-menu.svg?color=%23ffffff" alt="" />
                        </button>
                </div>
                <div className={style.divImagen}>
                    <NavLink to="/">
                        <img className={style.logo} src="https://res.cloudinary.com/dfmsezslx/image/upload/v1700522308/TECDE/RefriActive_Img_zfhpl2.png" alt="LogoRefriActive" />
                    </NavLink>
                </div>
                <div className={style.datasection}>
                    <div className={style.userinfo}>
                        <p className={style.name}><img className={style.logoUser} src="https://api.iconify.design/material-symbols:person.svg?color=%23fcfcfc" alt="user" />{userData?.name + " " + userData?.surname}</p>
                        <p className={style.email}>{userData?.email}</p>
                        <p className={style.profile}><strong>Perfil:</strong> {userData?.profile}</p>
                    </div>
                    <button className={style.button} onClick={logout}>
                        <img className={style.logoUser} src="https://api.iconify.design/material-symbols:logout.svg?color=%23fcfcfc" alt="logout" />
                    </button>
                </div>
            </div>
            <div className={style.navBar}>
            {showNavbar && ( <div ref={menuRef}>
                <NavbarComponent/>
                </div>)} {/* Renderiza la barra de navegación solo si showNavbar es true */}
            </div>
        </div>
    );
};

export default SuperiorBar;
