import React from "react"
import NavBar from "../../components/NavBar/NavBar"
import { useSelector } from "react-redux"
import { NavLink } from "react-router-dom"
import style from "./contabilidad.module.css"


const Contabilidad = () => {
    const userData = useSelector((state) => state.userLoginNow)
    return (
        <div className={style.divContenedor}>
          <div className={style.divNavBar}>
          <NavBar/>
          </div>
            <div className={style.divPrincipal}>
                <h1 className={style.tituloSeccion}>Contabilidad</h1>
                <p className={style.parrafo}>En esta sección podrás sumar información, actualizar o modificar de todas las secciones creadas para la contabilidad y seguimiento <strong>Por favor tener cuidado al actualizar y/o eliminar, ya que esta información de propaga para todos los usuarios y en toda la base de datos.</strong> </p>
                <p className={style.parrafo}>Acceda a las siguientes categorías</p>
                <div className={style.botones}>
                  <NavLink to={"/accounting/counts"}>
                    <button className={style.internSectionButtoms}>Cuentas por pagar</button>
                  </NavLink>
                  <NavLink to={"/accounting/prepays"}>
                    <button disabled className={style.internSectionButtoms}>Anticipos</button>
                  </NavLink>
                  <NavLink to={"/accounting/personalpays"}>
                    <button disabled className={style.internSectionButtoms}>Pagos a personal</button>
                  </NavLink>
                  <NavLink to={"/accounting/incomes"}>
                    <button disabled className={style.internSectionButtoms}>Ingresos</button>
                  </NavLink>
                  <NavLink to={"/accounting/expenses"}>
                    <button disabled className={style.internSectionButtoms}>Egresos</button>
                  </NavLink>
                  <NavLink to={"/accounting/banks"}>
                    <button disabled className={style.internSectionButtoms}>Bancos</button>
                  </NavLink>
                  <NavLink to={"/accounting/balances"}>
                    <button disabled className={style.internSectionButtoms}>Balances</button>
                  </NavLink>
                </div>
            </div>
        </div>
    )
}


export default Contabilidad