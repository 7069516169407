// Dependencias y estilos
import './App.css';
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useEffect, lazy } from 'react';
import { useDispatch, useSelector } from "react-redux";
// Acciones
import { setUserData, setLoginStatus, setLoginReportStatus } from './redux/actions'
import * as actions from "./redux/actions"
// Componentes importados
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import SuperiorBar from './components/SuperiorBar/SuperiorBar';
import Quotes from './pages/Quotes/Quotes';
import MyQuotes from './pages/Quotes/MyQuotes';
import AllQuotes from './pages/Quotes/AllQuotes';
import CreateQuotes from './pages/Quotes/CreateQuotes/CreateQuotes';
import Datos from './pages/Datos/Datos';
import Client from './pages/Datos/Clientes/Client';
import UpdateClient from './pages/Datos/Clientes/UpdateClient';
import Store from './pages/Datos/Stores/Store';
import UpdateStore from './pages/Datos/Stores/UpdateStore';
import Parts from './pages/Datos/Parts/Parts';
import UpdatePart from './pages/Datos/Parts/UpdatePart';
import UpdateQuote from './pages/Quotes/CreateQuotes/UpdateQuote';
import Users from './pages/Datos/Users/User';
import Error from './components/Error/Error';
import Maintenance from './pages/Maintenance/Maintenance';
import PreBills from './pages/PreBills/PreBills';
import AllPreBills from './pages/PreBills/AllPreBills';
import MyPreBills from './pages/PreBills/MyPreBills';
import UpdatePreBill from './pages/PreBills/CreatePrebill/UpdatePreBill';
import CreatePreBillByQuote from './pages/PreBills/CreatePrebill/CreatePreBillByQuote';
import Services from './pages/Servicios/Services';
import AllServices from './pages/Servicios/AllServices';
import MyServices from './pages/Servicios/MyServices';
import UpdateService from './pages/Servicios/CreateServices/UpdateService';
import ViewQuote from './pages/Quotes/CreateQuotes/ViewQuote';
import ViewPreBill from './pages/PreBills/CreatePrebill/ViewPreBill';
import ViewService from './pages/Servicios/CreateServices/ViewService';
import Bills from './pages/Bills/Bills';
import AllBills from './pages/Bills/AllBills';
import MyBills from './pages/Bills/MyBills';
import UpdateBill from './pages/Bills/CreateBill/UpdateBill';
import ViewBill from './pages/Bills/CreateBill/ViewBill';
import CreateBillByPreBill from './pages/Bills/CreateBill/CreateBillByPreBill';
import CreateReport from './pages/Reports/CreateReports/createReport';
import LoginReports from './pages/Reports/loginReport/loginReports';
import CreateProfile from './pages/Reports/CreateProfile/createProfile';
import StartReport from './pages/Reports/StartReport/startReport';
import Reports from './pages/ReportSection/Reports';
import AllReports from './pages/ReportSection/AllReports';
import ViewReport from './pages/ReportSection/UtilsReports/ViewReport';
import CreateServices from './pages/Servicios/CreateServices/CreateService';
import CreateServicesData from './pages/Servicios/CreateServices/CreateServiceData';
import CreateBillByService from './pages/Bills/CreateBill/CreateBillByService';
import PuReports from './pages/ReportSectionPublic/PuReports';
import PuViewReport from './pages/ReportSectionPublic/UtilsReports/PuViewReport';
import PuReportsFilter from './pages/ReportSectionPublic/PuReportsFilter';
import ProjectReports from './pages/ReportSection/ProjectReports';
import Supplier from './pages/Datos/Supplier/Suppliers';
import UpdateSupplier from './pages/Datos/Supplier/CrudSupplier/UpdateSupplier';
import ViewSupplier from './pages/Datos/Supplier/CrudSupplier/ViewSupplier';
import CreateSupplier from './pages/Datos/Supplier/CrudSupplier/CreateSupplier';
import Contabilidad from './pages/Accounting/Accounting';
import Counts from './pages/Accounting/Counts/Counts';
import CreateCount from './pages/Accounting/Counts/CrudCounts/CreateCount';
import ViewCount from './pages/Accounting/Counts/CrudCounts/ViewCount';
import UpdateCount from './pages/Accounting/Counts/CrudCounts/UpdateCount';
import Component from '../src/Component';
import UpdateReport from './pages/ReportSection/UtilsReports/UpdateReport';
const Step2Report = lazy(() => import('./pages/Reports/Step2Report/step2Report'));
// URL
// export const url = "http://localhost:3001"
// export const urlFront = "http://localhost:3000"
export const url = "https://apirefriactive.tecde.co"
export const urlFront = "https://refriactive.tecde.co/"

const App = () => {
  // Imports
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const login = useSelector((state) => state.login)
  const userData = useSelector((state) => state.userLoginNow)
  const loginReports = useSelector((state) => state.loginReport)
  // Definitions
  const isLoginPage = location.pathname === "/login"
  const isMaintenance = location.pathname === "/maintenance"
  const isFormReport = location.pathname.includes("/reportes")
  const isReportPublic = location.pathname.includes("/reports/public")
  const isInicio = location.pathname === "/inicio"
  // useEffect
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("globalState")) || {};
    dispatch(setUserData(storedData.userData || null));
    dispatch(setLoginStatus(storedData.login || null));
    dispatch(setLoginReportStatus(storedData.loginReports || null));
    if (storedData.login !== true && !isFormReport && location.pathname !== "/inicio" && !location.pathname.includes("/reports/public")) {
      navigate("/login");
    }
    // if (storedData.loginReports !== true && isFormReport) {
    //   navigate("/inicio");
    // }
    if (location.pathname === "/data/user" && storedData.userData.profile !== "superadmin") {
      navigate("/")
    }
    if (location.pathname.includes("/accounting") && storedData.userData.profile !== "superadmin") {
      navigate("/")
    }
  }, []);
  useEffect(() => {
    if (isInicio === true && isFormReport === true) {
      dispatch(actions.allCitys())
      dispatch(actions.allDepartments())
    }
  }, [dispatch, isInicio, isFormReport ])
  useEffect(() => {
    const dataToStore = { userData, login, loginReports };
    localStorage.setItem("globalState", JSON.stringify(dataToStore));
  }, [userData, login, loginReports]);
  // Renderizado
  return (
    <div className='App'>
      {!isLoginPage && !isMaintenance && !isFormReport && !isInicio && !isReportPublic && <SuperiorBar />}
      <Component/>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/data' element={<Datos />} />
        <Route path='/data/client' element={<Client />} />
        <Route path='/data/client/:id' element={<UpdateClient />} />
        <Route path='/data/supplier' element={<Supplier/>} />
        <Route path='/data/supplier/update/:id' element={<UpdateSupplier />} />
        <Route path='/data/supplier/view/:id' element={<ViewSupplier />} />
        <Route path='/data/supplier/create' element={< CreateSupplier />} />
        <Route path='/data/store' element={<Store />} />
        <Route path='/data/store/:id' element={<UpdateStore />} />
        <Route path='/data/part' element={<Parts />} />
        <Route path='/data/part/:id' element={<UpdatePart />} />
        <Route path='/data/user' element={<Users />} />
        <Route path='/quotes' element={<Quotes />} />
        <Route path='/quotes/:id' element={<UpdateQuote />} />
        <Route path='/quotes/user/:id' element={<MyQuotes />} />
        <Route path='/quotes/all' element={<AllQuotes />} />
        <Route path='/quotes/view/:id' element={<ViewQuote />} />
        <Route path='/quotes/create' element={<CreateQuotes />} />
        <Route path='/preBills' element={<PreBills />} />
        <Route path='/preBills/all' element={<AllPreBills />} />
        <Route path='/preBills/user/:id' element={<MyPreBills />} />
        <Route path='/preBills/:id' element={<UpdatePreBill />} />
        <Route path='/preBills/view/:id' element={<ViewPreBill />} />
        <Route path='/preBills/create/:id' element={<CreatePreBillByQuote />} />
        <Route path='/services' element={<Services />} />
        <Route path='/services/all' element={<AllServices />} />
        <Route path='/services/user/:id' element={<MyServices />} />
        <Route path='/services/:id' element={<UpdateService />} />
        <Route path='/services/view/:id' element={<ViewService />} />
        <Route path='/services/new' element={<CreateServices />} />
        <Route path='/services/edit/:id' element={<CreateServicesData />} />
        <Route path='/bills/service/create/:id' element={<CreateBillByService />} />
        <Route path='/bills' element={< Bills/>} />
        <Route path='/bills/all' element={<AllBills />} />
        <Route path='/bills/user/:id' element={<MyBills />} />
        <Route path='/bills/:id' element={<UpdateBill />} />
        <Route path='/bills/view/:id' element={<ViewBill />} />
        <Route path='/bills/create/:id/:report' element={<CreateBillByPreBill />} />
        <Route path='/login' element={<Login />} />
        <Route path='/reportes' element={<CreateReport />} />
        <Route path='/reportes/techprofile' element={<CreateProfile />} />
        <Route path='/reportes/new/:id' element={<StartReport />} />
        <Route path='/reportes/step2/:report' element={<Step2Report />} />
        <Route path='/inicio' element={<LoginReports />} />
        <Route path='/reports' element={<Reports/>} />
        <Route path='/reports/projects' element={<ProjectReports/>} />
        <Route path='/reports/all' element={<AllReports />} />
        <Route path='/reports/public/all' element={<PuReports/>} />
        <Route path='/reports/public/filters/:zone/:client' element={<PuReportsFilter/>} />
        <Route path='/reports/public/view/:id' element={<PuViewReport />} />
        <Route path='/reports/view/:id' element={<ViewReport />} />
        <Route path='/reports/update/:id' element={<UpdateReport />} />
        <Route path='/accounting' element={<Contabilidad />} />
        <Route path='/accounting/counts' element={<Counts />} />
        <Route path='/accounting/counts/create' element={<CreateCount />} />
        <Route path='/accounting/counts/view/:id' element={<ViewCount />} />
        <Route path='/accounting/counts/update/:id' element={<UpdateCount />} />
        <Route path='/maintenance' element={<Maintenance />} />
        <Route path='*' element={<Error />} />
      </Routes>
    </div>
  );
}
export default App;