import style from "./createsupplier.module.css"
import NavBar from "../../../../components/NavBar/NavBar"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom"
import { useState, useEffect } from "react"
import { useForm, Controller } from 'react-hook-form';
import * as actions from "../../../../redux/actions"
import axios from "axios"
import { url, urlFront } from "../../../../App";
import { ALERT_SESSION } from "../../../../redux/action-types";
import AlertSession from "../../../../components/AlertSession/AlertSession";


const CreateSupplier = () => {
    // Importations
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Sumar 1 porque los meses van de 0 a 11
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    const { handleSubmit, control, formState: { errors }, trigger, reset, watch, register, setValue } = useForm()
    // Global States
    const userData = useSelector((state) => state.userLoginNow)
    const allClients = useSelector((state) => state.allClients)
    const allParts = useSelector((state) => state.allParts)
    const allCitys = useSelector((state) => state.allCitys)
    const allDepartments = useSelector((state) => state.allDepartments)
    const allStores = useSelector((state) => state.allStores)
    const alertSession = useSelector((state) => state.alertSession)
    let client = watch("clientId")
    // Local states
    const [formDisabled, setFormDisabled] = useState(null)
    const [filterCity, setFilterCity] = useState([])
    const [filterStore, setFilterStore] = useState([])
    const [actualClient, setActualClient] = useState("")
    const [selectedPart, setSelectedPart] = useState("");
    const [quantity, setQuantity] = useState("");
    const [quantityServ, setQuantityServ] = useState("");
    const [serviceDescription, setServiceDescription] = useState("");
    const [servicePrice, setServicePrice] = useState("");
    const [quoteList, setQuoteList] = useState([])
    const [countItem, setCountItem] = useState(1)
    const [confirmRegistro, setConfirmRegistro] = useState(null);
    //UseEffect
    useEffect(() => {
        dispatch(actions.allStores())
        dispatch(actions.allClients())
        dispatch(actions.allParts())
    }, [dispatch])
    useEffect(() => {
        setActualClient(client)
    }, [client])
    // Funciones
    const sumOfTotalPrice = quoteList.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.totalCost;
    }, 0);
    const handleChange = (e, allCitys) => {
        const departmentIdValue = e.target.value
        const citysById = allCitys.filter((city) => city.departmentId === parseInt(departmentIdValue))
        setFilterCity(citysById)
    }
    const handleChangeStore = (e, allStores) => {
        const cityIdValue = e.target.value
        const storesById = allStores.filter((store) => store.cityId === parseInt(cityIdValue))
        const storesByClient = storesById.filter((store) => store.clientId === parseInt(actualClient))
        setFilterStore(storesByClient)
    }
    const getCityName = (allCitys, cityId) => {
        const city = allCitys && allCitys.find((city) => city.id === cityId);
        return city ? city.city : 'Ciudad Desconocida';
    };
    const handleChangeClientId = (e) => {
        setValue('departmentId', "");
        trigger('departmentId');
        setValue('cityId', "");
        trigger('cityId');
        setValue('storeId', "");
        trigger('storeId');
    };
    const handlePartChange = (e) => {
        setSelectedPart(e.target.value);
    };
    const handleQuantityChange = (e) => {
        setQuantity(e.target.value);
    };
    const handleServiceChange = (e) => {
        setServiceDescription(e.target.value);
    };
    const handlePriceChange = (e) => {
        setServicePrice(e.target.value);
    };
    const handleQuantiServiceChange = (e) => {
        setQuantityServ(e.target.value);
    };
    const addPartToQuote = () => {
        const partFilter = allParts.filter((part) => part.id === parseInt(selectedPart));
        if (partFilter.length > 0) { // Verifica que se haya encontrado un elemento
            const part = {
                item: countItem,
                idPart: parseInt(selectedPart),
                description: partFilter[0].name + " " + partFilter[0].description,
                unidad: "UND",
                quantity: parseInt(quantity),
                costUnit: partFilter[0].price,
                totalCost: partFilter[0].price * quantity
            };
            quoteList.push(part)
            setCountItem(countItem + 1)
        }
    };
    const addServiceToQuote = () => {
        const service = {
            item: countItem,
            description: serviceDescription,
            unidad: "UND",
            quantity: parseInt(quantityServ),
            costUnit: parseInt(servicePrice),
            totalCost: servicePrice * quantityServ
        };
        quoteList.push(service)
        setCountItem(countItem + 1)
    }
    const onSubmit = async (data) => {
        data.userId = userData.id
        data.quoteParts = quoteList
        data.subtotal = sumOfTotalPrice
        data.iva = sumOfTotalPrice * 0.19
        data.total = data.subtotal + data.iva
        try {
            setFormDisabled(true);
            const config = {
                method: 'post',
                url: `${url}/suppliers/create`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                data: data
            };
            const response = await axios(config)
            if (response.data) {
                if (response.data.success === true) {
                    setConfirmRegistro(true)
                }
                else {
                    setConfirmRegistro(false)
                }
            }
            setTimeout(() => {
                setConfirmRegistro(null);
                reset();
                setFormDisabled(false);
                setTimeout(() => {
                    navigate("/data/supplier");
                }, 100);
            }, 2000);
        } catch (error) {
            console.error("Error al crear proveedor:", error);
            setFormDisabled(false);
            setConfirmRegistro(false)

            if(error.response.status === 401) {
                dispatch({
                    type: ALERT_SESSION,
                    payload: true
                })

                setTimeout(() => {
                    localStorage.clear('globalState')
                    window.location.replace(urlFront)
                }, [3000])
                
            }  
        }
    }
    // Renderizado
    return (
        <div className={style.divContenedor}>
            <div className={style.divNavBar}>
                <NavBar />
            </div>
            <div className={style.divPrincipal}>
            {alertSession && <AlertSession text1="Su sesión ha vencido" text2="Por favor, vuelva a iniciar sesión" disabledBtn={false}/>}
                <h1 className={style.tituloSeccion}>Proveedores</h1>
                <h4 className={style.subtitulo}>Crear proveedor</h4>
                <div className={style.divForm}>
                    <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
                        <div className={style.rowform}>
                            <div className={style.divDatos}>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="name"> Proveedor </label>
                                    <div className={style.divInput}>
                                        <Controller name="name"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: 'Este campo es obligatorio', maxLength: { value: 30, message: 'Maximo 30 caracteres' }, minLength: { value: 4, message: 'Minimo 4 caracteres' } }}
                                            render={({ field }) => (
                                                <input className={style.input} type="text" {...field} onChange={(e) => { field.onChange(e); trigger("name"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.name && <p className={style.errorText}>{errors.name.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="nit">NIT/CC/DOC</label>
                                    <div className={style.divInput}>
                                        <Controller name="nit"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: 'Este campo es obligatorio', maxLength: { value: 30, message: 'Maximo 30 caracteres' }, minLength: { value: 4, message: 'Minimo 4 caracteres' } }}
                                            render={({ field }) => (
                                                <input className={style.input} type="text" {...field} onChange={(e) => { field.onChange(e); trigger("nit"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.nit && <p className={style.errorText}>{errors.nit.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="departmentId"> Departamento </label>
                                    <div className={style.divInput}>
                                        <Controller name="departmentId"
                                            control={control}
                                            defaultValue={""}
                                            rules={{ required: 'Seleccione el depertamento' }}
                                            render={({ field }) => (
                                                <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("departmentId"); handleChange(e, allCitys) }}>
                                                    <option value="" disabled> Seleccione el departamento </option>
                                                    {allDepartments !== undefined ? allDepartments.map((department) => (
                                                        <option key={department.id} value={department.id}>
                                                            {department.department}</option>)) : null}
                                                </select>)} />
                                        <div className={style.errorMenssage}>
                                            {errors.departmentId && <p className={style.errorText}>{errors.departmentId.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="cityId"> Ciudad </label>
                                    <div className={style.divInput}>
                                        <Controller name="cityId"
                                            control={control}
                                            defaultValue={""}
                                            rules={{ required: 'Seleccione la ciudad' }}
                                            render={({ field }) => (
                                                <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("cityId"); handleChangeStore(e, allStores) }}>
                                                    <option value="" disabled> Seleccione la ciudad </option>
                                                    {filterCity !== undefined ? filterCity.map((city) => (
                                                        <option key={city.id} value={city.id}>
                                                            {city.city}</option>)) : null}
                                                </select>)} />
                                        <div className={style.errorMenssage}>
                                            {errors.cityId && <p className={style.errorText}>{errors.cityId.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="telefonoPrincipal">Telefono</label>
                                    <div className={style.divInput}>
                                        <Controller name="telefonoPrincipal"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: 'Este campo es obligatorio', maxLength: { value: 30, message: 'Maximo 30 caracteres' }, minLength: { value: 4, message: 'Minimo 4 caracteres' } }}
                                            render={({ field }) => (
                                                <input className={style.input} type="text" {...field} onChange={(e) => { field.onChange(e); trigger("telefonoPrincipal"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.telefonoPrincipal && <p className={style.errorText}>{errors.telefonoPrincipal.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="emailPrincipal">Correo electronico</label>
                                    <div className={style.divInput}>
                                        <Controller name="emailPrincipal"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: 'Este campo es obligatorio', maxLength: { value: 40, message: 'Maximo 40 caracteres' }, minLength: { value: 5, message: 'Minimo 5 caracteres' } }}
                                            render={({ field }) => (
                                                <input className={style.input} type="text" {...field} onChange={(e) => { field.onChange(e); trigger("emailPrincipal"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.emailPrincipal && <p className={style.errorText}>{errors.emailPrincipal.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="direccion">Dirección</label>
                                    <div className={style.divInput}>
                                        <Controller name="direccion"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: 'Este campo es obligatorio', maxLength: { value: 100, message: 'Maximo 100 caracteres' }, minLength: { value: 4, message: 'Minimo 4 caracteres' } }}
                                            render={({ field }) => (
                                                <textarea className={style.input} type="text" {...field} onChange={(e) => { field.onChange(e); trigger("direccion"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.direccion && <p className={style.errorText}>{errors.direccion.message}</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="submit" className={style.formbutton} >Crear proveedor</button>
                        {confirmRegistro === true ? (<><p className={style.positivo}>Proveedor creado con exito</p></>) : (confirmRegistro === false ? (<><p className={style.negativo} > No se pudo registrar, vuelve a intentarlo</p></>) : (null))}
                        <Link to="/data/supplier">
                            <button type="button" className={style.backbutton}>Volver</button>
                        </Link>
                    </form>
                </div>
            </div>
        </div>
    )

}
export default CreateSupplier