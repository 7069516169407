import style from "./store.module.css"
import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useForm, Controller } from 'react-hook-form';
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import NavBar from "../../../components/NavBar/NavBar";
import * as actions from "../../../redux/actions"
import { url, urlFront } from "../../../App";
import Filtro from "../../../components/Filtro/Filtro";
import Search from "../../../components/Search/Search";
import { ALERT_SESSION } from "../../../redux/action-types";
import AlertSession from "../../../components/AlertSession/AlertSession";

const Store = () => {
    const dispatch = useDispatch()
    const { handleSubmit, control, formState: { errors }, trigger, reset, watch } = useForm()
    const allClients = useSelector((state) => state.allClients)
    const allStores = useSelector((state) => state.allStores)
    const allCitys = useSelector((state) => state.allCitys)
    const allDepartments = useSelector((state) => state.allDepartments)
    const alertSession = useSelector((state) => state.alertSession)
    const [filterCity, setFilterCity] = useState([])
    const [createClients, setCreateClients] = useState(false)
    const [confirmRegistro, setConfirmRegistro] = useState(null);
    const [formDisabled, setFormDisabled] = useState(false);
    const filter = useSelector((state) => state.filterStores)
    const [data, setData] = useState(allStores)
    useEffect(() => {
        if (filter !== undefined && filter.length > 0 && filter[0] !== "Sin resultados") {
            setData(filter)
        }
        else if (filter !== undefined && filter.length > 0 && filter[0] === "Sin resultados") {
            setData([])
        }
        else if (filter !== undefined && filter.length === 0) {
            setData(allStores)
        }
    }, [allStores, filter])
    // Use effect
    useEffect(() => {
        if (!allDepartments || allDepartments.length === 0) {
            dispatch(actions.allDepartments())
        }
        if (!allCitys || allCitys.length === 0) {
            dispatch(actions.allCitys())
        }
        if (!allClients || allClients.length === 0) {
            dispatch(actions.allClients())
        }
    }, [])
    useEffect(() => {
        dispatch(actions.allStores())
    }, [dispatch])
    // Funtions
    const handleNewUser = () => {
        if (createClients) {
            setCreateClients(false)
        }
        else {
            setCreateClients(true)
        }
    }
    const handleChange = (e, allCitys) => {
        const departmentIdValue = e.target.value
        const citysById = allCitys.filter((city) => city.departmentId === parseInt(departmentIdValue))
        setFilterCity(citysById)
    }
    const onSubmit = async (data) => {
        try {
            setFormDisabled(true);
            const config = {
                method: 'post',
                url: `${url}/stores/create`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                data: data
            };
            const response = await axios(config)
            if (response.data) {
                if (response.data.success === true) {
                    setConfirmRegistro(true)
                }
                else {
                    setConfirmRegistro(false)
                }
            }
            setTimeout(() => {
                setConfirmRegistro(null);
                reset();
                setFormDisabled(false);
                setTimeout(() => {
                    window.location.reload();
                }, 100);
            }, 2000);
        } catch (error) {
            console.error("Error al crear almacen:", error);
            setFormDisabled(false);
            setConfirmRegistro(false)
            reset()

            if(error.response.status === 401) {
                dispatch({
                    type: ALERT_SESSION,
                    payload: true
                })

                setTimeout(() => {
                    localStorage.clear('globalState')
                    window.location.replace(urlFront)
                }, [3000])
                
            }  
        }
    };
    const getClientName = (allClients, clientId) => {
        const client = allClients.find((client) => client.id === clientId);
        return client ? client.name : 'Cliente desconocido';
    };
    const getCityName = (allCitys, cityId) => {
        const city = allCitys && allCitys.find((city) => city.id === cityId);
        return city ? city.city : 'Ciudad Desconocida';
    };
    const getDeparmentsName = (allDepartments, departmentId) => {
        const department = allDepartments && allDepartments.find((department) => department.id === departmentId);
        return department ? department.department : 'Ciudad Desconocida';
    };
    // Paginado
    const [pagina, setPagina] = useState(1)
    const porPagina = 8
    const ultimoElemento = pagina * porPagina
    const primerElemento = ultimoElemento - porPagina
    const sortedData = data !== undefined && data.length > 0 && [...data].sort((a, b) => new Date(b.id) - new Date(a.id));
    const actualQuotes = sortedData !== undefined && sortedData.length > 0 && sortedData.slice(primerElemento, ultimoElemento)
    const totalPages = data !== undefined && data.length > 0 && Math.ceil(data.length / porPagina);
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }
    useEffect(() => { setPagina(1) }, [totalPages]);
    const maxPaginasAMostrar = 4; // Número máximo de páginas que se mostrarán en la barra de paginación
    const inicioPaginas = pagina <= Math.ceil(maxPaginasAMostrar / 2) ? 1 : Math.max(1, pagina - Math.floor(maxPaginasAMostrar / 2))
    const finPaginas = Math.min(inicioPaginas + maxPaginasAMostrar - 1, totalPages);
    // Renderizado
    return (
        <div className={style.divContenedor}>
            <div className={style.divNavBar}>
                <NavBar />
            </div>
            <div className={style.contenedor}>
            {alertSession && <AlertSession text1="Su sesión ha vencido" text2="Por favor, vuelva a iniciar sesión" disabledBtn={false}/>}
                <div className={style.divComplementario}>
                    <h1 className={style.titulo}>Almacenes</h1>
                </div>
                <Filtro section="stores" />
                <Search section="stores" />
                <div className={style.divTabla}>
                    <table className={style.table}>
                        <tr className={style.tr}>
                            <th className={style.th}>#</th>
                            <th className={style.th}>Almacen</th>
                            <th className={style.th}>Cliente</th>
                            <th className={style.th}>Departamento</th>
                            <th className={style.th}>Ciudad</th>
                            <th className={style.th}>Barrio</th>
                            <th className={style.th}>Dirección</th>
                            <th className={style.th}>Acciones</th>
                            <th className={style.thres}>#</th>
                            <th className={style.thres}>Almacen</th>
                            <th className={style.thres}>Client</th>
                            <th className={style.thres}>Ciudad</th>
                            <th className={style.thres}>Acciones</th>
                        </tr>
                        {actualQuotes && actualQuotes.length > 0 && actualQuotes.map((store) => (<>
                            <tr className={style.tr} key={store.id}>
                                <td className={style.td}>{store.id}</td>
                                <td className={style.td}>{store.name}</td>
                                <td className={style.td}>{getClientName(allClients, store.clientId)}</td>
                                <td className={style.td}>{getDeparmentsName(allDepartments, store.departmentId)}</td>
                                <td className={style.td}>{getCityName(allCitys, store.cityId)}</td>
                                <td className={style.td}>{store.town}</td>
                                <td className={style.td}>{store.address}</td>
                                <td className={style.tdres}>{store.id}</td>
                                <td className={style.tdres}>{store.name}</td>
                                <td className={style.tdres}>{getClientName(allClients, store.clientId)}</td>
                                <td className={style.tdres}>{getCityName(allCitys, store.cityId)}</td>
                                <td className={style.tdetail}>
                                    <Link to={`/data/store/${store.id}`}>
                                        <button className={style.botonUpdate} disabled={createClients}><img className={style.icon} src="https://api.iconify.design/material-symbols:edit.svg?color=%23313372" alt="editbuttom" /></button>
                                    </Link>
                                </td>
                            </tr>
                        </>))}
                    </table>
                    {filter[0] === "Sin resultados" &&
                        <div className={style.paginado}>
                            <p className={style.mensaje}>Sin resultados en la búsqueda</p>
                        </div>}
                    {sortedData !== undefined && sortedData.length > 0 &&
                        <div className={style.paginado}>
                            <button className={style.botonpag} onClick={() => setPagina(1)} disabled={pagina === 1}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:move-last-rtl.svg?color=%23313372" alt="editbuttom" /></button>
                            <button className={style.botonpag} onClick={() => setPagina(pagina - 1)} disabled={pagina === 1}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:previous-ltr.svg?color=%23313372" alt="editbuttom" /></button>
                            {pageNumbers.slice(inicioPaginas - 1, finPaginas).map((pageNumber) => (
                                <button key={pageNumber} className={pageNumber === pagina ? style.pagina : style.paginaboton} onClick={() => setPagina(pageNumber)}>{pageNumber}</button>))}
                            <button className={style.botonpag} onClick={() => setPagina(pagina + 1)} disabled={ultimoElemento >= sortedData.length}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:previous-rtl.svg?color=%23313372" alt="editbuttom" /></button>
                            <button className={style.botonpag} onClick={() => setPagina(totalPages)} disabled={ultimoElemento >= sortedData.length}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:move-last-ltr.svg?color=%23313372" alt="editbuttom" /></button>
                        </div>}
                </div>
                <div className={style.newUser}>
                    {createClients === false ? (<button className={style.formbutton} onClick={handleNewUser}> + Añadir nuevo almacen</button>) : (<button className={style.botonClose} onClick={handleNewUser}> X </button>)}
                    {createClients === true ? (
                        <div className={style.divCreate}>
                            <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
                                <div className={style.divDatos}>
                                    <div className={style.divCampo}>
                                        <label className={style.label} htmlFor="name">Almacen</label>
                                        <div className={style.divInput}>
                                            <Controller name="name"
                                                control={control}
                                                defaultValue=""
                                                rules={{ required: 'Este campo es obligatorio', maxLength: { value: 30, message: 'Máximo 30 caracteres' }, minLength: { value: 2, message: 'Minímo 2 caracteres' } }}
                                                render={({ field }) => (
                                                    <input className={style.input} type="text" {...field} onChange={(e) => { field.onChange(e); trigger("name"); }} />)} />
                                            <div className={style.errorMenssage}>
                                                {errors.name && <p className={style.errorText}>{errors.name.message}</p>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={style.divCampo}>
                                        <label className={style.label} htmlFor="clientId"> Cliente </label>
                                        <div className={style.divInput}>
                                            <Controller name="clientId"
                                                control={control}
                                                defaultValue={""}
                                                rules={{ required: 'Seleccione el cliente' }}
                                                render={({ field }) => (
                                                    <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("clientId") }}>
                                                        <option value="" disabled> Seleccione el cliente </option>
                                                        {allClients !== undefined ? allClients.map((client) => (
                                                            <option key={client.id} value={client.id}>
                                                                {client.name}</option>)) : null}
                                                    </select>)} />
                                            <div className={style.errorMenssage}>
                                                {errors.clientId && <p className={style.errorText}>{errors.clientId.message}</p>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={style.divCampo}>
                                        <label className={style.label} htmlFor="departmentId"> Departamento </label>
                                        <div className={style.divInput}>
                                            <Controller name="departmentId"
                                                control={control}
                                                defaultValue={""}
                                                rules={{ required: 'Seleccione el depertamento' }}
                                                render={({ field }) => (
                                                    <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("departmentId"); handleChange(e, allCitys) }}>
                                                        <option value="" disabled> Seleccione el departamento </option>
                                                        {allDepartments !== undefined ? allDepartments.map((department) => (
                                                            <option key={department.id} value={department.id}>
                                                                {department.department}</option>)) : null}
                                                    </select>)} />
                                            <div className={style.errorMenssage}>
                                                {errors.departmentId && <p className={style.errorText}>{errors.departmentId.message}</p>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={style.divCampo}>
                                        <label className={style.label} htmlFor="cityId"> Ciudad </label>
                                        <div className={style.divInput}>
                                            <Controller name="cityId"
                                                control={control}
                                                defaultValue={""}
                                                rules={{ required: 'Seleccione la ciudad' }}
                                                render={({ field }) => (
                                                    <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("cityId"); }}>
                                                        <option value="" disabled> Seleccione la ciudad </option>
                                                        {filterCity.map((city) => (
                                                            <option key={city.id} value={city.id}>
                                                                {city.city}</option>))}
                                                    </select>)} />
                                        </div>
                                    </div>
                                    <div className={style.divCampo}>
                                        <label className={style.label} htmlFor="town">Barrio</label>
                                        <div className={style.divInput}>
                                            <Controller name="town"
                                                control={control}
                                                defaultValue=""
                                                rules={{ required: 'Este campo es obligatorio', maxLength: { value: 30, message: 'Máximo 30 caracteres' }, minLength: { value: 4, message: 'El Minímo 4 caracteres' } }}
                                                render={({ field }) => (
                                                    <input className={style.input} type="text" {...field} onChange={(e) => { field.onChange(e); trigger("town"); }} />)} />
                                            <div className={style.errorMenssage}>
                                                {errors.town && <p className={style.errorText}>{errors.town.message}</p>}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="zoneId">Zona</label>
                                    <div className={style.divInput}>
                                        <Controller name="zoneId"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: 'Este campo es obligatorio'} }
                                        render={({ field }) => (
                                        <input className={style.input} type="number" {...field} onChange={(e) => {field.onChange(e); trigger("zoneId"); }}/>)}/>
                                        <div className={style.errorMenssage}>
                                            {errors.zoneId && <p className={style.errorText}>{errors.zoneId.message}</p>}
                                        </div>
                                    </div>
                                </div> */}
                                    <div className={style.divCampo}>
                                        <label className={style.label} htmlFor="address">Dirección</label>
                                        <div className={style.divInput}>
                                            <Controller name="address"
                                                control={control}
                                                defaultValue=""
                                                rules={{ required: 'Este campo es obligatorio', maxLength: { value: 30, message: 'Máximo 30 caracteres' }, minLength: { value: 4, message: 'El Minímo 4 caracteres' } }}
                                                render={({ field }) => (
                                                    <input className={style.input} type="text" {...field} onChange={(e) => { field.onChange(e); trigger("address"); }} />)} />
                                            <div className={style.errorMenssage}>
                                                {errors.address && <p className={style.errorText}>{errors.address.message}</p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <button type="submit" className={style.formbutton} disabled={formDisabled} >Crear nuevo almacen</button>
                                    {confirmRegistro === true ? (<><p className={style.positivo}>Almacen creado con exito</p></>) : (confirmRegistro === false ? (<><p className={style.negativo} > No se pudo registrar, vuelve a intentarlo</p></>) : (null))}
                                </div>
                            </form>
                        </div>) : (null)}
                    <Link to="/data">
                        <button type="button" className={style.backbutton}>Volver</button>
                    </Link>
                </div>
            </div>

        </div>
    );
}

export default Store





// const generatePassword = () => {
//     const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()-_=+[]{}|;:,.<>?';
//     const passwordLength = 8;
//     let password = '';
//     for (let i = 0; i < passwordLength; i++) {
//       const randomIndex = Math.floor(Math.random() * charset.length);
//       password += charset.charAt(randomIndex);
//     }
//     setGeneratedPassword(password);
//   };