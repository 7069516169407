import style from "./bills.module.css"
import NavBar from "../../components/NavBar/NavBar"
import { NavLink, Link } from "react-router-dom"
import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/actions"
import axios from "axios";
import { url, urlFront } from "../../App";
import Filtro from "../../components/Filtro/Filtro"
import Search from "../../components/Search/Search";
import { ALERT_SESSION } from "../../redux/action-types";
import AlertSession from "../../components/AlertSession/AlertSession";


const Bills = () => {
  const dispatch = useDispatch()
  const userData = useSelector((state) => state.userLoginNow)
  const allClients = useSelector((state) => state.allClients)
  const allStores = useSelector((state) => state.allStores)
  const allCitys = useSelector((state) => state.allCitys)
  const allPreBills = useSelector((state) => state.allBills)
  const filterQuotes = useSelector((state) => state.filterBills)
  const alertSession = useSelector((state) => state.alertSession)
  const [data, setData] = useState(allPreBills)
  const address = [
    { id: 1, address: "CR 7 CL 155 C 30 ED NORTH POINT TO EP 37 Y 38" },
    { id: 2, address: " " },
    { id: 3, address: " " },
    { id: 4, address: " " },
    { id: 5, address: " " },
    { id: 6, address: " " }
  ]
  useEffect(() => {
    if (filterQuotes !== undefined && filterQuotes.length > 0 && filterQuotes[0] !== "Sin resultados") {
      setData(filterQuotes)
    }
    else if (filterQuotes !== undefined && filterQuotes.length > 0 && filterQuotes[0] === "Sin resultados") {
      setData([])
    }
    else {
      setData(allPreBills)
    }
  }, [allPreBills, filterQuotes])
  // UseEffect 
  useEffect(() => {
    if (!allCitys || allCitys.length === 0) {
      dispatch(actions.allCitys())
    }
    if (!allClients || allClients.length === 0) {
      dispatch(actions.allClients())
    }
    if (!allStores || allStores.length === 0) {
      dispatch(actions.allStores())
    }
    if (!allPreBills || allPreBills.length === 0) {
      dispatch(actions.allBills())
    }
  }, [])
  // Traer nombres
  const getClientName = (allClients, clientId) => {
    const client = allClients.find((client) => client.id === parseInt(clientId));
    return client ? client.name : 'Cliente desconocido';
  };
  const getStoreName = (allStores, storeId) => {
    const store = allStores.find((store) => store.id ===  parseInt(storeId));
    return store ? store.name : 'Tienda desconocido';
  };
  const getCityName = (allCitys, cityId) => {
    const city = allCitys && allCitys.find((city) => city.id ===  parseInt(cityId));
    return city ? city.city : 'Ciudad Desconocida';
  };
  const handleAnulada = async (id) => {
    try {
      const config = {
      method: 'patch',
      url: `${url}/bills/logicDelete/${id}`,
      headers: {
          'Content-Type': 'application/json',
      },
      withCredentials: true,
      };
      const respuestaPost = await axios(config)
      if (respuestaPost.status === 200) {
        dispatch(actions.allBills())
      }
    } catch (error) {
      console.log(error)
      
      if(error.response.status === 401) {
        dispatch({
            type: ALERT_SESSION,
            payload: true
        })

        setTimeout(() => {
            localStorage.clear('globalState')
            window.location.replace(urlFront)
        }, [3000])
        
      }  
    }
  }
  useEffect(() => {
    dispatch(actions.allBills())
  }, [])
  const sortedData = data !== undefined && data.length > 0 && [...data].sort((a, b) => new Date(b.id) - new Date(a.id));
  // Paginado
  const [pagina, setPagina] = useState(1)
  const porPagina = 8
  const ultimoElemento = pagina * porPagina
  const primerElemento = ultimoElemento - porPagina
  const actualQuotes = sortedData !== undefined && sortedData.length > 0 && sortedData.slice(primerElemento, ultimoElemento)
  const totalPages = sortedData !== undefined && sortedData.length > 0 && Math.ceil(sortedData.length / porPagina);
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }
  useEffect(() => { setPagina(1) }, [totalPages]);
  const maxPaginasAMostrar = 4; // Número máximo de páginas que se mostrarán en la barra de paginación
  const inicioPaginas = pagina <= Math.ceil(maxPaginasAMostrar / 2) ? 1 : Math.max(1, pagina - Math.floor(maxPaginasAMostrar / 2))
  const finPaginas = Math.min(inicioPaginas + maxPaginasAMostrar - 1, totalPages);
  // Renderizado
  return (
    <div className={style.divContenedor}>
      <div className={style.divNavBar}>
        <NavBar />
      </div>
      <div className={style.divPrincipal}>
      {alertSession && <AlertSession text1="Su sesión ha vencido" text2="Por favor, vuelva a iniciar sesión" disabledBtn={false}/>}
        <h1 className={style.tituloSeccion}>Facturas</h1>
        <div className={style.botones}>
          {/* <NavLink to={"/preBills/create"}>
                    <button disabled className={style.internSectionButtoms}>Crear prefactura</button>
                  </NavLink> */}
          {/* <NavLink to={"/bills/user/" + userData.id}>
            <button className={style.internSectionButtoms}> Mis facturas </button>
          </NavLink> */}
        </div>
        <Filtro section="bills" />
        <Search section="bills" />
        <div className={style.divTabla}>
          <h4 className={style.subtituloSeccion}>Ultimas facturas</h4>
          <table className={style.table}>
            <tr className={style.tr}>
              <th className={style.th}>#</th>
              <th className={style.th}>Prefactura</th>
              <th className={style.th}>Cotización</th>
              <th className={style.th}>Fecha</th>
              <th className={style.th}>Cliente</th>
              <th className={style.th}>Almacen</th>
              <th className={style.th}>Total</th>
              <th className={style.th}>Anulada</th>
              <th className={style.th}>Acciones</th>
              <th className={style.thres}>#</th>
              <th className={style.thres}>Prefactura</th>
              <th className={style.thres}>Fecha</th>
              <th className={style.thres}>Cliente</th>
              <th className={style.thres}>Almacen</th>
              <th className={style.thres}>Total</th>
              <th className={style.thres}>Acciones</th>
            </tr>
            {actualQuotes !== undefined && actualQuotes.length > 0 && actualQuotes.map((orden) => (
              <tr className={style.tr} key={orden.id}>
                <td className={style.td}>{orden.id}</td>
                <td className={style.td}>{orden.preBillId === null ? "000": orden.preBillId }</td>
                <td className={style.td}>{orden.quoteId === null ? "000": orden.quoteId}</td>
                <td className={style.td}>{orden.data.date ? orden.data.date : (orden.data && orden.data.aplicationDate? orden.data.aplicationDate : null)}</td>
                <td className={style.td}>{getClientName(allClients, orden.clientId)}</td>
                <td className={style.td}>{getStoreName(allStores, orden.data.storeId)}</td>
                <td className={style.td}>$ {orden.data.total.toLocaleString()}</td>
                <td className={style.td} style={{ color: orden.deleted ? 'red' : 'green' }}>{orden.deleted ? ("Sí") : ("No")}</td>
                <td className={style.tdres}>{orden.id}</td>
                <td className={style.tdres}>{orden.preBillId}</td>
                <td className={style.tdres}>
                  {(() => {
                    var fechaString = orden.data.date;
                    var fecha = new Date(fechaString);
                    var mes = fecha.getMonth() + 1;
                    var dia = fecha.getDate();
                    var nuevaFechaString = (mes < 10 ? '0' : '') + mes + '-' + (dia < 10 ? '0' : '') + dia;
                    return nuevaFechaString;
                  })()}
                </td>
                <td className={style.tdres}>{getClientName(allClients, orden.clientId)}</td>
                <td className={style.tdres}>{getStoreName(allStores, orden.data.storeId)}</td>
                <td className={style.tdres}>$ {orden.data.total.toLocaleString()}</td>
                <td className={style.tdetail}>
                  <Link to={`/bills/view/${orden.id}`}>
                    <button className={style.botonUpdate}><img className={style.icon} src="https://api.iconify.design/material-symbols:open-in-new.svg?color=%23313372" alt="editbuttom" /></button>
                  </Link>
                  <button className={style.botonUpdate} onClick={()=> handleAnulada(orden.id)}><img className={style.icon} src={orden.deleted ? ("https://api.iconify.design/material-symbols:check-circle.svg?color=%23313372"):("https://api.iconify.design/material-symbols:cancel-rounded.svg?color=%23313372")} alt="editbuttom" /></button>
                  {/* <Link to={`/bills/${orden.id}`}>
                    <button className={style.botonUpdate}><img className={style.icon} src="https://api.iconify.design/material-symbols:edit.svg?color=%23313372" alt="editbuttom" /></button>
                  </Link> */}
                  {/* <button className={style.botonUpdate} onClick={() => handleGeneratePDF(orden)}> <img className={style.icon} src="https://api.iconify.design/teenyicons:pdf-solid.svg?color=%23313372" alt="editbuttom" /></button> */}
                </td>
              </tr>))}
          </table>
          {filterQuotes[0] === "Sin resultados" &&
            <div className={style.paginado}>
              <p className={style.mensaje}>Sin resultados en la búsqueda</p>
            </div>}
          {sortedData !== undefined && sortedData.length > 0 &&
            <div className={style.paginado}>
              <button className={style.botonpag} onClick={() => setPagina(1)} disabled={pagina === 1}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:move-last-rtl.svg?color=%23313372" alt="editbuttom" /></button>
              <button className={style.botonpag} onClick={() => setPagina(pagina - 1)} disabled={pagina === 1}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:previous-ltr.svg?color=%23313372" alt="editbuttom" /></button>
              {pageNumbers.slice(inicioPaginas - 1, finPaginas).map((pageNumber) => (
                <button key={pageNumber} className={pageNumber === pagina ? style.pagina : style.paginaboton} onClick={() => setPagina(pageNumber)}>{pageNumber}</button>))}
              <button className={style.botonpag} onClick={() => setPagina(pagina + 1)} disabled={ultimoElemento >= sortedData.length}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:previous-rtl.svg?color=%23313372" alt="editbuttom" /></button>
              <button className={style.botonpag} onClick={() => setPagina(totalPages)} disabled={ultimoElemento >= sortedData.length}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:move-last-ltr.svg?color=%23313372" alt="editbuttom" /></button>
            </div>}
          <NavLink to="/bills/all">
            <button className={style.backbutton}>Ver todo</button>
          </NavLink>
        </div>
      </div>
    </div>
  )
}


export default Bills
