import style from "./client.module.css"
import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useForm, Controller } from 'react-hook-form';
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import NavBar from "../../../components/NavBar/NavBar";
import * as actions from "../../../redux/actions"
import {url, urlFront} from "../../../App";
import { ALERT_SESSION } from "../../../redux/action-types";
import AlertSession from "../../../components/AlertSession/AlertSession";

const Client = () => {
  const dispatch = useDispatch()
    const {handleSubmit, control, formState: {errors}, trigger, reset, watch} = useForm()
    const allClients = useSelector((state) => state.allClients)
    const allCitys = useSelector((state) => state.allCitys)
    const allDepartments = useSelector((state) => state.allDepartments)
    const alertSession = useSelector((state) => state.alertSession)
    const [filterCity, setFilterCity] = useState([])
    useEffect(()=> {
      if (!allDepartments) {
          dispatch(actions.allDepartments())
      }
  }, [])
    const [createClients, setCreateClients] = useState(false) 
    const [confirmRegistro, setConfirmRegistro] = useState(null);
    const [formDisabled, setFormDisabled] = useState(false);
    useEffect(() => {
      dispatch(actions.allClients())
    }, [])
    const handleNewUser = () => {
        if (createClients) {
          setCreateClients(false)
        }
        else {
          setCreateClients(true)
        }
    }
    useEffect(()=> {
        if (!allDepartments) {
            dispatch(actions.allDepartments())
        }
    }, [])
    const handleChange = (e, allCitys) => {
        const departmentIdValue = e.target.value
        const citysById = allCitys.filter((city) => city.departmentId === parseInt(departmentIdValue))
        setFilterCity(citysById)
    }
    const onSubmit = async (data) => {
      try {
            setFormDisabled(true);
            const config = {
                method: 'post',
                url: `${url}/clients/create`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                data: data
            };
            const response = await axios(config)
            if (response.data) {
                if (response.data.success === true) {
                    setConfirmRegistro(true)
                }
                else {
                    setConfirmRegistro(false)
                }
            }
            setTimeout(() => {
                setConfirmRegistro(null);
                reset();
                setFormDisabled(false);
                // Recargar la página después de 2000 milisegundos (2 segundos)
                setTimeout(() => {
                    window.location.reload();
                }, 100);
            }, 2000);
        } catch (error) {
            console.error("Error al crear usuario:", error);
            setFormDisabled(false);
            setConfirmRegistro(false)
            reset()

            if(error.response.status === 401) {
                dispatch({
                    type: ALERT_SESSION,
                    payload: true
                })

                setTimeout(() => {
                    localStorage.clear('globalState')
                    window.location.replace(urlFront)
                }, [3000])
                
            }  
        }
    };
    useEffect(() => {
      dispatch(actions.allClients())
    }, [dispatch])
    const getCityName = (allCitys, cityId) => {
        const city = allCitys.find((city) => city.id === cityId);
        return city ? city.city : 'Ciudad Desconocida';
    };
    const getDeparmentsName = (allDepartments, departmentId) => {
      const department = allDepartments.find((department) => department.id === departmentId);
      return department ? department.department : 'Ciudad Desconocida';
  };
    return (
        <div className={style.divContenedor}>
        <div className={style.divNavBar}>
          <NavBar/>
          </div>
        <div className={style.contenedor}>
        {alertSession && <AlertSession text1="Su sesión ha vencido" text2="Por favor, vuelva a iniciar sesión" disabledBtn={false}/>}
          <div className={style.divComplementario}>
            <h1 className={style.titulo}>Clientes</h1>
          </div>
          <div className={style.divTabla}>
            <table className={style.table}>
              <tr className={style.tr}>
                <th className={style.th}>Cliente</th>
                <th className={style.th}>NIT</th>
                <th className={style.th}>Ciudad</th>
                <th className={style.th}>Departamento</th>
                <th className={style.th}>Email</th>
                <th className={style.th}>Telefono</th>
                <th className={style.th}>Acciones</th>
                <th className={style.thres}>#</th>
                <th className={style.thres}>Cliente</th>
                <th className={style.thres}>NIT</th>
                <th className={style.thres}>Acciones</th>
              </tr>
              {allClients && allClients.length > 0 && allClients.map((client) => ( <>
                <tr className={style.tr} key={client.id}>
                  <td className={style.td}>{client.name}</td>
                  <td className={style.td}>{client.nit}</td>
                  <td className={style.td}>{getCityName(allCitys, client.cityId)}</td>
                  <td className={style.td}>{getDeparmentsName(allDepartments, client.departmentId)}</td>
                  <td className={style.td}>{client.emailPrincipal}</td>
                  <td className={style.td}>{client.telefonoPrincipal}</td>
                  <td className={style.tdres}>{client.id}</td>
                  <td className={style.tdres}>{client.name}</td>
                  <td className={style.tdres}>{client.nit}</td>
                  <td className={style.tdetail}>
                    <Link to={`/data/client/${client.id}`}>
                    <button className={style.botonUpdate} disabled={createClients}><img className={style.icon} src="https://api.iconify.design/material-symbols:edit.svg?color=%23313372" alt="editbuttom" /></button>
                    </Link>
                  </td>
                </tr>
                </>))}
            </table>
            </div>
            <div className={style.newUser}>
                {createClients === false ? (<button className={style.formbutton} onClick={handleNewUser}> + Añadir nuevo cliente</button>):(<button className={style.botonClose} onClick={handleNewUser}> X </button>)}
                {createClients === true ? (
                    <div className={style.divCreate}>
                        <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
                            <div className={style.divDatos}>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="name">Cliente</label>
                                    <div className={style.divInput}>
                                        <Controller name="name"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: 'Este campo es obligatorio', maxLength: {value: 30,message: 'Máximo 30 caracteres'}, minLength: {value: 2,message: 'Minímo 2 caracteres'}} }
                                        render={({ field }) => (
                                        <input className={style.input} type="text" {...field} onChange={(e) => {field.onChange(e); trigger("name"); }}/>)}/>
                                        <div className={style.errorMenssage}>
                                            {errors.name && <p className={style.errorText}>{errors.name.message}</p>}
                                        </div>
                                    </div>
                                </div> 
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="nit">NIT</label>
                                    <div className={style.divInput}>
                                        <Controller name="nit"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: 'Este campo es obligatorio', maxLength: {value: 30,message: 'Máximo 30 caracteres'}, minLength: {value: 4,message: 'El Minímo 4 caracteres'}} }
                                        render={({ field }) => (
                                        <input className={style.input} type="text" {...field} onChange={(e) => {field.onChange(e); trigger("nit"); }}/>)}/>
                                        <div className={style.errorMenssage}>
                                            {errors.nit && <p className={style.errorText}>{errors.nit.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="departmentId"> Departamento </label>
                                    <div className={style.divInput}>
                                        <Controller name="departmentId"
                                        control={control}
                                        defaultValue={""}
                                        rules={{ required: 'Seleccione el depertamento' }}
                                        render={({ field }) => (
                                    <select className={style.select} {...field} onChange={(e) => {field.onChange(e); trigger("departmentId"); handleChange(e, allCitys) }}> 
                                        <option value="" disabled> Seleccione el departamento </option>
                                        {allDepartments !== undefined ? allDepartments.map((department) => (
                                        <option key={department.id} value={department.id}>
                                            {department.department}</option>)):null}
                                        </select>)} />
                                        <div className={style.errorMenssage}>
                                            {errors.departmentId && <p className={style.errorText}>{errors.departmentId.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="cityId"> Ciudad </label>
                                    <div className={style.divInput}>
                                        <Controller name="cityId"
                                        control={control}
                                        defaultValue={""}
                                        rules={{ required: 'Seleccione la ciudad' }}
                                        render={({ field }) => (
                                    <select className={style.select} {...field} onChange={(e) => {field.onChange(e); trigger("cityId"); }}> 
                                        <option value="" disabled> Seleccione la ciudad </option>
                                        {filterCity.map((city) => (
                                        <option key={city.id} value={city.id}>
                                            {city.city}</option>))}
                                        </select>)} />
                                        <div className={style.errorMenssage}>
                                            {errors.cityId && <p className={style.errorText}>{errors.cityId.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="telefonoPrincipal">Teléfono</label>
                                    <div className={style.divInput}>
                                        <Controller name="telefonoPrincipal"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: 'Este campo es obligatorio', maxLength: {value: 30,message: 'Máximo 30 caracteres'}, minLength: {value: 4,message: 'El Minímo 4 caracteres'}} }
                                        render={({ field }) => (
                                        <input className={style.input} type="text" {...field} onChange={(e) => {field.onChange(e); trigger("telefonoPrincipal"); }}/>)}/>
                                        <div className={style.errorMenssage}>
                                            {errors.telefonoPrincipal && <p className={style.errorText}>{errors.telefonoPrincipal.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                {/* <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="direccionPrincipal">Dirección</label>
                                    <div className={style.divInput}>
                                        <Controller name="direccionPrincipal"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: 'Este campo es obligatorio', maxLength: {value: 30,message: 'Máximo 30 caracteres'}, minLength: {value: 4,message: 'El Minímo 4 caracteres'}} }
                                        render={({ field }) => (
                                        <input className={style.input} type="text" {...field} onChange={(e) => {field.onChange(e); trigger("direccionPrincipal"); }}/>)}/>
                                        <div className={style.errorMenssage}>
                                            {errors.direccionPrincipal && <p className={style.errorText}>{errors.direccion.message}</p>}
                                        </div>
                                    </div>
                                </div> */}
                        <div className={style.divCampo}>
                            <label className={style.label} htmlFor="emailPrincipal"> Correo electronico </label>
                            <div className={style.divInput}>
                                <Controller name="emailPrincipal"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: 'Este campo es obligatorio',
                                    pattern: {
                                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                      message: 'Ingrese un correo válido',
                                    },
                                }}
                                render={({ field }) => (
                                    <input className={style.input} type="emailPrincipal" {...field} onChange={(e) => {field.onChange(e); trigger("email")}}/>)}/>
                                <div className={style.errorMenssage}>
                                    {errors.emailPrincipal && <p className={style.errorText}>{errors.emailPrincipal.message}</p>}
                                </div>
                            </div>
                        </div>
                        </div>
                        <div>
                            <button type="submit" className={style.formbutton} disabled={formDisabled} >Crear nuevo cliente</button>
                            {confirmRegistro === true ? (<><p className={style.positivo}>Cliente creado con exito</p></>):(confirmRegistro === false ? (<><p className={style.negativo} > No se pudo registrar, vuelve a intentarlo</p></>):(null))}
                        </div>
                        </form>
                    </div>):(null)}
                    <Link to="/data">
                    <button type="button" className={style.backbutton}>Volver</button>
                    </Link>
            </div>
        </div>

        </div>
      );
}

export default Client





// const generatePassword = () => {
//     const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()-_=+[]{}|;:,.<>?';
//     const passwordLength = 8;
//     let password = '';
//     for (let i = 0; i < passwordLength; i++) {
//       const randomIndex = Math.floor(Math.random() * charset.length);
//       password += charset.charAt(randomIndex);
//     }
//     setGeneratedPassword(password);
//   };