// Login and User
export const USER_EXIST = "USER_EXIST"
export const LOGIN = "LOGIN"
export const LOGOUT = "LOGOUT"
export const LOGREPORTS = "LOGREPORTS"
export const SET_LOGIN_REPORT_STATUS = "SET_LOGIN_REPORT_STATUS"
export const USER_DATA = "USER_DATA"
export const SET_USER_DATA = "SET_USER_DATA" 
export const SET_LOGIN_STATUS = "SET_LOGIN_STATUS"
export const ALL_USERS = "ALL_USERS"
// Client actions 
export const ALL_CLIENTS = "ALL_CLIENTS"
// Technical actions 
export const ALL_TECHNICAL = "ALL_TECHNICAL"
// Store actions 
export const ALL_STORES = "ALL_STORES"
export const SEARCH_STORES="SEARCH_STORES"
export const FILTER_STORES="FILTER_STORES"
export const CLEAR_FILTER_STORES="CLEAR_FILTER_STORES"
export const COMBINED_FILTER_STORES="COMBINED_FILTER_STORES"
//Parts actions 
export const ALL_PARTS = "ALL_PARTS"
export const SEARCH_PARTS="SEARCH_PARTS"
export const FILTER_PARTS="FILTER_PARTS"
export const CLEAR_FILTER_PARTS="CLEAR_FILTER_PARTS"
export const COMBINED_FILTER_PARTS="COMBINED_FILTER_PARTS"
//Quotes actions
export const ALL_QUOTES = "ALL_QUOTES"
export const SEARCH_QUOTES="SEARCH_QUOTES"
export const FILTER_QUOTES="FILTER_QUOTES"
export const CLEAR_FILTER_QUOTES="CLEAR_FILTER_QUOTES"
export const COMBINED_FILTER_QUOTES="COMBINED_FILTER_QUOTES"
// Prebills actions
export const ALL_PREBILLS = "ALL_PREBILLS"
export const SEARCH_PREBILLS = "SEARCH_PREBILLS"
export const FILTER_PREBILLS = "FILTER_PREBILLS"
export const CLEAR_FILTER_PREBILLS = "CLEAR_FILTER_PREBILLS"
export const COMBINED_FILTER_PREBILLS = "COMBINED_FILTER_PREBILLS"
//Bills actions
export const ALL_BILLS = "ALL_BILLS"
export const SEARCH_BILLS = "SEARCH_BILLS"
export const FILTER_BILLS = "FILTER_BILLS"
export const CLEAR_FILTER_BILLS = "CLEAR_FILTER_BILLS"
export const COMBINED_FILTER_BILLS = "COMBINED_FILTER_BILLS"
// Services actions 
export const ALL_SERVICES = "ALL_SERVICES"
export const SEARCH_SERVICES= "SEARCH_SERVICES"
export const FILTER_SERVICES = "FILTER_SERVICES"
export const CLEAR_FILTER_SERVICES = "CLEAR_FILTER_SERVICES"
export const COMBINED_FILTER_SERVICES = "COMBINED_FILTER_SERVICES"
// Reports 
export const ALL_REPORTS = "ALL_REPORTS"
export const SEARCH_REPORTS= "SEARCH_REPORTS"
export const FILTER_REPORTS = "FILTER_REPORTS"
export const CLEAR_FILTER_REPORTS = "CLEAR_FILTER_REPORTS"
export const COMBINED_FILTER_REPORTS = "COMBINED_FILTER_REPORTS"
// Supplier
export const ALL_SUPPLIERS = "ALL_SUPPLIERS"
export const SEARCH_SUPPLIERS= "SEARCH_SUPPLIERS"
export const FILTER_SUPPLIERS = "FILTER_SUPPLIERS"
export const CLEAR_FILTER_SUPPLIERS = "CLEAR_FILTER_SUPPLIERS"
export const COMBINED_FILTER_SUPPLIERS = "COMBINED_FILTER_SUPPLIERS"
// Supplier
export const ALL_COUNTS = "ALL_COUNTS"
export const SEARCH_COUNTS= "SEARCH_COUNTS"
export const FILTER_COUNTS = "FILTER_COUNTS"
export const CLEAR_FILTER_COUNTS = "CLEAR_FILTER_COUNTS"
// Demography
export const GET_CITYS = "GET_CITYS"
export const GET_DEPARTMENTS = "GET_DEPARTMENTS"

export const  ALERT_SESSION = "ALERT_SESSION"


