import style from "./supplier.module.css"
import NavBar from "../../../components/NavBar/NavBar"
import { NavLink, Link, useParams } from "react-router-dom"
import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../redux/actions"
import axios from "axios";
import { url } from "../../../App";
import Filtro from "../../../components/Filtro/Filtro"
import Search from "../../../components/Search/Search";


const Supplier = () => {
  const dispatch = useDispatch()
  const allQuotes = useSelector((state) => state.allSuppliers)
  const allClients = useSelector((state) => state.allClients)
  const allStores = useSelector((state) => state.allStores)
  const allCitys = useSelector((state) => state.allCitys)
  const allDepartments = useSelector((state) => state.allDepartments)
  const filterQuotes = useSelector((state) => state.filterSuppliers)
  const [data, setData] = useState(allQuotes)
  useEffect(() => {
    if (filterQuotes !== undefined && filterQuotes.length > 0 && filterQuotes[0] !== "Sin resultados") {
      setData(filterQuotes)
    }
    else if (filterQuotes !== undefined && filterQuotes.length > 0 && filterQuotes[0] === "Sin resultados") {
      setData([])
    }
    else {
      setData(allQuotes)
    }
  }, [allQuotes, filterQuotes])
  // UseEffect 
  useEffect(() => {
    if (!allCitys || allCitys.length === 0) {
      dispatch(actions.allCitys())
    }
    if (!allClients || allClients.length === 0) {
      dispatch(actions.allClients())
    }
    if (!allStores || allStores.length === 0) {
      dispatch(actions.allStores())
    }
    if (!allDepartments || allDepartments.length === 0) {
      dispatch(actions.allDepartments())
    }
  }, [])
  useEffect(() => {
    dispatch(actions.allSuppliers())
  }, [dispatch])
  // Traer nombres
  const getClientName = (allClients, clientId) => {
    const client = allClients.find((client) => client.id === clientId);
    return client ? client.name : 'Cliente desconocido';
  };
  const getStoreName = (allStores, storeId) => {
    const store = allStores.find((store) => store.id === storeId);
    return store ? store.name : 'Tienda desconocido';
  };
  const getCityName = (allCitys, cityId) => {
    const city = allCitys && allCitys.find((city) => city.id === cityId);
    return city ? city.city : 'Ciudad Desconocida';
  };
  const getDepartmentName = (allCitys, cityId) => {
    const city = allCitys && allCitys.find((city) => city.id === cityId);
    return city ? city.city : 'Department Desconocida';
  };
  // Paginado
  const sortedData = data !== undefined && data.length > 0 && [...data].sort((a, b) => new Date(b.id) - new Date(a.id));
  const [pagina, setPagina] = useState(1)
  const porPagina = 8
  const ultimoElemento = pagina * porPagina
  const primerElemento = ultimoElemento - porPagina
  const actualQuotes = sortedData !== undefined && sortedData.length > 0 && sortedData.slice(primerElemento, ultimoElemento)
  const totalPages = sortedData !== undefined && sortedData.length > 0 && Math.ceil(sortedData.length / porPagina);
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }
  useEffect(() => { setPagina(1) }, [totalPages]);
  const maxPaginasAMostrar = 4; // Número máximo de páginas que se mostrarán en la barra de paginación
  const inicioPaginas = pagina <= Math.ceil(maxPaginasAMostrar / 2) ? 1 : Math.max(1, pagina - Math.floor(maxPaginasAMostrar / 2))
  const finPaginas = Math.min(inicioPaginas + maxPaginasAMostrar - 1, totalPages);
  // Renderizado
  return (
    <div className={style.divContenedor}>
      <div className={style.divNavBar}>
        <NavBar />
      </div>
      <div className={style.divPrincipal}>
        <h1 className={style.tituloSeccion}>Proveedores</h1>
        <div className={style.botones}>
          <NavLink to={"/data/supplier/create"}>
            <button className={style.internSectionButtoms}>Crear proveedor</button>
          </NavLink>
        </div>
        <div className={style.divTabla}>
          <table className={style.table}>
            <tr className={style.tr}>
              <th className={style.th}>#</th>
              <th className={style.th}>Proveedor</th>
              <th className={style.th}>Nit</th>
              <th className={style.th}>Ciudad</th>
              <th className={style.th}>Email</th>
              <th className={style.th}>Telefono</th>
              <th className={style.th}>Dirección</th>
              <th className={style.th}>Acciones</th>
              <th className={style.thres}>#</th>
              <th className={style.thres}>Proveedor</th>
              <th className={style.thres}>Nit</th>
              <th className={style.thres}>Ciudad</th>
              <th className={style.thres}>Acciones</th>
            </tr>
            {actualQuotes !== undefined && actualQuotes.length > 0 && actualQuotes.map((orden) => (
              <tr className={style.tr} key={orden.id}>
                <td className={style.td}>{orden.id}</td>
                <td className={style.td}>{orden.name}</td>
                <td className={style.td}>{orden.nit}</td>
                <td className={style.td}>{getCityName(allCitys, orden.cityId)}</td>
                <td className={style.td}>{orden.emailPrincipal}</td>
                <td className={style.td}>{orden.telefonoPrincipal}</td>
                <td className={style.td}>{orden.direccion}</td>
                <td className={style.tdres}>{orden.id}</td>
                <td className={style.tdres}>{orden.name}</td>
                <td className={style.tdres}>{orden.nit}</td>
                <td className={style.tdres}>{getCityName(allCitys, orden.cityId)}</td>
                <td className={style.tdetail}>
                  <Link to={`/data/supplier/view/${orden.id}`}>
                    <button className={style.botonUpdate}><img className={style.icon} src="https://api.iconify.design/material-symbols:open-in-new.svg?color=%23313372" alt="editbuttom" /></button>
                  </Link>
                  <Link to={`/data/supplier/update/${orden.id}`}>
                    <button className={style.botonUpdate}><img className={style.icon} src="https://api.iconify.design/material-symbols:edit.svg?color=%23313372" alt="editbuttom" /></button>
                  </Link>
                </td>
              </tr>))}
          </table>
          {filterQuotes[0] === "Sin resultados" &&
            <div className={style.paginado}>
              <p className={style.mensaje}>Sin resultados en la búsqueda</p>
            </div>}
          {sortedData !== undefined && sortedData.length > 0 &&
            <div className={style.paginado}>
              <button className={style.botonpag} onClick={() => setPagina(1)} disabled={pagina === 1}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:move-last-rtl.svg?color=%23313372" alt="editbuttom" /></button>
              <button className={style.botonpag} onClick={() => setPagina(pagina - 1)} disabled={pagina === 1}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:previous-ltr.svg?color=%23313372" alt="editbuttom" /></button>
              {pageNumbers.slice(inicioPaginas - 1, finPaginas).map((pageNumber) => (
                <button key={pageNumber} className={pageNumber === pagina ? style.pagina : style.paginaboton} onClick={() => setPagina(pageNumber)}>{pageNumber}</button>))}
              <button className={style.botonpag} onClick={() => setPagina(pagina + 1)} disabled={ultimoElemento >= sortedData.length}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:previous-rtl.svg?color=%23313372" alt="editbuttom" /></button>
              <button className={style.botonpag} onClick={() => setPagina(totalPages)} disabled={ultimoElemento >= sortedData.length}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:move-last-ltr.svg?color=%23313372" alt="editbuttom" /></button>
            </div>}
          <NavLink to="/quotes/all">
            <button className={style.backbutton}>Ver todo</button>
          </NavLink>
        </div>
      </div>
    </div>
  )
}


export default Supplier
