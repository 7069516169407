import { ALERT_SESSION, ALL_CLIENTS } from "../action-types";
import axios from "axios"
import {url, urlFront} from "../../App.js";


export const getAllClients = () => {   
    return async (dispatch) => {
    try {
        const config = {
            method: 'get',
            url: `${url}/clients/`,
            headers: {
                'Content-Type': 'application/json',
                'X-Is-Public': 'true',
            },
            withCredentials: true,
        };
        const response = await axios(config)
        dispatch({
            type: ALL_CLIENTS,
            payload: response.data
        })
    } catch (error) {
            const errorResponse = {};
            errorResponse.error = error.message;
            if(error.response.status === 401) {
                dispatch({
                    type: ALERT_SESSION,
                    payload: true
                })

                setTimeout(() => {
                    localStorage.clear('globalState')
                    window.location.replace(urlFront)
                }, [3000])
                
            }  
            return errorResponse;    
        } 
    }
}

