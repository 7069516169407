import style from "./parts.module.css"
import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useForm, Controller } from 'react-hook-form';
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import NavBar from "../../../components/NavBar/NavBar";
import * as actions from "../../../redux/actions"
import {url, urlFront} from "../../../App";
import { ALERT_SESSION } from "../../../redux/action-types";
import AlertSession from "../../../components/AlertSession/AlertSession";


const UpdatePart = (props) => {
    const {id} = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {handleSubmit, control, formState: {errors}, trigger, reset, watch} = useForm()
    let allParts = useSelector((state) => state.allParts)
    let actualPart = allParts.filter((part) => part.id === parseInt(id));
    let dataPart = actualPart[0]
    const allCitys = useSelector((state) => state.allCitys)
    const allDepartments = useSelector((state) => state.allDepartments)
    const alertSession = useSelector((state) => state.alertSession)
    const [filterCity, setFilterCity] = useState([])
    useEffect(()=> {
        if (!allDepartments) {
            dispatch(actions.allDepartments())
        }
    }, [])
    const handleChange = (e, allCitys) => {
        const departmentIdValue = e.target.value
        const citysById = allCitys.filter((city) => city.departmentId === parseInt(departmentIdValue))
        setFilterCity(citysById)
    }
    const [confirmRegistro, setConfirmRegistro] = useState(null);
    const [formDisabled, setFormDisabled] = useState(false);
    const onSubmit = async (data) => {
        data.id = id
        try {
            setFormDisabled(true);
            const config = {
                method: 'patch',
                url: `${url}/parts/update/${data.id}`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                data: data
            };
            const response = await axios(config)
            if (response.data) {
                if (response.data.success === true) {
                    setConfirmRegistro(true)
                }
                else {
                    setConfirmRegistro(false)
                }
            }
            setTimeout(()=> {navigate("/data/part");reset()}, 2000)
        } catch (error) {
          console.error("Error al crear usuario:", error);
          setFormDisabled(false);
          setConfirmRegistro(false)
          reset()

          if(error.response.status === 401) {
            dispatch({
                type: ALERT_SESSION,
                payload: true
            })

            setTimeout(() => {
                localStorage.clear('globalState')
                window.location.replace(urlFront)
            }, [3000])
            
        }  
        }
      };
    if (!dataPart) {
        navigate("/data/part")
    }
    return (
        <div className={style.divContenedor}>
            <NavBar/>
        <div className={style.contenedor}>
        {alertSession && <AlertSession text1="Su sesión ha vencido" text2="Por favor, vuelva a iniciar sesión" disabledBtn={false}/>}
          <div className={style.divComplementario}>
            <h1 className={style.titulo}>Repuestos</h1>
            <h4 className={style.subtitulo}>Actualizar repuesto</h4>
          </div>
            <div className={style.newUser}>
                    <div>
                        <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
                            <div className={style.divDatos}>
                            <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="name">Repuesto</label>
                                    <div className={style.divInput}>
                                        <Controller name="name"
                                        control={control}
                                        defaultValue={dataPart ? dataPart.name : ""}
                                        rules={{ required: 'Este campo es obligatorio', maxLength: {value: 30,message: 'Máximo 30 caracteres'}, minLength: {value: 2,message: 'Minímo 2 caracteres'}} }
                                        render={({ field }) => (
                                        <input className={style.input} type="text" {...field} value={field.value} onChange={(e) => {field.onChange(e); trigger("name"); }}/>)}/>
                                        <div className={style.errorMenssage}>
                                            {errors.name && <p className={style.errorText}>{errors.name.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="subCategory"> Categoria </label>
                                    <div className={style.divInput}>
                                        <Controller name="subCategory"
                                        control={control}
                                        defaultValue={""}
                                        rules={{ required: 'Seleccione la categoría' }}
                                        render={({ field }) => (
                                    <select className={style.select} {...field} onChange={(e) => {field.onChange(e); trigger("subCategory") }}> 
                                        <option value="" disabled> Seleccione la categoría </option>
                                        <option value="Iluminación" > Iluminación </option>
                                        <option value="Refrigeración" > Refrigeración </option>
                                        <option value="Limpieza" > Limpieza </option>
                                        <option value="Otro" > Otro </option>
                                        </select>)} />
                                        <div className={style.errorMenssage}>
                                            {errors.subCategory && <p className={style.errorText}>{errors.subCategory.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="description">Descripción</label>
                                    <div className={style.divInput}>
                                        <Controller name="description"
                                        control={control}
                                        defaultValue={dataPart ? dataPart.description : ""}
                                        rules={{ required: 'Este campo es obligatorio', maxLength: {value: 200,message: 'Máximo 200 caracteres'}, minLength: {value: 10,message: 'Minímo 10 caracteres'}} }
                                        render={({ field }) => (
                                        <textarea className={style.input} {...field} value={field.value} onChange={(e) => {field.onChange(e); trigger("description"); }}/>)}/>
                                        <div className={style.errorMenssage}>
                                            {errors.description && <p className={style.errorText}>{errors.description.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="brand">Marca</label>
                                    <div className={style.divInput}>
                                        <Controller name="brand"
                                        control={control}
                                        defaultValue={dataPart ? dataPart.brand: ""}
                                        rules={{ required: 'Este campo es obligatorio'} }
                                        render={({ field }) => (
                                        <input className={style.input} type="text" value={field.value} {...field} onChange={(e) => {field.onChange(e); trigger("brand"); }}/>)}/>
                                        <div className={style.errorMenssage}>
                                            {errors.brand && <p className={style.errorText}>{errors.brand.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="proveedor">Proveedor</label>
                                    <div className={style.divInput}>
                                        <Controller name="proveedor"
                                        control={control}
                                        defaultValue={dataPart ? dataPart.proveedor : ""}
                                        rules={{ required: 'Este campo es obligatorio', maxLength: {value: 30,message: 'Máximo 30 caracteres'}, minLength: {value: 4,message: 'El Minímo 4 caracteres'}} }
                                        render={({ field }) => (
                                        <input className={style.input} type="text" {...field} value={field.value} onChange={(e) => {field.onChange(e); trigger("proveedor"); }}/>)}/>
                                        <div className={style.errorMenssage}>
                                            {errors.proveedor && <p className={style.errorText}>{errors.proveedor.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="departmentId"> Departamento </label>
                                    <div className={style.divInput}>
                                        <Controller name="departmentId"
                                        control={control}
                                        defaultValue={""}
                                        rules={{ required: 'Seleccione el depertamento' }}
                                        render={({ field }) => (
                                    <select className={style.select} {...field} onChange={(e) => {field.onChange(e); trigger("departmentId"); handleChange(e, allCitys) }}> 
                                        <option value="" disabled> Seleccione el departamento </option>
                                        {allDepartments !== undefined ? allDepartments.map((department) => (
                                        <option key={department.id} value={department.id}>
                                            {department.department}</option>)):null}
                                        </select>)} />
                                        <div className={style.errorMenssage}>
                                            {errors.departmentId && <p className={style.errorText}>{errors.departmentId.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="cityId"> Ciudad </label>
                                    <div className={style.divInput}>
                                        <Controller name="cityId"
                                        control={control}
                                        defaultValue={""}
                                        rules={{ required: 'Seleccione la ciudad' }}
                                        render={({ field }) => (
                                    <select className={style.select} {...field} onChange={(e) => {field.onChange(e); trigger("cityId"); }}> 
                                        <option value="" disabled> Seleccione la ciudad </option>
                                        {filterCity.map((city) => (
                                        <option key={city.id} value={city.id}>
                                            {city.city}</option>))}
                                        </select>)} />
                                        <div className={style.errorMenssage}>
                                            {errors.cityId && <p className={style.errorText}>{errors.cityId.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="cost">Costo</label>
                                    <div className={style.divInput}>
                                        <Controller name="cost"
                                        control={control}
                                        defaultValue={dataPart ? dataPart.cost : ""}
                                        rules={{ required: 'Este campo es obligatorio'} }
                                        render={({ field }) => (
                                        <input className={style.input} type="number" value={field.value} {...field} placeholder="COP $" onChange={(e) => {field.onChange(e); trigger("cost"); }}/>)}/>
                                        <div className={style.errorMenssage}>
                                            {errors.cost && <p className={style.errorText}>{errors.cost.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="price">Precio</label>
                                    <div className={style.divInput}>
                                        <Controller name="price"
                                        control={control}
                                        defaultValue={dataPart ? dataPart.price : ""}
                                        rules={{ required: 'Este campo es obligatorio'} }
                                        render={({ field }) => (
                                        <input className={style.input} type="number" value={field.value} {...field} placeholder="COP $" onChange={(e) => {field.onChange(e); trigger("price"); }}/>)}/>
                                        <div className={style.errorMenssage}>
                                            {errors.price && <p className={style.errorText}>{errors.price.message}</p>}
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div>
                            <button type="submit" className={style.formbutton} disabled={formDisabled} > Actualizar repuesto </button>
                            {confirmRegistro === true ? (<><p className={style.positivo}>Repuesto actualizado con exito</p></>):(confirmRegistro === false ? (<><p className={style.negativo} > No se pudo actualizar, vuelve a intentarlo</p></>):(null))}
                        </div>
                        <Link to="/data/part">
                            <button type="button" className={style.backbutton}>Volver</button>
                        </Link>
                        </form>
                    </div>
            </div>
        </div>
        </div>
      );
}

export default UpdatePart
