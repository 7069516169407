import style from "./count.module.css"
import NavBar from "../../../components/NavBar/NavBar"
import { NavLink, Link, useParams } from "react-router-dom"
import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../redux/actions"
import axios from "axios";
import { url, urlFront } from "../../../App";
import Filtro from "../../../components/Filtro/Filtro"
import Search from "../../../components/Search/Search";
import FiltroAvanzado from "../../../components/Filtro/FiltroAvanzado";
import AlertSession from "../../../components/AlertSession/AlertSession";
import { ALERT_SESSION } from "../../../redux/action-types";


const Counts = () => {
  const dispatch = useDispatch()
  const allQuotes = useSelector((state) => state.allCounts)
  const allClients = useSelector((state) => state.allClients)
  const allSuppliers = useSelector((state) => state.allSuppliers)
  const allStores = useSelector((state) => state.allStores)
  const allCitys = useSelector((state) => state.allCitys)
  const allDepartments = useSelector((state) => state.allDepartments)
  const filterQuotes = useSelector((state) => state.filterCounts)
  const [data, setData] = useState(allQuotes)
  const alertSession = useSelector((state) => state.alertSession)
  useEffect(() => {
    if (filterQuotes !== undefined && filterQuotes.length > 0 && filterQuotes[0] !== "Sin resultados") {
      setData(filterQuotes)
    }
    else if (filterQuotes !== undefined && filterQuotes.length > 0 && filterQuotes[0] === "Sin resultados") {
      setData([])
    }
    else {
      setData(allQuotes)
    }
  }, [allQuotes, filterQuotes])
  // UseEffect 
  useEffect(() => {
    if (!allCitys || allCitys.length === 0) {
      dispatch(actions.allCitys())
    }
    if (!allClients || allClients.length === 0) {
      dispatch(actions.allClients())
    }
    if (!allStores || allStores.length === 0) {
      dispatch(actions.allStores())
    }
    if (!allDepartments || allDepartments.length === 0) {
      dispatch(actions.allDepartments())
    }
    if (!allSuppliers || allSuppliers.length === 0) {
      dispatch(actions.allSuppliers())
    }
  }, [])
  useEffect(() => {
    dispatch(actions.allCounts())
  }, [dispatch])
  // Traer nombres
  const getSupplierName = (allSuppliers, id) => {
    const supplier = allSuppliers.find((supplier) => supplier.id === id);
    return supplier ? supplier.name : 'Proveedor desconocido';
  };
  const getStoreName = (allStores, storeId) => {
    const store = allStores.find((store) => store.id === storeId);
    return store ? store.name : 'Tienda desconocido';
  };
  const getCityName = (allCitys, cityId) => {
    const city = allCitys && allCitys.find((city) => city.id === cityId);
    return city ? city.city : 'Ciudad Desconocida';
  };
  const getDepartmentName = (allCitys, cityId) => {
    const city = allCitys && allCitys.find((city) => city.id === cityId);
    return city ? city.city : 'Department Desconocida';
  };
  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("¿Estás seguro de que quieres eliminar esta cuenta por pagar?");
    if (confirmDelete) {
      try {
        const config = {
          method: 'delete',
          url: `${url}/counts/delete/${id}`,
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
          data: data
        };
        const response = await axios(config)
        if (response.data) {
          if (response.data.success === true) {
            console.log(`Orden con ID #${id} eliminada`);
            alert('Orden eliminada con éxito');
            dispatch(actions.allCounts())
          }
          else {
            console.log(`Orden con ID #${id} NO pudo ser eliminada`);
            alert('No pudo ser eliminada');
          }
        }
      } catch (error) {
        console.error("Error al actualizar cuenta:", error);
        if(error.response.status === 401) {
          dispatch({
              type: ALERT_SESSION,
              payload: true
          })

          setTimeout(() => {
              localStorage.clear('globalState')
              window.location.replace(urlFront)
          }, [3000])
          
      }  
      }

    }
  };
  // Paginado
  const sortedData = data !== undefined && data.length > 0 && [...data].sort((a, b) => new Date(b.id) - new Date(a.id));
  const [pagina, setPagina] = useState(1)
  const porPagina = 8
  const ultimoElemento = pagina * porPagina
  const primerElemento = ultimoElemento - porPagina
  const actualQuotes = sortedData !== undefined && sortedData.length > 0 && sortedData.slice(primerElemento, ultimoElemento)
  const totalPages = sortedData !== undefined && sortedData.length > 0 && Math.ceil(sortedData.length / porPagina);
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }
  useEffect(() => { setPagina(1) }, [totalPages]);
  const maxPaginasAMostrar = 4; // Número máximo de páginas que se mostrarán en la barra de paginación
  const inicioPaginas = pagina <= Math.ceil(maxPaginasAMostrar / 2) ? 1 : Math.max(1, pagina - Math.floor(maxPaginasAMostrar / 2))
  const finPaginas = Math.min(inicioPaginas + maxPaginasAMostrar - 1, totalPages);
  // Renderizado
  return (
    <div className={style.divContenedor}>
      {alertSession && <AlertSession text1="Su sesión ha vencido" text2="Por favor, vuelva a iniciar sesión" disabledBtn={false}/>}
      <div className={style.divNavBar}>
        <NavBar />
      </div>
      <div className={style.divPrincipal}>
        <h1 className={style.tituloSeccion}>Cuentas por pagar</h1>
        <div className={style.botones}>
          <NavLink to={"/accounting/counts/create"}>
            <button className={style.internSectionButtoms}>Crear nueva cuenta</button>
          </NavLink>
        </div>
        <FiltroAvanzado section="counts" />
        <Search section="counts" />
        <div className={style.divTabla}>
          <table className={style.table}>
            <tr className={style.tr}>
              <th className={style.th}>#</th>
              <th className={style.th}>Proveedor</th>
              <th className={style.th}>Valor</th>
              <th className={style.th}>Factura #</th>
              <th className={style.th}>Estado</th>
              <th className={style.th}>Fecha vencimiento</th>
              <th className={style.th}>Acciones</th>
              <th className={style.thres}>#</th>
              <th className={style.thres}>Proveedor</th>
              <th className={style.thres}>Valor</th>
              <th className={style.thres}>Fecha pago</th>
              <th className={style.thres}>Acciones</th>
            </tr>
            {actualQuotes !== undefined && actualQuotes.length > 0 && actualQuotes.map((orden) => (
              <tr className={style.tr} key={orden.id}>
                <td className={style.td}>{orden.id}</td>
                <td className={style.td}>{getSupplierName(allSuppliers, orden.supplierId)}</td>
                <td className={style.td}>${orden.total && orden.total.toLocaleString() || orden.valor}</td>
                <td className={style.td}>{orden.description}</td>
                <td className={style.td} style={{ color: orden.state ? 'green' : 'red' }}>{orden.state === true ? "Pagado" : "Pendiente"}</td>
                <td className={style.td}>{orden.expirationDate}</td>
                <td className={style.tdres}>{orden.id}</td>
                <td className={style.tdres}>{getSupplierName(allSuppliers, orden.supplierId)}</td>
                <td className={style.tdres}>${orden.valor && orden.valor.toLocaleString()}</td>
                <td className={style.tdres}>{orden.expirationDate}</td>
                <td className={style.tdetail}>
                  <Link to={`/accounting/counts/view/${orden.id}`}>
                    <button className={style.botonUpdate}><img className={style.icon} src="https://api.iconify.design/material-symbols:open-in-new.svg?color=%23313372" alt="editbuttom" /></button>
                  </Link>
                  <Link to={`/accounting/counts/update/${orden.id}`}>
                    <button className={style.botonUpdate}><img className={style.icon} src="https://api.iconify.design/material-symbols:edit.svg?color=%23313372" alt="editbuttom" /></button>
                  </Link>
                  <button className={style.botonUpdate} onClick={() => handleDelete(orden.id)}>
                    <img className={style.icon} src="https://api.iconify.design/material-symbols:delete.svg?color=%23313372" alt="delete button" />
                  </button>
                </td>
              </tr>))}
          </table>
          {filterQuotes[0] === "Sin resultados" &&
            <div className={style.paginado}>
              <p className={style.mensaje}>Sin resultados en la búsqueda</p>
            </div>}
          {sortedData !== undefined && sortedData.length > 0 &&
            <div className={style.paginado}>
              <button className={style.botonpag} onClick={() => setPagina(1)} disabled={pagina === 1}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:move-last-rtl.svg?color=%23313372" alt="editbuttom" /></button>
              <button className={style.botonpag} onClick={() => setPagina(pagina - 1)} disabled={pagina === 1}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:previous-ltr.svg?color=%23313372" alt="editbuttom" /></button>
              {pageNumbers.slice(inicioPaginas - 1, finPaginas).map((pageNumber) => (
                <button key={pageNumber} className={pageNumber === pagina ? style.pagina : style.paginaboton} onClick={() => setPagina(pageNumber)}>{pageNumber}</button>))}
              <button className={style.botonpag} onClick={() => setPagina(pagina + 1)} disabled={ultimoElemento >= sortedData.length}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:previous-rtl.svg?color=%23313372" alt="editbuttom" /></button>
              <button className={style.botonpag} onClick={() => setPagina(totalPages)} disabled={ultimoElemento >= sortedData.length}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:move-last-ltr.svg?color=%23313372" alt="editbuttom" /></button>
            </div>}
          <NavLink to="/quotes/all">
            <button className={style.backbutton}>Ver todo</button>
          </NavLink>
        </div>
      </div>
    </div>
  )
}


export default Counts