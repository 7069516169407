import { ALERT_SESSION, ALL_STORES, CLEAR_FILTER_STORES, FILTER_STORES, SEARCH_STORES } from "../action-types";
import axios from "axios"
import {url, urlFront} from "../../App.js";


export const getAllStores = () => {
    return async (dispatch) => {
        try {
            const config = {
                method: 'get',
                url: `${url}/stores/`,
                headers: {
                    'Content-Type': 'application/json',
                    'X-Is-Public': 'true',
                },
                withCredentials: true,
            };
            const response = await axios(config)
            dispatch({
                type: ALL_STORES,
                payload: response.data
            })
        } catch (error) {
            const errorResponse = {};
            errorResponse.error = error.message;
            if(error.response.status === 401) {
                dispatch({
                    type: ALERT_SESSION,
                    payload: true
                })

                setTimeout(() => {
                    localStorage.clear('globalState')
                    window.location.replace(urlFront)
                }, [3000])
                
            }  
            return errorResponse;    
        } 
    }
}

export const clearFilterStores = () => {
    return {
      type: CLEAR_FILTER_STORES,
    };
  };

  export const getFilterStore = (filtros) => {
    return async (dispatch) => {
        try {
            const config = {
                method: 'post',
                url: `${url}/stores/filter`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                data: filtros
            };
            const response = await axios(config)
            if (response.status === 200) {
                dispatch({
                    type: FILTER_STORES,
                    payload: response.data.stores
                })
            }
            else if (response.status === 204) {
                dispatch({
                    type: FILTER_STORES,
                    payload: ["Sin resultados"]
                })
            }
        } catch (error) {
            const errorResponse = {};
            errorResponse.error = error.message;
            if(error.response.status === 401) {
                dispatch({
                    type: ALERT_SESSION,
                    payload: true
                })

                setTimeout(() => {
                    localStorage.clear('globalState')
                    window.location.replace(urlFront)
                }, [3000])
                
            }  
            return errorResponse;    
        } 
    }
}
export const getSearchStores = (partialName) => {
    return async (dispatch) => {
        try {
            const config = {
                method: 'get',
                url: `${url}/stores/search/${partialName}`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            };
            const response = await axios(config)
            if (response.status === 200) {
                dispatch({
                    type: SEARCH_STORES,
                    payload: response.data.stores
                })
            }
            else if (response.status === 204) {
                dispatch({
                    type: SEARCH_STORES,
                    payload: ["Sin resultados"]
                })
            }
        } catch (error) {
            const errorResponse = {};
            errorResponse.error = error.message;
            if(error.response.status === 401) {
                dispatch({
                    type: ALERT_SESSION,
                    payload: true
                })

                setTimeout(() => {
                    localStorage.clear('globalState')
                    window.location.replace(urlFront)
                }, [3000])
                
            }  
            return errorResponse;    
        } 
    }
}