import { USER_DATA, USER_EXIST, LOGIN, LOGOUT, SET_USER_DATA, SET_LOGIN_STATUS, ALL_USERS, LOGREPORTS, SET_LOGIN_REPORT_STATUS, ALERT_SESSION } from "../action-types";
import axios from "axios"
import {url, urlFront} from "../../App.js";



export const loginUser = (userData) => {
    try {
        return async (dispatch) => {
            const config = {
                method: 'post',
                url: `${url}/users/login`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                data: userData
            };
            const response = await axios(config)
            dispatch({
                type: LOGIN,
                payload: response.data
            })
        } 
    } catch (error) {
        const errorResponse = {};
        errorResponse.error = error.message;
        return errorResponse;    
    }
}

export const loginReportAccess = (pass) => {
    try {
        return async (dispatch) => {
            const config = {
                method: 'get',
                url: `${url}/key/1`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            };
            const response = await axios(config)
            const {data} = response
            if (parseInt(pass) === parseInt(data.key.clave)) {
                const datos = {
                    success: true
                }
                dispatch({
                    type: LOGREPORTS,
                    payload: datos
                })
            }
            else {
                const datos = {
                    success: false
                }
                dispatch({
                    type: LOGREPORTS,
                    payload: datos
                })
            }
        } 
    } catch (error) {
        const errorResponse = {};
        errorResponse.error = error.message;
        return errorResponse;    
    }
}

export const existUser = (email) => {
     return async (dispatch) => {
        try {
            const config = {
                method: 'get',
                url: `${url}/users/email/${email}`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            };
            const response = await axios(config)
            dispatch({
                type: USER_EXIST,
                payload: response.data
            })
        } catch (error) {
            const errorResponse = {};
            errorResponse.error = error.message;
            if(error.response.status === 401) {
                dispatch({
                    type: ALERT_SESSION,
                    payload: true
                })

                setTimeout(() => {
                    localStorage.clear('globalState')
                    window.location.replace(urlFront)
                }, [3000])
                
            }  
            return errorResponse;   
        }
    }
}

export const logoutUser = (id) => {
    return async (dispatch) => {
        try {
            const config = {
                method: 'post',
                url: `${url}/users/logout/${id}`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            };
                await axios(config)
                dispatch({
                    type: LOGOUT,
                    payload: null
                })
        } catch (error) {
            const errorResponse = {};
            errorResponse.error = error.message;
            return errorResponse;  
        }  
    }
}

export const setUserDatas = (userData) => {
    return {
      type: SET_USER_DATA,
      payload: userData,
    };
  };
  
  export const setLoginStatu = (loginStatus) => {
    return {
      type: SET_LOGIN_STATUS,
      payload: loginStatus,
    };
  };

  export const setLoginReport = (loginReportStatus) => {
    return {
      type: SET_LOGIN_REPORT_STATUS,
      payload: loginReportStatus,
    };
  };

export const getAllUsers = () => {
    return async (dispatch) => {
        try {
            const config = {
                method: 'get',
                url: `${url}/users/`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            };
            const response = await axios(config)
            dispatch({
                type: ALL_USERS,
                payload: response.data
            })
        } catch (error) {
            const errorResponse = {};
            errorResponse.error = error.message;

            if(error.response.status === 401) {
                dispatch({
                    type: ALERT_SESSION,
                    payload: true
                })

                setTimeout(() => {
                    localStorage.clear('globalState')
                    window.location.replace(urlFront)
                }, [3000])
                
            }  
            return errorResponse;    
        } 
    }
} 
  
  
