import React from "react"
import NavBar from "../../components/NavBar/NavBar"
import { useSelector } from "react-redux"
import { NavLink } from "react-router-dom"
import style from "./Datos.module.css"


const Datos = () => {
    const userData = useSelector((state) => state.userLoginNow)
    return (
        <div className={style.divContenedor}>
          <div className={style.divNavBar}>
          <NavBar/>
          </div>
            <div className={style.divPrincipal}>
                <h1 className={style.tituloSeccion}>Añade/modifica datos</h1>
                <p className={style.parrafo}>En esta sección podrás sumar información, actualizar o modificar de los servicios prestados, de la base de datos de repuestos, de los clientes y sus almacenes. <strong>Por favor tener cuidado al actualizar y/o eliminar, ya que esta información de propaga para todos los usuarios y en toda la base de datos.</strong> </p>
                <p className={style.parrafo}><strong>Si es posible, NO elimine información</strong></p>
                <p className={style.parrafo}>Acceda a las siguientes categorías</p>
                <div className={style.botones}>
                  <NavLink to={"/data/part"}>
                    <button className={style.internSectionButtoms}>Repuestos</button>
                  </NavLink>
                  <NavLink to={"/"}>
                    <button disabled className={style.internSectionButtoms}>Servicios</button>
                  </NavLink>
                  <NavLink to={"/data/client"}>
                    <button className={style.internSectionButtoms}>Clientes</button>
                  </NavLink>
                  <NavLink to={"/data/store"}>
                    <button className={style.internSectionButtoms}>Almacenes</button>
                  </NavLink>
                  <NavLink to={"/data/supplier"}>
                    <button className={style.internSectionButtoms}>Proveedores</button>
                  </NavLink>
                  {userData.profile === "superadmin" &&                   <NavLink to={"/data/user"}>
                    <button className={style.internSectionButtoms}>Usuarios</button>
                  </NavLink>}
                </div>
            </div>
        </div>
    )
}


export default Datos