import style from "./createServices.module.css"
import NavBar from "../../../components/NavBar/NavBar"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom"
import { useState, useEffect } from "react"
import { useForm, Controller } from 'react-hook-form';
import * as actions from "../../../redux/actions"
import axios from "axios"
import { url, urlFront } from "../../../App";
import { ALERT_SESSION } from "../../../redux/action-types";
import AlertSession from "../../../components/AlertSession/AlertSession";


const UpdateService = () => {
    // Importations
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams()
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Sumar 1 porque los meses van de 0 a 11
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    const { handleSubmit, control, formState: { errors }, trigger, reset, watch, register, setValue } = useForm()
    // Global States
    const userData = useSelector((state) => state.userLoginNow)
    const allClients = useSelector((state) => state.allClients)
    const allParts = useSelector((state) => state.allParts)
    const allCitys = useSelector((state) => state.allCitys)
    const allDepartments = useSelector((state) => state.allDepartments)
    const allStores = useSelector((state) => state.allStores)
    const alertSession = useSelector((state) => state.alertSession)
    let client = watch("clientId")
    // Local states
    const [formDisabled, setFormDisabled] = useState(null)
    const [actualClient, setActualClient] = useState("")
    const [quoteList, setQuoteList] = useState([])
    const [countItem, setCountItem] = useState(1)
    const [confirmRegistro, setConfirmRegistro] = useState(null);
    const [actualQuote, setActualQuote] = useState({})
    const handleGoBack = () => {
        navigate(-1); // Navegar hacia atrás, equivalente a history.goBack()
    };
    // Get de quote
    const getActualQuote = async (preBillsId) => {
        try {
            setFormDisabled(true);
            const config = {
                method: 'get',
                url: `${url}/serviceReport/${preBillsId}`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            };
            const response = await axios(config)
            if (response.data) {
                if (response.data.success === true) {
                    const preBill = response.data.serviceReport
                    setActualQuote(preBill)
                }
            }
        } catch (error) {
            console.error("Error al crear tienda:", error);
            setFormDisabled(false);
            setConfirmRegistro(false)

            if(error.response.status === 401) {
                dispatch({
                    type: ALERT_SESSION,
                    payload: true
                })

                setTimeout(() => {
                    localStorage.clear('globalState')
                    window.location.replace(urlFront)
                }, [3000])
                
            }  
        }
    }
    useEffect(() => {
        getActualQuote(id)
    }, [dispatch])
    useEffect(() => {
        setValue('state', actualQuote.state)
        setValue('paymentState', actualQuote.paymentState)
        setValue('codeIdentificator', actualQuote.codeIdentificator)
        setValue('stateDescription', actualQuote.stateDescription)
        setValue('saleOrder', actualQuote.saleOrder)
    }, [actualQuote, setValue]);
    useEffect(() => {
        // Calcula el máximo valor de 'item'
        const maxItem = Math.max(...quoteList.map(obj => obj.item), 0);
        setCountItem(maxItem + 1);
    }, [quoteList]);
    //UseEffect
    useEffect(() => {
        dispatch(actions.allStores())
        dispatch(actions.allClients())
        dispatch(actions.allParts())
    }, [dispatch])
    useEffect(() => {
        setActualClient(client)
    }, [client])
    // Funciones  
    const onSubmit = async (data) => {
        try {
            setFormDisabled(true);
            const config = {
                method: 'patch',
                url: `${url}/serviceReport/update/state/${id}`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                data: data
            };
            const response = await axios(config)
            if (response.data) {
                if (response.data.success === true) {
                    setConfirmRegistro(true)
                }
                else {
                    setConfirmRegistro(false)
                }
            }
            setTimeout(() => {
                setConfirmRegistro(null);
                reset();
                setFormDisabled(false);
                setTimeout(() => {
                    navigate("/services");
                }, 100);
            }, 2000);
        } catch (error) {
            console.error("Error al crear tienda:", error);
            setFormDisabled(false);
            setConfirmRegistro(false)

            if(error.response.status === 401) {
                dispatch({
                    type: ALERT_SESSION,
                    payload: true
                })

                setTimeout(() => {
                    localStorage.clear('globalState')
                    window.location.replace(urlFront)
                }, [3000])
                
            }  
        }
    }
    // Renderizado
    return (
        <div className={style.divContenedor}>
            <div className={style.divNavBar}>
                <NavBar />
            </div>
            <div className={style.divPrincipal}>
            {alertSession && <AlertSession text1="Su sesión ha vencido" text2="Por favor, vuelva a iniciar sesión" disabledBtn={false}/>}
                <h1 className={style.tituloSeccion}>Servicios</h1>
                <h4 className={style.subtitulo}>Actualizar servicio # {actualQuote.id}</h4>
                <div className={style.divForm}>
                    <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
                        <div className={style.rowform}>
                            <div className={style.divDatos}>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="state"> Estado de servicio </label>
                                    <div className={style.divInput}>
                                        <Controller name="state"
                                            control={control}
                                            defaultValue={actualQuote.state}
                                            rules={{ required: 'Este campo es obligatorio' }}
                                            render={({ field }) => (
                                                <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("state"); }}>
                                                    <option value="" disabled> Seleccione el estado </option>
                                                    <option value={true}> Activo </option>
                                                    <option value={false}> Cerrado </option>
                                                </select>)} />
                                        <div className={style.errorMenssage}>
                                            {errors.state && <p className={style.errorText}>{errors.state.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="paymentState"> Estado de pago </label>
                                    <div className={style.divInput}>
                                        <Controller name="paymentState"
                                            control={control}
                                            defaultValue={actualQuote.paymentState}
                                            rules={{ required: 'Este campo es obligatorio' }}
                                            render={({ field }) => (
                                                <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("paymentState"); }}>
                                                    <option value="" disabled> Seleccione el pago </option>
                                                    <option value={true} > Confirmado </option>
                                                    <option value={false} > Pendiente </option>
                                                </select>)} />
                                        <div className={style.errorMenssage}>
                                            {errors.paymentState && <p className={style.errorText}>{errors.paymentState.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="codeIdentificator">Identificador</label>
                                    <div className={style.divInput}>
                                        <Controller name="codeIdentificator"
                                            control={control}
                                            defaultValue={actualQuote.codeIdentificator}
                                            // rules={{ required: 'Este campo es obligatorio', maxLength: { value: 15, message: 'Máximo 15 caracteres' }, minLength: { value: 4, message: 'Minimo 4 caracteres' } }}
                                            render={({ field }) => (
                                                <input className={style.input} type="number" {...field} onChange={(e) => { field.onChange(e); trigger("codeIdentificator"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.codeIdentificator && <p className={style.errorText}>{errors.codeIdentificator.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="saleOrder">Orden de compra</label>
                                    <div className={style.divInput}>
                                        <Controller name="saleOrder"
                                            control={control}
                                            defaultValue={actualQuote.saleOrder}
                                            rules={{ required: 'Este campo es obligatorio', maxLength: { value: 15, message: 'Máximo 15 caracteres' }, minLength: { value: 1, message: 'Minimo 1 caracteres' } }}
                                            render={({ field }) => (
                                                <input className={style.input} type="number" {...field} onChange={(e) => { field.onChange(e); trigger("saleOrder"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.saleOrder && <p className={style.errorText}>{errors.saleOrder.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="stateDescription">Descripción de estado</label>
                                    <div className={style.divInput}>
                                        <Controller name="stateDescription"
                                            control={control}
                                            defaultValue={actualQuote.stateDescription}
                                            // rules={{ required: 'Este campo es obligatorio', maxLength: { value: 30, message: 'Maximo 30 caracteres' }, minLength: { value: 4, message: 'Minimo 4 caracteres' } }}
                                            render={({ field }) => (
                                                <input className={style.input} type="text" {...field} maxLength={30} onChange={(e) => { field.onChange(e); trigger("stateDescription"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.stateDescription && <p className={style.errorText}>{errors.stateDescription.message}</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="submit" className={style.formbutton} >Actualizar servicio</button>
                        {confirmRegistro === true ? (<><p className={style.positivo}>Servicio actualizado con exito</p></>) : (confirmRegistro === false ? (<><p className={style.negativo} > No se pudo registrar, vuelve a intentarlo</p></>) : (null))}
                        <button type="button" className={style.backbutton} onClick={handleGoBack}>Volver</button>
                    </form>
                </div>
            </div>
        </div>
    )

}
export default UpdateService