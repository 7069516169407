import { ALERT_SESSION, ALL_TECHNICAL, LOGREPORTS} from "../action-types.js";
import axios from "axios"
import {url, urlFront} from "../../App.js";


export const getAllTechincal = () => {
    return async (dispatch) => {
        try {
            const config = {
                method: 'get',
                url: `${url}/technical`,
                headers: {
                    'Content-Type': 'application/json',
                    'X-Is-Public': 'true',
                },
                withCredentials: true,
            };
            const response = await axios(config)
            dispatch({
                type: ALL_TECHNICAL,
                payload: response.data.allElements
            })
        } catch (error) {
            const errorResponse = {};
            errorResponse.error = error.message;

            if(error.response.status === 401) {
                dispatch({
                    type: ALERT_SESSION,
                    payload: true
                })

                setTimeout(() => {
                    localStorage.clear('globalState')
                    window.location.replace(urlFront)
                }, [3000])
                
            }  
            return errorResponse;    
        } 
    }
}
export const getTechincalById = (id) => {
    return async (dispatch) => {
        try {
            const config = {
                method: 'get',
                url: `${url}/technical/${id}`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            };
            const response = await axios(config)
            if(response.data.success) {
                dispatch({
                    type: LOGREPORTS,
                    payload: response.data
                })
            }
        } catch (error) {
            const errorResponse = {};
            errorResponse.error = error.message;

            if(error.response.status === 401) {
                dispatch({
                    type: ALERT_SESSION,
                    payload: true
                })

                setTimeout(() => {
                    localStorage.clear('globalState')
                    window.location.replace(urlFront)
                }, [3000])
                
            }  

            return errorResponse;    
        } 
    }
}

export const logoutTechnical = (id) => {
    return async (dispatch) => {
        try {
            const config = {
                method: 'post',
                url: `${url}/technical/logout/${id}`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            };
            await axios(config)
            dispatch({
                type: LOGREPORTS,
                payload: null
            })
        } catch (error) {
            const errorResponse = {};
            errorResponse.error = error.message;
            return errorResponse;  
        }  
    }
    
}