import React from "react"
import NavBar from "../../components/NavBar/NavBar"
import { useSelector } from "react-redux"
import { NavLink } from "react-router-dom"
import "./Home.css"


const Home = () => {
    const userData = useSelector((state) => state.userLoginNow)
    return (
        <>
        <div className="divContenedor">
          <div className="divNavBar">
          <NavBar/>
          </div>
            <div className="divBienvenido">
                <h1 className="titulo">Sistema de integración RefriActive ByG</h1>
                <img className="logo" src="https://res.cloudinary.com/dfmsezslx/image/upload/v1700522308/TECDE/RefriActive_Img_zfhpl2.png" alt="LogoRefriActive" />
                <h2 className="subtitulo">¡Bienvenido de nuevo <strong>{userData?.name + " " + userData?.surname}</strong>!</h2>
                <h3 className="subtitulo2">¿Qué quieres hacer hoy?</h3>
                <div className="botones">
                    <NavLink to={"/reports"}>
                      <button className="internSectionButtoms"> Reportes </button>
                    </NavLink>
                    <NavLink to={"/quotes"}>
                      <button className="internSectionButtoms"> Cotizaciones </button>
                    </NavLink>
                    <NavLink to={"/preBills"}>
                      <button className="internSectionButtoms"> PreFacturas </button>
                    </NavLink>
                    <NavLink to={"/bills"}>
                      <button className="internSectionButtoms"> Facturas </button>
                    </NavLink>
                    <NavLink to={"/services"}>
                      <button className="internSectionButtoms"> Servicios </button>
                    </NavLink>
                    <NavLink to={"/data"}>
                      <button className="internSectionButtoms"> Añade datos </button>
                    </NavLink>
                </div>
            </div>
        </div>
        </>

    )
}

export default Home