import style from "./navbar.module.css"
import { Link, NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const NavBar = () => {
    const location = useLocation().pathname
    const userData = useSelector((state) => state.userLoginNow)
    return (
        <div className={style.contenedorDiv}>
            {location !== "/" &&
                <div className={style.divSeccion}>
                    <NavLink to="/">
                        <button className={location === "/" ? (style.botonSeccion) : (style.botonNoSeccion)} aria-haspopup="true" data-toggle="dropdown"> Inicio </button>
                    </NavLink>
                </div>}
            <div className={style.divSeccion}>
                <NavLink to="/reports">
                    <button className={location.includes("/reports") ? (style.botonSeccion) : (style.botonNoSeccion)} aria-haspopup="true" data-toggle="dropdown"> Reportes </button>
                </NavLink>
                {location.includes("/reports") && (
                    <div className={style.desplegable}>
                        <NavLink to={"/reports/all"}>
                            <buttom className={location.includes("/reports/all") ? style.botonSecundarioSelect : style.botonSecundario}>Resumen</buttom>
                        </NavLink>
                    </div>)}
                {location.includes("/reports") && (
                    <div className={style.desplegable}>
                        <NavLink to={"/reports/projects"}>
                            <buttom className={location.includes("/reports/projects") ? style.botonSecundarioSelect : style.botonSecundario}>Proyectos</buttom>
                        </NavLink>
                    </div>)}
            </div>
            <div className={style.divSeccion}>
                <NavLink to="/quotes">
                    <button className={location.includes("/quotes") ? (style.botonSeccion) : (style.botonNoSeccion)} aria-haspopup="true" data-toggle="dropdown"> Cotizaciones </button>
                </NavLink>
                {location.includes("/quotes") && (
                    <div className={style.desplegable}>
                        <NavLink to={"/quotes/create"}>
                            <buttom className={location.includes("/quotes/create") ? style.botonSecundarioSelect : style.botonSecundario}>Crear cotización</buttom>
                        </NavLink>
                        <NavLink to={"/quotes/user/" + userData?.id}>
                            <buttom className={location.includes("/quotes/user/") ? style.botonSecundarioSelect : style.botonSecundario}>Mis cotizaciones</buttom>
                        </NavLink>
                        <NavLink to={"/quotes/all"}>
                            <buttom className={location.includes("/quotes/all") ? style.botonSecundarioSelect : style.botonSecundario}>Resumen</buttom>
                        </NavLink>
                    </div>)}
            </div>
            <div className={style.divSeccion}>
                <NavLink to="/preBills">
                    <button className={location.includes("/preBills") ? (style.botonSeccion) : (style.botonNoSeccion)} aria-haspopup="true" data-toggle="dropdown"> PreFacturas </button>
                </NavLink>
                {location.includes("/preBills") && (
                    <div className={style.desplegable}>
                        {/* <NavLink  to={"/preBills/create"}> */}
                        {/* <buttom disabled className={location.includes("/preBills/create") ? style.botonSecundarioSelect: style.botonSecundariodisabled}>Crear prefactura</buttom> */}
                        {/* </NavLink> */}
                        <NavLink to={"/preBills/user/" + userData?.id}>
                            <buttom className={location.includes("/preBills/user/") ? style.botonSecundarioSelect : style.botonSecundario}>Mis prefacturas</buttom>
                        </NavLink>
                        <NavLink to={"/preBills/all"}>
                            <buttom className={location.includes("/preBills/all") ? style.botonSecundarioSelect : style.botonSecundario}>Resumen</buttom>
                        </NavLink>
                    </div>)}
            </div>
            <div className={style.divSeccion}>
                <NavLink to="/bills">
                    <button className={location.includes("/bills") ? (style.botonSeccion) : (style.botonNoSeccion)} aria-haspopup="true" data-toggle="dropdown"> Facturas </button>
                </NavLink>
                {location.includes("/bills") && (
                    <div className={style.desplegable}>
                        {/* <NavLink to={"/bills/user/"+ userData.id}>
                        <buttom disabled className={ location.includes("/bills/user/") ? style.botonSecundarioSelect: style.botonSecundario}>Mis facturas</buttom>
                    </NavLink> */}
                        <NavLink to={"/bills/all"}>
                            <buttom className={location.includes("/bills/all") ? style.botonSecundarioSelect : style.botonSecundario}>Resumen</buttom>
                        </NavLink>
                    </div>)}
            </div>
            <div className={style.divSeccion}>
                <NavLink to="/services">
                    <button className={location.includes("/services") ? (style.botonSeccion) : (style.botonNoSeccion)} aria-haspopup="true" data-toggle="dropdown"> Servicios </button>
                </NavLink>
                {location.includes("/services") && (
                    <div className={style.desplegable}>
                        <NavLink to={"/services/user/" + userData?.id}>
                            <buttom className={location.includes("/services/user/") ? style.botonSecundarioSelect : style.botonSecundario}>Mis servicios</buttom>
                        </NavLink>
                        <NavLink to={"/services/all"}>
                            <buttom className={location.includes("/services/all") ? style.botonSecundarioSelect : style.botonSecundario}>Resumen</buttom>
                        </NavLink>
                    </div>)}
            </div>
            {userData?.profile === "superadmin" &&
                <div className={style.divSeccion}>
                    <NavLink to="/accounting">
                        <button className={location.includes("/accounting") ? (style.botonSeccion) : (style.botonNoSeccion)} aria-haspopup="true" data-toggle="dropdown"> Contabilidad </button>
                    </NavLink>
                    {location.includes("/accounting") && (<div className={style.desplegable}>
                        <NavLink to={"/accounting/counts"}>
                            <buttom className={location.includes("/accounting/counts") ? style.botonSecundarioSelect : style.botonSecundario}>Cuentas por pagar</buttom>
                        </NavLink>
                        <buttom disabled className={location.includes("/accounting/prepays") ? style.botonSecundarioSelect : style.botonSecundariodisabled}>Anticipos</buttom>
                        <buttom disabled className={location.includes("/accounting/personalpays") ? style.botonSecundarioSelect : style.botonSecundariodisabled}>Pagos personal</buttom>
                        <buttom disabled className={location.includes("/accounting/incomes") ? style.botonSecundarioSelect : style.botonSecundariodisabled}>Ingresos</buttom>
                        <buttom disabled className={location.includes("/accounting/expenses") ? style.botonSecundarioSelect : style.botonSecundariodisabled}>Egresos</buttom>
                        <buttom disabled className={location.includes("/accounting/banks") ? style.botonSecundarioSelect : style.botonSecundariodisabled}>Bancos</buttom>
                        <buttom disabled className={location.includes("/accounting/balances") ? style.botonSecundarioSelect : style.botonSecundariodisabled}>Balances</buttom>
                    </div>)}
                </div> 
            }
            <div className={style.divSeccion}>
                <NavLink to="/data">
                    <button className={location.includes("/data") ? (style.botonSeccion) : (style.botonNoSeccion)} aria-haspopup="true" data-toggle="dropdown"> Añade datos </button>
                </NavLink>
                {location.includes("/data") && (<div className={style.desplegable}>
                    <NavLink to={"/data/part"}>
                        <buttom className={location.includes("/data/part") ? style.botonSecundarioSelect : style.botonSecundario}>Repuestos</buttom>
                    </NavLink>
                    <buttom disabled className={location.includes("/data/service") ? style.botonSecundarioSelect : style.botonSecundariodisabled}>Servicios</buttom>
                    <NavLink to={"/data/client"}>
                        <buttom className={location.includes("/data/client") ? style.botonSecundarioSelect : style.botonSecundario}>Clientes</buttom>
                    </NavLink>
                    <NavLink to={"/data/store"}>
                        <buttom className={location.includes("/data/store") ? style.botonSecundarioSelect : style.botonSecundario}>Almacenes</buttom>
                    </NavLink>
                    {<NavLink to={"/data/supplier"}>
                        <buttom className={location.includes("/data/supplier") ? style.botonSecundarioSelect : style.botonSecundario}>Proveedores</buttom>
                    </NavLink>}
                    {userData?.profile === "superadmin" && <NavLink to={"/data/user"}>
                        <buttom className={location.includes("/data/user") ? style.botonSecundarioSelect : style.botonSecundario}>Usuarios</buttom>
                    </NavLink>}
                </div>)}
            </div>
        </div>
    );
}

export default NavBar