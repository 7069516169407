import style from "./createServices.module.css"
import NavBar from "../../../components/NavBar/NavBar"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link, useParams } from "react-router-dom"
import { useState, useEffect } from "react"
import { useForm, Controller } from 'react-hook-form';
import * as actions from "../../../redux/actions"
import axios from "axios"
import { url, urlFront } from "../../../App";
import { ALERT_SESSION } from "../../../redux/action-types";
import AlertSession from "../../../components/AlertSession/AlertSession";

const CreateServicesData = () => {
    // Importations
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {id} = useParams()
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Sumar 1 porque los meses van de 0 a 11
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    const { handleSubmit, control, formState: { errors }, trigger, reset, watch, register, setValue } = useForm()
    // Global States
    const userData = useSelector((state) => state.userLoginNow)
    const allClients = useSelector((state) => state.allClients)
    const allParts = useSelector((state) => state.allParts)
    const allCitys = useSelector((state) => state.allCitys)
    const allDepartments = useSelector((state) => state.allDepartments)
    const allStores = useSelector((state) => state.allStores)
    const alertSession = useSelector((state) => state.alertSession)
    let client = watch("clientId")
    // Local states
    const [formDisabled, setFormDisabled] = useState(null)
    const [filterCity, setFilterCity] = useState([])
    const [filterStore, setFilterStore] = useState([])
    const [actualClient, setActualClient] = useState("")
    const [selectedPart, setSelectedPart] = useState("");
    const [quantity, setQuantity] = useState("");
    const [quantityServ, setQuantityServ] = useState("");
    const [serviceDescription, setServiceDescription] = useState("");
    const [servicePrice, setServicePrice] = useState("");
    const [quoteList, setQuoteList] = useState([])
    const [countItem, setCountItem] = useState(1)
    const [confirmRegistro, setConfirmRegistro] = useState(null);
    //UseEffect
    useEffect(() => {
        dispatch(actions.allStores())
        dispatch(actions.allClients())
        dispatch(actions.allParts())
    }, [dispatch])
    useEffect(() => {
        setActualClient(client)
    }, [client])
    // Funciones
    const sumOfTotalPrice = quoteList.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.totalCost;
    }, 0);
    const handleChange = (e, allCitys) => {
        const departmentIdValue = e.target.value
        const citysById = allCitys.filter((city) => city.departmentId === parseInt(departmentIdValue))
        setFilterCity(citysById)
    }
    const handleChangeStore = (e, allStores) => {
        const cityIdValue = e.target.value
        const storesById = allStores.filter((store) => store.cityId === parseInt(cityIdValue))
        const storesByClient = storesById.filter((store) => store.clientId === parseInt(actualClient))
        setFilterStore(storesByClient)
    }
    const getCityName = (allCitys, cityId) => {
        const city = allCitys && allCitys.find((city) => city.id === cityId);
        return city ? city.city : 'Ciudad Desconocida';
    };
    const handleChangeClientId = (e) => {
        setValue('departmentId', "");
        trigger('departmentId');
        setValue('cityId', "");
        trigger('cityId');
        setValue('storeId', "");
        trigger('storeId');
    };
    const handlePartChange = (e) => {
        setSelectedPart(e.target.value);
    };
    const handleQuantityChange = (e) => {
        setQuantity(e.target.value);
    };
    const handleServiceChange = (e) => {
        setServiceDescription(e.target.value);
    };
    const handlePriceChange = (e) => {
        setServicePrice(e.target.value);
    };
    const handleQuantiServiceChange = (e) => {
        setQuantityServ(e.target.value);
    };
    const addPartToQuote = () => {
        const partFilter = allParts.filter((part) => part.id === parseInt(selectedPart));
        if (partFilter.length > 0) { // Verifica que se haya encontrado un elemento
            const part = {
                item: countItem,
                idPart: parseInt(selectedPart),
                description: partFilter[0].name + " " + partFilter[0].description,
                unidad: "UND",
                quantity: parseInt(quantity),
                costUnit: partFilter[0].price,
                totalCost: partFilter[0].price * quantity
            };
            quoteList.push(part)
            setCountItem(countItem + 1)
        }
    };
    const addServiceToQuote = () => {
        const service = {
            item: countItem,
            description: serviceDescription,
            unidad: "UND",
            quantity: parseInt(quantityServ),
            costUnit: parseInt(servicePrice),
            totalCost: servicePrice * quantityServ
        };
        quoteList.push(service)
        setCountItem(countItem + 1)
    }
    const onSubmit = async (datos) => {
        datos.userId = userData.id
        datos.quoteParts = quoteList
        datos.subtotal = sumOfTotalPrice
        datos.iva = sumOfTotalPrice * 0.19
        datos.total = datos.subtotal + datos.iva
        const body = {
            data : datos
        }
        try {
            setFormDisabled(true);
            const config = {
                method: 'patch',
                url: `${url}/serviceReport/update/${id}`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                data: body
            };
            const response = await axios(config)
            if (response.data) {
                if (response.data.success === true) {
                    setConfirmRegistro(true)
                    setTimeout(() => {
                        setConfirmRegistro(null);
                        reset();
                        setFormDisabled(false);
                        setTimeout(() => {
                            navigate("/services");
                        }, 100);
                    }, 2000);
                }
                else {
                    setConfirmRegistro(false)
                }
            }
        } catch (error) {
            console.error("Error al crear tienda:", error);
            setFormDisabled(false);
            setConfirmRegistro(false)

            if(error.response.status === 401) {
                dispatch({
                    type: ALERT_SESSION,
                    payload: true
                })

                setTimeout(() => {
                    localStorage.clear('globalState')
                    window.location.replace(urlFront)
                }, [3000])
                
            }  
        }
    }
    function removeItemById(array, id) {
        const index = array.findIndex(item => item.item === id);

        if (index !== -1) {
            // El elemento con el ID proporcionado fue encontrado, procedemos a eliminarlo
            array.splice(index, 1);
        }
        setCountItem(countItem - 1)
    }
    // Renderizado
    return (
        <div className={style.divContenedor}>
            <div className={style.divNavBar}>
                <NavBar />
            </div>
            <div className={style.divPrincipal}>
            {alertSession && <AlertSession text1="Su sesión ha vencido" text2="Por favor, vuelva a iniciar sesión" disabledBtn={false}/>}
                <h1 className={style.tituloSeccion}>Servicios</h1>
                <h4 className={style.subtitulo}>Completar servicio # {id}</h4>
                <div className={style.divForm}>
                    <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
                        <div className={style.rowform}>
                            <div className={style.divDatos}>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="reportId">Número de reporte</label>
                                    <div className={style.divInput}>
                                        <Controller name="reportId"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: 'Este campo es obligatorio', maxLength: { value: 15, message: 'Máximo 15 caracteres' }, minLength: { value: 4, message: 'Minimo 4 caracteres' } }}
                                            render={({ field }) => (
                                                <input className={style.input} type="number" {...field} onChange={(e) => { field.onChange(e); trigger("reportId"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.reportId && <p className={style.errorText}>{errors.reportId.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="aplicationDate">Fecha de solicitud</label>
                                    <div className={style.divInput}>
                                        <Controller
                                            name="aplicationDate"
                                            control={control}
                                            defaultValue={formattedDate}
                                            rules={{ required: 'Este campo es obligatorio' }}
                                            render={({ field }) => (
                                                <input type="date" className={style.select} {...field} value={field.value} onChange={(e) => { field.onChange(e); trigger("aplicationDate"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.aplicationDate && <p className={style.errorText}>{errors.aplicationDate.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="clientId"> Cliente </label>
                                    <div className={style.divInput}>
                                        <Controller name="clientId"
                                            control={control}
                                            defaultValue={""}
                                            rules={{ required: 'Seleccione el cliente' }}
                                            render={({ field }) => (
                                                <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("clientId"); handleChangeClientId(e) }}>
                                                    <option value="" disabled> Seleccione el cliente </option>
                                                    {allClients !== undefined ? allClients.map((client) => (
                                                        <option key={client.id} value={client.id}>
                                                            {client.name}</option>)) : null}
                                                </select>)} />
                                        <div className={style.errorMenssage}>
                                            {errors.clientId && <p className={style.errorText}>{errors.clientId.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="departmentId"> Departamento </label>
                                    <div className={style.divInput}>
                                        <Controller name="departmentId"
                                            control={control}
                                            defaultValue={""}
                                            rules={{ required: 'Seleccione el depertamento' }}
                                            render={({ field }) => (
                                                <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("departmentId"); handleChange(e, allCitys) }}>
                                                    <option value="" disabled> Seleccione el departamento </option>
                                                    {allDepartments !== undefined ? allDepartments.map((department) => (
                                                        <option key={department.id} value={department.id}>
                                                            {department.department}</option>)) : null}
                                                </select>)} />
                                        <div className={style.errorMenssage}>
                                            {errors.departmentId && <p className={style.errorText}>{errors.departmentId.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="cityId"> Ciudad </label>
                                    <div className={style.divInput}>
                                        <Controller name="cityId"
                                            control={control}
                                            defaultValue={""}
                                            rules={{ required: 'Seleccione la ciudad' }}
                                            render={({ field }) => (
                                                <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("cityId"); handleChangeStore(e, allStores) }}>
                                                    <option value="" disabled> Seleccione la ciudad </option>
                                                    {filterCity !== undefined ? filterCity.map((city) => (
                                                        <option key={city.id} value={city.id}>
                                                            {city.city}</option>)) : null}
                                                </select>)} />
                                        <div className={style.errorMenssage}>
                                            {errors.cityId && <p className={style.errorText}>{errors.cityId.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="storeId"> Almacen </label>
                                    <div className={style.divInput}>
                                        <Controller name="storeId"
                                            control={control}
                                            defaultValue={""}
                                            rules={{ required: 'Seleccione el almacen' }}
                                            render={({ field }) => (
                                                <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("storeId"); }}>
                                                    <option value="" disabled> Seleccione el almacen </option>
                                                    {filterStore.map((store) => (
                                                        <option key={store.id} value={store.id}>
                                                            {store.name}</option>))}
                                                </select>)} />
                                        <div className={style.errorMenssage}>
                                            {errors.storeId && <p className={style.errorText}>{errors.storeId.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="supervisor"> Supervisor</label>
                                    <div className={style.divInput}>
                                        <Controller name="supervisor"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: 'Este campo es obligatorio', maxLength: { value: 30, message: 'Maximo 30 caracteres' }, minLength: { value: 4, message: 'Minimo 4 caracteres' } }}
                                            render={({ field }) => (
                                                <input className={style.input} type="text" {...field} onChange={(e) => { field.onChange(e); trigger("supervisor"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.supervisor && <p className={style.errorText}>{errors.supervisor.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="actaEntrega">Acta de entrega</label>
                                    <div className={style.divInput}>
                                        <Controller name="actaEntrega"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: 'Este campo es obligatorio', maxLength: { value: 30, message: 'Max 30 caracteres' }, minLength: { value: 4, message: 'Min 4 caracteres' } }}
                                            render={({ field }) => (
                                                <input className={style.input} type="text" {...field} onChange={(e) => { field.onChange(e); trigger("actaEntrega"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.actaEntrega && <p className={style.errorText}>{errors.actaEntrega.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="reportRum">RUM</label>
                                    <div className={style.divInput}>
                                        <Controller name="reportRum"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: 'Este campo es obligatorio', maxLength: { value: 30, message: 'Max 30 caracteres' }, minLength: { value: 4, message: 'Min 4 caracteres' } }}
                                            render={({ field }) => (
                                                <input className={style.input} type="text" {...field} onChange={(e) => { field.onChange(e); trigger("reportRum"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.reportRum && <p className={style.errorText}>{errors.reportRum.message}</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={style.divDatos}>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="phone">Telefono</label>
                                    <div className={style.divInput}>
                                        <Controller name="phone"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: 'Este campo es obligatorio', maxLength: { value: 30, message: 'El apellido no puede tener más de 30 caracteres' }, minLength: { value: 4, message: 'El apellido no puede tener menos de 4 caracteres' } }}
                                            render={({ field }) => (
                                                <input className={style.input} type="text" {...field} onChange={(e) => { field.onChange(e); trigger("phone"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.phone && <p className={style.errorText}>{errors.phone.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="email">Correo electronico</label>
                                    <div className={style.divInput}>
                                        <Controller name="email"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: 'Este campo es obligatorio', maxLength: { value: 40, message: 'Maximo 40 caracteres' }, minLength: { value: 5, message: 'Minimo 5 caracteres' } }}
                                            render={({ field }) => (
                                                <input className={style.input} type="text" {...field} onChange={(e) => { field.onChange(e); trigger("email"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.email && <p className={style.errorText}>{errors.email.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="deliveryTime">Tiempo de entrega</label>
                                    <div className={style.divInput}>
                                        <Controller name="deliveryTime"
                                            control={control}
                                            defaultValue={"A convenir"}
                                            rules={{ required: 'Este campo es obligatorio', maxLength: { value: 20, message: 'Maximo 20 caracteres' }, minLength: { value: 5, message: 'Minimo 5 caracteres' } }}
                                            render={({ field }) => (
                                                <input className={style.input} type="text" {...field} value={field.value} onChange={(e) => { field.onChange(e); trigger("deliveryTime"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.deliveryTime && <p className={style.errorText}>{errors.deliveryTime.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="offerValidity"> Validez de la oferta </label>
                                    <div className={style.divInput}>
                                        <Controller name="offerValidity"
                                            control={control}
                                            defaultValue={"10 días"}
                                            rules={{ required: 'Este campo es obligatorio', maxLength: { value: 20, message: 'Maximo 20 caracteres' }, minLength: { value: 5, message: 'Minimo 5 caracteres' } }}
                                            render={({ field }) => (
                                                <input className={style.input} type="text" {...field} value={field.value} onChange={(e) => { field.onChange(e); trigger("offerValidity"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.offerValidity && <p className={style.errorText}>{errors.offerValidity.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="warranty"> Garantía </label>
                                    <div className={style.divInput}>
                                        <Controller name="warranty"
                                            control={control}
                                            defaultValue=""
                                            rules={{ maxLength: { value: 20, message: 'Maximo 20 caracteres' }, minLength: { value: 5, message: 'Minimo 5 caracteres' } }}
                                            render={({ field }) => (
                                                <input className={style.input} type="text" {...field} onChange={(e) => { field.onChange(e); trigger("warranty"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.warranty && <p className={style.errorText}>{errors.warranty.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="paymentMeth">Forma de Pago</label>
                                    <div className={style.divInput}>
                                        <Controller
                                            name="paymentMeth"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: 'Seleccione su forma de pago' }}
                                            render={({ field }) => (
                                                <select className={style.select} {...field} value={field.value} onChange={(e) => { field.onChange(e); trigger("paymentMeth"); }}>
                                                    <option value="" disabled>Seleccione la forma de pago</option>
                                                    <option value="A convenir">A convenir</option>
                                                    <option value="Contado">Contado</option>
                                                    <option value="Crédito hasta 30 días">Crédito a 30 días</option>
                                                    <option value="Crédito 45 días">Crédito a 45 días</option>
                                                    <option value="Crédito 55 días">Crédito a 55 días</option>
                                                    <option value="Crédito 60 días">Crédito a 60 días</option>
                                                    <option value="Crédito 90 días">Crédito a 90 días</option>
                                                </select>)} />
                                        <div className={style.errorMenssage}>
                                            {errors.paymentMeth && <p className={style.errorText}>{errors.paymentMeth.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="observations"> Observaciones </label>
                                    <div className={style.divInput}>
                                        <Controller name="observations"
                                            control={control}
                                            defaultValue=""
                                            rules={{ maxLength: { value: 250, message: 'Maximo 250 caracteres' } }}
                                            render={({ field }) => (
                                                <textarea className={style.input} rows={Math.ceil(100 / 30)} type="text" {...field} onChange={(e) => { field.onChange(e); trigger("observations"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.observations && <p className={style.errorText}>{errors.observations.message}</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={style.divDatos}>
                                <label className={style.label}>Añadir repuestos</label>
                                <div className={style.divCampo}>
                                    <label className={style.label}>Repuesto</label>
                                    <div className={style.divInput}>
                                        <select defaultValue="" className={style.select} onChange={(e) => { handlePartChange(e) }}>
                                            <option value="" disabled>Seleccione el repuesto</option>
                                            {allParts !== undefined &&
                                                allParts
                                                    .slice() // Crear una copia para no modificar el array original
                                                    .sort((a, b) => a.name.localeCompare(b.name)) // Ordenar alfabéticamente
                                                    .map((store) => (
                                                        <option key={store.id} value={store.id}>
                                                            {store.name + ` (${getCityName(allCitys, store.cityId)})`}
                                                        </option>
                                                    ))}
                                        </select>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label}>Cantidad</label>
                                    <div className={style.divInput}>
                                        <input className={style.input} maxLength={10} type="number" onChange={(e) => { handleQuantityChange(e) }} />
                                    </div>
                                </div>
                                <button className={style.formbutton} type="button" onClick={addPartToQuote} disabled={quantity === "" || selectedPart === ""}>Añadir repuesto</button>
                                <div className={style.divDatos}>
                                    <label className={style.label}>Añadir servicio</label>
                                    <div className={style.divCampo}>
                                        <label className={style.label}>Servicio</label>
                                        <div className={style.divInput}>
                                            <textarea className={style.input} rows={Math.ceil(150 / 30)} maxLength={500} type="text" onChange={(e) => { handleServiceChange(e) }} />
                                        </div>
                                    </div>
                                    <div className={style.divCampo}>
                                        <label className={style.label}>Cantidad</label>
                                        <div className={style.divInput}>
                                            <input className={style.input} maxLength={10} type="number" onChange={(e) => { handleQuantiServiceChange(e) }} />
                                        </div>
                                    </div>
                                    <div className={style.divCampo}>
                                        <label className={style.label}>Precio</label>
                                        <div className={style.divInput}>
                                            <input className={style.input} maxLength={10} type="number" placeholder="COP $" onChange={(e) => { handlePriceChange(e) }} />
                                        </div>
                                    </div>
                                    <button className={style.formbutton} onClick={addServiceToQuote} type="button" disabled={serviceDescription === "" || servicePrice === "" || quantityServ === ""} >Añadir servicio</button>
                                </div>
                            </div>
                        </div>
                        <div className={style.divDatos}>
                            <h3 className={style.subtitulo}>Resumen de cotización</h3>
                            <div className={style.divTabla}>
                                <table className={style.table}>
                                    <tr className={style.tr}>
                                        <th className={style.th}>Item</th>
                                        <th className={style.th}>Descripción</th>
                                        <th className={style.th}>Unidad</th>
                                        <th className={style.th}>Cantidad</th>
                                        <th className={style.th}>Valor unitario</th>
                                        <th className={style.th}>Valor total</th>
                                        <th className={style.th}>Acciones</th>
                                        <th className={style.thres}>Descripción</th>
                                        <th className={style.thres}>Cantidad</th>
                                        <th className={style.thres}>Valor unitario</th>
                                        <th className={style.thres}>Acciones</th>
                                    </tr>
                                    {quoteList.map((orden) => (
                                        <tr className={style.tr} key={orden.item}>
                                            <td className={style.td}>{orden.item}</td>
                                            <td className={style.td}>{orden.description}</td>
                                            <td className={style.td}>{orden.unidad}</td>
                                            <td className={style.td}>{orden.quantity}</td>
                                            <td className={style.td}>$ {orden.costUnit.toLocaleString()}</td>
                                            <td className={style.td}>$ {orden.totalCost.toLocaleString()}</td>
                                            <td className={style.tdres}>{orden.description}</td>
                                            <td className={style.tdres}>{orden.quantity}</td>
                                            <td className={style.tdres}>$ {orden.costUnit.toLocaleString()}</td>
                                            <td className={style.tdetail}> <button type="button" className={style.deleteItem} onClick={() => removeItemById(quoteList, orden.item)}> Eliminar </button> </td>
                                        </tr>))}
                                </table>
                            </div>
                        </div>
                        <button type="submit" className={style.formbutton} disabled={quoteList.length < 1} >Actualizar servicio</button>
                        {confirmRegistro === true ? (<><p className={style.positivo}>Servicio actualizado creada con exito</p></>) : (confirmRegistro === false ? (<><p className={style.negativo} > No se pudo registrar, vuelve a intentarlo</p></>) : (null))}
                        <Link to="/quotes">
                            <button type="button" className={style.backbutton}>Volver</button>
                        </Link>
                    </form>
                </div>
            </div>
        </div>
    )

}
export default CreateServicesData