import style from "./createquote.module.css"
import NavBar from "../../../components/NavBar/NavBar"
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Link, useNavigate, useParams } from "react-router-dom"
import { useState, useEffect } from "react"
import { useForm, Controller } from 'react-hook-form';
import { isBefore, parseISO } from "date-fns";
import * as actions from "../../../redux/actions"
import axios from "axios"
import { url, urlFront } from "../../../App";
import { ALERT_SESSION } from "../../../redux/action-types";
import AlertSession from "../../../components/AlertSession/AlertSession";


const ViewQuote = () => {
    // Importations
    const dispatch = useDispatch()
    const { id } = useParams()
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Sumar 1 porque los meses van de 0 a 11
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    const { handleSubmit, control, formState: { errors }, trigger, reset, watch, register, setValue } = useForm()
    // Global States
    const userData = useSelector((state) => state.userLoginNow)
    const allClients = useSelector((state) => state.allClients)
    const allParts = useSelector((state) => state.allParts)
    const allCitys = useSelector((state) => state.allCitys)
    const allDepartments = useSelector((state) => state.allDepartments)
    const allStores = useSelector((state) => state.allStores)
    const alertSession = useSelector((state) => state.alertSession)
    let client = watch("clientId")
    // Local states
    const [formDisabled, setFormDisabled] = useState(null)
    const [filterCity, setFilterCity] = useState([])
    const [filterStore, setFilterStore] = useState([])
    const [actualClient, setActualClient] = useState("")
    const [selectedPart, setSelectedPart] = useState("");
    const [quantity, setQuantity] = useState("");
    const [serviceDescription, setServiceDescription] = useState("");
    const [servicePrice, setServicePrice] = useState("");
    const [quoteList, setQuoteList] = useState([])
    const [countItem, setCountItem] = useState(1)
    const [confirmRegistro, setConfirmRegistro] = useState(null);
    const [actualQuote, setActualQuote] = useState({})
    const [quote, setQuote] = useState({})
    // Get de quote
    const getActualQuote = async (quoteId) => {
        try {
            setFormDisabled(true);
            const config = {
                method: 'get',
                url: `${url}/quotes/${quoteId}`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            };
            const response = await axios(config)
            if (response.data) {
                if (response.data.success === true) {
                    const quote = response.data.quote
                    setActualQuote(quote)
                    setQuoteList(quote.quoteParts)
                }
            }
        } catch (error) {
            console.error("Error al crear tienda:", error);
            setFormDisabled(false);
            setConfirmRegistro(false)

            if(error.response.status === 401) {
                dispatch({
                    type: ALERT_SESSION,
                    payload: true
                })

                setTimeout(() => {
                    localStorage.clear('globalState')
                    window.location.replace(urlFront)
                }, [3000])
                
            }  
        }
    }
    useEffect(() => {
        getActualQuote(id)
    }, [dispatch])
    useEffect(() => {
        // Calcula el máximo valor de 'item'
        const maxItem = Math.max(...quoteList.map(obj => obj.item), 0);
        setCountItem(maxItem + 1);
    }, [quoteList]);
    //UseEffect
    useEffect(() => {
        dispatch(actions.allStores())
        dispatch(actions.allClients())
        dispatch(actions.allParts())
    }, [dispatch])
    useEffect(() => {
        setActualClient(client)
    }, [client])
    // Funciones
    const getClientName = (allClients, clientId) => {
        const client = allClients.find((client) => client.id === clientId);
        return client ? client.name : 'Cliente desconocido';
    };
    const getStoreName = (allStores, storeId) => {
        const store = allStores.find((store) => store.id === storeId);
        return store ? store.name : 'Tienda desconocido';
    };
    const getCityName = (allCitys, cityId) => {
        const city = allCitys && allCitys.find((city) => city.id === cityId);
        return city ? city.city : 'Ciudad Desconocida';
    };
    const handleGeneratePDF = async (data) => {
        const urldata = url
        try {
            data.clientId = getClientName(allClients, data.clientId)
            data.cityId = getCityName(allCitys, data.cityId)
            data.storeId = getStoreName(allStores, data.storeId)
            data.observations = data.observations === null ? "Sin observaciones" : data.observations
            const config = {
                method: 'get',
                url: `${urldata}/quotes/generate`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            };
            const res = await axios(config)
            const apiKey = res.data.respuesta
            const response = await fetch('https://api.prexview.com/v1/transform', {
                method: 'POST',
                headers: {
                    'Authorization': apiKey,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    json: JSON.stringify(data),
                    template: 'refriactivequotes',
                    output: 'pdf'
                })
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `Cotización #${data.id}.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        catch (error) {
            console.error('Error:', error);

            if(error.response.status === 401) {
                dispatch({
                    type: ALERT_SESSION,
                    payload: true
                })

                setTimeout(() => {
                    localStorage.clear('globalState')
                    window.location.replace(urlFront)
                }, [3000])
                
            }  
        }
    };
    const navigate = useNavigate()
    const handleGoBack = () => {
        navigate(-1); // Navegar hacia atrás, equivalente a history.goBack()
    };
    // Renderizado
    return (
        <div className={style.divContenedor}>
            <div className={style.divNavBar}>
                <NavBar />
            </div>
            <div className={style.divPrincipal}>
            {alertSession && <AlertSession text1="Su sesión ha vencido" text2="Por favor, vuelva a iniciar sesión" disabledBtn={false}/>}
                <h1 className={style.tituloSeccion}>Cotizaciones</h1>
                <h4 className={style.subtitulo}>Cotización # {actualQuote.id}</h4>
                <div className={style.divForm}>
                    <div className={style.divDatos}>
                        <div className={style.divCampo}>
                            <label className={style.label}>Número de reporte</label>
                            <p className={style.input}> {actualQuote.reportId}</p>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label}>Fecha de solicitud</label>
                            <p className={style.input}> {actualQuote.aplicationDate}</p>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label}>Cliente</label>
                            <p className={style.input}>{getClientName(allClients, actualQuote.clientId)}</p>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label}>Ciudad</label>
                            <p className={style.input}>{getCityName(allCitys, actualQuote.cityId)}</p>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label}>Almacen</label>
                            <p className={style.input}>{getStoreName(allStores, actualQuote.storeId)}</p>
                        </div>
                    </div>
                    <div className={style.divDatos}>
                        <div className={style.divCampo}>
                            <label className={style.label}>Supervisor</label>
                            <p className={style.input}> {actualQuote.supervisor}</p>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label}>Correo electrónico</label>
                            <p className={style.input}> {actualQuote.email}</p>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label}>Contacto </label>
                            <p className={style.input}>+57 {actualQuote.phone}</p>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label}>Tiempo de entrega </label>
                            <p className={style.input}>{actualQuote.deliveryTime}</p>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label}>Validez de la oferta </label>
                            <p className={style.input}>{actualQuote.offerValidity}</p>
                        </div>
                    </div>
                    <div className={style.divDatos}>
                        <div className={style.divCampo}>
                            <label className={style.label}>Garantía </label>
                            <p className={style.input}>{actualQuote.warranty === "" ? ("Sin información") : (actualQuote.warranty)}</p>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label}>Forma de pago</label>
                            <p className={style.input}>{actualQuote.paymentMeth}</p>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label}>Subototal</label>
                            <p className={style.input}> ${actualQuote !== undefined && actualQuote.subtotal !== undefined && actualQuote.subtotal.toLocaleString()}</p>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label}>IVA</label>
                            <p className={style.input}>${actualQuote !== undefined && actualQuote.iva !== undefined && actualQuote.iva.toLocaleString()}</p>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label}>Total</label>
                            <p className={style.input}>${actualQuote !== undefined && actualQuote.total !== undefined && actualQuote.total.toLocaleString()}</p>
                        </div>
                    </div>
                </div>
                <div className={style.divCampo}>
                    <label className={style.label}>Observaciones</label>
                    <p className={style.input}>{actualQuote !== undefined && actualQuote.observations === null ? "Sin observaciones" : actualQuote.observations}</p>
                </div>
                <div className={style.divDatos}>
                    <h3 className={style.subtitulo}>Resumen de cotización</h3>
                    <div className={style.divTabla}>
                        <table className={style.table}>
                            <tr className={style.tr}>
                                <th className={style.th}>Item</th>
                                <th className={style.th}>Descripción</th>
                                <th className={style.th}>Unidad</th>
                                <th className={style.th}>Cantidad</th>
                                <th className={style.th}>Valor unitario</th>
                                <th className={style.th}>Valor total</th>
                                <th className={style.thres}>Descripción</th>
                                <th className={style.thres}>Cantidad</th>
                                <th className={style.thres}>Valor unitario</th>
                            </tr>
                            {quoteList.map((orden) => (
                                <tr className={style.tr} key={orden.item}>
                                    <td className={style.td}>{orden.item}</td>
                                    <td className={style.td}>{orden.description}</td>
                                    <td className={style.td}>{orden.unidad}</td>
                                    <td className={style.td}>{orden.quantity}</td>
                                    <td className={style.td}>$ {orden.costUnit.toLocaleString()}</td>
                                    <td className={style.td}>$ {orden.totalCost.toLocaleString()}</td>
                                    <td className={style.tdres}>{orden.description}</td>
                                    <td className={style.tdres}>{orden.quantity}</td>
                                    <td className={style.tdres}>$ {orden.costUnit.toLocaleString()}</td>
                                </tr>))}
                        </table>
                    </div>
                </div>
                <Link to={`/quotes/${actualQuote.id}`}>
                    <button type="button" className={style.backbutton}>Editar cotización</button>
                </Link>
                <button type="button" className={style.pdfbutton} onClick={() => handleGeneratePDF(actualQuote)} disabled={actualQuote === undefined || actualQuote.id === undefined}>Descargar PDF</button>
                <button type="button" className={style.backbutton} onClick={handleGoBack}>Volver</button>
            </div>
        </div>
    )

}
export default ViewQuote