import style from "./createsupplier.module.css"
import NavBar from "../../../../components/NavBar/NavBar"
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Link, useNavigate, useParams } from "react-router-dom"
import { useState, useEffect } from "react"
import { useForm, Controller } from 'react-hook-form';
import { isBefore, parseISO } from "date-fns";
import * as actions from "../../../../redux/actions"
import axios from "axios"
import { url, urlFront } from "../../../../App";
import { ALERT_SESSION } from "../../../../redux/action-types";
import AlertSession from "../../../../components/AlertSession/AlertSession";


const ViewSupplier = () => {
    // Importations
    const dispatch = useDispatch()
    const { id } = useParams()
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Sumar 1 porque los meses van de 0 a 11
    const day = String(currentDate.getDate()).padStart(2, '0');
    const { handleSubmit, control, formState: { errors }, trigger, reset, watch, register, setValue } = useForm()
    // Global States
    const userData = useSelector((state) => state.userLoginNow)
    const allClients = useSelector((state) => state.allClients)
    const allParts = useSelector((state) => state.allParts)
    const allCitys = useSelector((state) => state.allCitys)
    const allDepartments = useSelector((state) => state.allDepartments)
    const allStores = useSelector((state) => state.allStores)
    const alertSession = useSelector((state) => state.alertSession)
    // Local states
    const [actualQuote, setActualQuote] = useState({})
    const [actualCounts, setActualCounts] = useState([])
    // Get de quote
    const getActualQuote = async (quoteId) => {
        try {
            const config = {
                method: 'get',
                url: `${url}/suppliers/${quoteId}`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            };
            const response = await axios(config)
            if (response.data) {
                if (response.data.success === true) {
                    const quote = response.data.element
                    setActualQuote(quote)
                }
            }
        } catch (error) {
            console.error("Error al crear tienda:", error);
            if(error.response.status === 401) {
                dispatch({
                    type: ALERT_SESSION,
                    payload: true
                })

                setTimeout(() => {
                    localStorage.clear('globalState')
                    window.location.replace(urlFront)
                }, [3000])
                
            }  
        }
    }
    const getActualCounts = async (supplierId) => {
        try {
            const config = {
                method: 'post',
                url: `${url}/counts/filter`,
                data: {
                    supplierId: supplierId,
                    state: false
                },
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            };
            const response = await axios(config)
            if (response.data) {
                if (response.data.success === true) {
                    const quote = response.data.elements
                    setActualCounts(quote)
                }
            }
        } catch (error) {
            console.error("Error al traer cuentas:", error);

            if(error.response.status === 401) {
                dispatch({
                    type: ALERT_SESSION,
                    payload: true
                })

                setTimeout(() => {
                    localStorage.clear('globalState')
                    window.location.replace(urlFront)
                }, [3000])
                
            }  
        }
    }
    useEffect(() => {
        getActualQuote(id)
    }, [dispatch])
    useEffect(() => {
        getActualCounts(id)
    }, [dispatch])
    //UseEffect
    useEffect(() => {
        dispatch(actions.allStores())
        dispatch(actions.allClients())
        dispatch(actions.allParts())
    }, [dispatch])
    // Funciones
    const getCityName = (allCitys, cityId) => {
        const city = allCitys && allCitys.find((city) => city.id === cityId);
        return city ? city.city : 'Ciudad Desconocida';
    };
    const navigate = useNavigate()
    const handleGoBack = () => {
        navigate(-1); // Navegar hacia atrás, equivalente a history.goBack()
    };
    const calculateBalance = (actualCounts) => {
        if (actualCounts.length > 0) {
            let total = 0
            for (let index = 0; index < actualCounts.length; index++) {
                const element = actualCounts[index];
                total += element.valor
            }
            return "$" + total.toLocaleString()
        }
        else {
            return "Sin cuentas pendientes"
        }
    };
    // Renderizado
    return (
        <div className={style.divContenedor}>
            <div className={style.divNavBar}>
                <NavBar />
            </div>
            <div className={style.divPrincipal}>
            {alertSession && <AlertSession text1="Su sesión ha vencido" text2="Por favor, vuelva a iniciar sesión" disabledBtn={false}/>}
                <h1 className={style.tituloSeccion}>Proveedores</h1>
                <h4 className={style.subtitulo}>Proveedor # {actualQuote.id}</h4>
                <div className={style.divForm}>
                    <div className={style.divDatos}>
                        <div className={style.divCampo}>
                            <label className={style.label}>Proveedor</label>
                            <p className={style.input}> {actualQuote.name}</p>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label}>NIT/CC/DOC</label>
                            <p className={style.input}> {actualQuote.nit}</p>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label}>Ciudad</label>
                            <p className={style.input}>{getCityName(allCitys, actualQuote.cityId)}</p>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label}>Telefono</label>
                            <p className={style.input}>+57 {actualQuote.telefonoPrincipal}</p>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label}>Correo electrónico</label>
                            <p className={style.input}> {actualQuote.emailPrincipal}</p>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label}>Dirección</label>
                            <p className={style.input}>{actualQuote.direccion}</p>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label}>Balance pendiente</label>
                            <p className={style.input}>{calculateBalance(actualCounts)}</p>
                        </div>
                    </div>
                </div>
                <div className={style.divDatos}>
                    <h3 className={style.subtitulo}>Cuentas pendientes</h3>
                    <div className={style.divTabla}>
                        <table className={style.table}>
                            <tr className={style.tr}>
                                <th className={style.th}>#</th>
                                <th className={style.th}>Factura #</th>
                                <th className={style.th}>Fecha de vencimiento</th>
                                <th className={style.th}>Estado</th>
                                <th className={style.th}>Valor</th>
                                <th className={style.th}>Acciones</th>
                                <th className={style.thres}>Factura #</th>
                                <th className={style.thres}>Fecha de vencimiento</th>
                                <th className={style.thres}>Valor</th>
                                <th className={style.thres}>Acciones</th>
                            </tr>
                            {actualCounts.map((orden) => (
                                <tr className={style.tr} key={orden.id}>
                                    <td className={style.td}>{orden.id}</td>
                                    <td className={style.td}>{orden.description}</td>
                                    <td className={style.td}>{orden.expirationDate}</td>
                                    <td className={style.td} style={{ color: orden.state ? 'green' : 'red' }}>{orden.state === true ? "Pagado" : "Pendiente"}</td>
                                    <td className={style.td}>${orden.valor.toLocaleString()}</td>
                                    <td className={style.tdres}>{orden.description}</td>
                                    <td className={style.tdres}>{orden.expirationDate}</td>
                                    <td className={style.tdres}>$ {orden.valor.toLocaleString()}</td>
                                    <td className={style.tdetail}>
                                        <Link to={`/accounting/counts/view/${orden.id}`}>
                                            <button className={style.botonUpdate}><img className={style.icon} src="https://api.iconify.design/material-symbols:open-in-new.svg?color=%23313372" alt="editbuttom" /></button>
                                        </Link>
                                        <Link to={`/accounting/counts/update/${orden.id}`}>
                                            <button className={style.botonUpdate}><img className={style.icon} src="https://api.iconify.design/material-symbols:edit.svg?color=%23313372" alt="editbuttom" /></button>
                                        </Link>
                                    </td>
                                </tr>))}
                        </table>
                    </div>
                </div>
                <Link to={`/data/supplier/update/${actualQuote.id}`}>
                    <button type="button" className={style.backbutton}>Editar proveedor</button>
                </Link>
                <button type="button" className={style.backbutton} onClick={handleGoBack}>Volver</button>
            </div>
        </div>
    )

}
export default ViewSupplier