import style from "./login.module.css"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"
import * as actions from "../../../redux/actions"


const LoginReports = () => {
    // Importations
    const dispatch = useDispatch()
    const navigate = useNavigate()
    // Global States
    const loginReports = useSelector((state) => state.loginReport)
    // Local states
    const [incorrecto, setIncorrecto] = useState(null)
    const [inputValue, setInputValue] = useState(0);
    // Funciones
    const handleChange = (event) => {
        setInputValue(event.target.value);
        setIncorrecto(null)
    };
    const redirect = (inputValue) => {
        dispatch(actions.accessReport(inputValue))
    }
    useEffect(()=> {
        if (loginReports) {
            navigate("/reportes")
        }
        if (loginReports === false) {
            setIncorrecto(true)
        }
    }, [loginReports, navigate])
    // Renderizado
    return (
        <div className={style.divContenedor}>
            <div className={style.divIngreso}>
                <img className={style.logo} src="https://res.cloudinary.com/dfmsezslx/image/upload/v1700522308/TECDE/RefriActive_Img_zfhpl2.png" alt="LogoRefriActive" />
                <h5 className={style.tituloSeccion}>Ingreso reportes</h5>
                <p className={style.parrafo}>Digite la clave para acceder al formulario</p>
                <p className={style.parrafo}>Recuerde que la clave es de <strong>4 digitos</strong></p>
                <input className={style.input} type="number" min="0" onChange={handleChange} placeholder="Ingrese la clave" />
                <button className={style.formbutton} onClick={() => redirect(inputValue)}>Ingresar</button>
                {incorrecto === true ? (
                    <div className={style.divmensajePass}>
                        <span className={style.span}> &#9888; Clave incorrecta </span>
                    </div>) : (null)}
            </div>
        </div>
    )

}
export default LoginReports