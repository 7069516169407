import style from "./createcount.module.css"
import NavBar from "../../../../components/NavBar/NavBar"
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Link, useNavigate, useParams } from "react-router-dom"
import { useState, useEffect } from "react"
import { useForm, Controller } from 'react-hook-form';
import { isBefore, parseISO } from "date-fns";
import * as actions from "../../../../redux/actions"
import axios from "axios"
import { url, urlFront } from "../../../../App";
import { ALERT_SESSION } from "../../../../redux/action-types";
import AlertSession from "../../../../components/AlertSession/AlertSession";


const ViewCount = () => {
    // Importations
    const dispatch = useDispatch()
    const { id } = useParams()
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Sumar 1 porque los meses van de 0 a 11
    const day = String(currentDate.getDate()).padStart(2, '0');
    const { handleSubmit, control, formState: { errors }, trigger, reset, watch, register, setValue } = useForm()
    // Global States
    const userData = useSelector((state) => state.userLoginNow)
    const allSuppliers = useSelector((state) => state.allSuppliers)
    const alertSession = useSelector((state) => state.alertSession)
    // Local states
    const [formDisabled, setFormDisabled] = useState(null)
    const [filterCity, setFilterCity] = useState([])
    const [filterStore, setFilterStore] = useState([])
    const [actualClient, setActualClient] = useState("")
    const [selectedPart, setSelectedPart] = useState("");
    const [quantity, setQuantity] = useState("");
    const [serviceDescription, setServiceDescription] = useState("");
    const [servicePrice, setServicePrice] = useState("");
    const [quoteList, setQuoteList] = useState([])
    const [countItem, setCountItem] = useState(1)
    const [confirmRegistro, setConfirmRegistro] = useState(null);
    const [actualQuote, setActualQuote] = useState({})
    const [quote, setQuote] = useState({})
    // Get de quote
    const getActualQuote = async (quoteId) => {
        try {
            setFormDisabled(true);
            const config = {
                method: 'get',
                url: `${url}/counts/${quoteId}`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            };
            const response = await axios(config)
            if (response.data) {
                if (response.data.success === true) {
                    const quote = response.data.element
                    setActualQuote(quote)
                    if (quote.prepays) {
                        setQuoteList(quote.prepays)
                    }
                }
            }
        } catch (error) {
            console.error("Error al crear tienda:", error);
            setFormDisabled(false);
            setConfirmRegistro(false)

            if(error.response.status === 401) {
                dispatch({
                    type: ALERT_SESSION,
                    payload: true
                })

                setTimeout(() => {
                    localStorage.clear('globalState')
                    window.location.replace(urlFront)
                }, [3000])
                
            }  
        }
    }
    useEffect(() => {
        getActualQuote(id)
    }, [dispatch])
    //UseEffect
    useEffect(() => {
        dispatch(actions.allStores())
        dispatch(actions.allClients())
        dispatch(actions.allParts())
        dispatch(actions.allSuppliers())
    }, [dispatch])
    // Funciones
    const getSupplierName = (allSuppliers, id) => {
        const supplier = allSuppliers.find((supplier) => supplier.id === id);
        return supplier ? supplier.name : 'Proveedor desconocido';
    };
    const navigate = useNavigate()
    const handleGoBack = () => {
        navigate(-1); // Navegar hacia atrás, equivalente a history.goBack()
    };
    const calculateActualValue = (actualQuote) => {
        if (actualQuote.prepays) {
            let total = 0
            for (let index = 0; index < actualQuote.prepays.length; index++) {
                const element = actualQuote.prepays[index];
                total += element.valor
            }
            const restante = actualQuote.total - total
            return restante && restante.toLocaleString()
        }
        else {
            if(actualQuote.total === null) {
                return actualQuote.valor.toLocaleString()
            }else {

                return actualQuote.total && actualQuote.total.toLocaleString()
            }
        }
    };
    const calculateRetencion = (actualQuote) => {
        if (actualQuote.retencion === true) {
            const total = parseInt(actualQuote.valor)
            const porcentaje = parseFloat(actualQuote.valorRetencion)
            const retencion = total*porcentaje/100
            return retencion
        }
        else {
            return null
        }
    };
    // Renderizado
    return (
        <div className={style.divContenedor}>
            <div className={style.divNavBar}>
                <NavBar />
            </div>
            <div className={style.divPrincipal}>
                {alertSession && <AlertSession text1="Su sesión ha vencido" text2="Por favor, vuelva a iniciar sesión" disabledBtn={false}/>}
                <h1 className={style.tituloSeccion}>Cuentas por pagar</h1>
                <h4 className={style.subtitulo}>Cuenta # {actualQuote.id}</h4>
                <div className={style.divForm}>
                    <div className={style.divDatos}>
                        <div className={style.divCampo}>
                            <label className={style.label}>Proveedor</label>
                            <p className={style.input}> {getSupplierName(allSuppliers, actualQuote.supplierId)}</p>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label}>Valor de la cuenta</label>
                            <p className={style.input}>${actualQuote.valor && actualQuote.valor.toLocaleString()}</p>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label}>IVA</label>
                            <p className={style.input}>{actualQuote.iva && actualQuote.iva.toLocaleString()}</p>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label}>Subtotal</label>
                            <p className={style.input}>${actualQuote.subtotal && actualQuote.subtotal.toLocaleString()}</p>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label}>¿Aplica retención?</label>
                            <p className={style.input} style={{ color: actualQuote.retencion ? 'blue' : 'purple' }}>{actualQuote.retencion !== null && actualQuote.retencion === true ? "Aplica" : "No aplica"}</p>
                        </div>
                        {actualQuote.retencion === true && 
                        <div className={style.divCampo}>
                            <label className={style.label}>Porcentaje retención</label>
                            <p className={style.input}>{actualQuote.valorRetencion && actualQuote.valorRetencion.toLocaleString()}</p>
                            
                            <label className={style.label}>Valor retención</label>
                            <p className={style.input}>${calculateRetencion(actualQuote).toLocaleString()}</p>
                        </div>
                        }
                        <div className={style.divCampo}>
                            <label className={style.label}>Total</label>
                            <p className={style.input}>${
                                actualQuote.total ? actualQuote.total.toLocaleString() :
                                parseFloat(actualQuote.valor + calculateRetencion(actualQuote))
                            }</p>
                        </div>                      
                    </div>
                    <div className={style.divDatos}>
                        
                        <div className={style.divCampo}>
                            <label className={style.label}>{quoteList.length === 0 ? "Sin" : "Con"} anticipo</label>
                            <p className={style.input}>${quoteList.reduce((acc, curr) => {
                                return acc + parseInt(curr.valor)
                            }, 0) || 0}</p>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label}>Valor restante</label>
                            <p className={style.input}>${actualQuote.state ? "0 (Pagado)" : calculateActualValue(actualQuote)}</p>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label}>Número de factura</label>
                            <p className={style.input}>{actualQuote.description}</p>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label}>Fecha de registro</label>
                            <p className={style.input}>{actualQuote.date}</p>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label}>Fecha de vencimiento</label>
                            <p className={style.input}>{actualQuote.expirationDate}</p>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label}>Estado</label>
                            <p className={style.input} style={{ color: actualQuote.state ? 'green' : 'red' }}>{actualQuote.state ? "Pagado" : "Pendiente"}</p>
                        </div>  
                        {actualQuote.state === true && <div className={style.divCampo}>
                            <label className={style.label}>Fecha de pago</label>
                            <p className={style.input}>{actualQuote.paymentDate && actualQuote.paymentDate}</p>
                        </div>}
                        <div className={style.divCampo}>
                            <label className={style.label}>Comentario/descripción</label>
                            <p className={style.input}>{actualQuote.comments ? actualQuote.comments : "Sin comentario añadidos"}</p>
                        </div>
                    </div>
                </div>
                <div className={style.divDatos}>
                    <h3 className={style.subtitulo}>Resumen de anticipos</h3>
                    <div className={style.divTabla}>
                        <table className={style.table}>
                            <tr className={style.tr}>
                                <th className={style.th}>#</th>
                                <th className={style.th}>Fecha</th>
                                <th className={style.th}>Valor</th>
                                <th className={style.thres}>Item</th>
                                <th className={style.thres}>Fecha</th>
                                <th className={style.thres}>Valor</th>
                            </tr>
                            {quoteList.map((orden) => (
                                <tr className={style.tr} key={orden.item}>
                                    <td className={style.td}>{orden.item}</td>
                                    <td className={style.td}>{orden.date}</td>
                                    <td className={style.td}>${orden.valor.toLocaleString()}</td>
                                    <td className={style.tdres}>{orden.item}</td>
                                    <td className={style.tdres}>{orden.date}</td>
                                    <td className={style.tdres}>$ {orden.valor.toLocaleString()}</td>
                                </tr>))}
                        </table>
                    </div>
                </div>
                <Link to={`/accounting/counts/update/${actualQuote.id}`}>
                    <button type="button" className={style.backbutton}>Actualizar cuenta</button>
                </Link>
                <button type="button" className={style.backbutton} onClick={handleGoBack}>Volver</button>
            </div>
        </div >
    )
}
export default ViewCount