import React, { useEffect, useState } from 'react';
import Intercom from '@intercom/messenger-js-sdk';

import { useDispatch, useSelector } from "react-redux"
import * as actions from "../../client/src/redux/actions";

export default function Component() {

    const dispatch = useDispatch()

    const [userInfo, setUserInfo] = useState({ id: '', name: '', email: '' });

    const userData = useSelector((state) => state.userLoginNow)
    const techData = useSelector((state) => state.techLoginReport)
    const userIntercomHash = useSelector((state) => state.intercomHash)
    const userExiste = useSelector((state) => state.bodyUser)

    useEffect(() => {
      if(userData?.id === null || userData?.id === undefined || 
        techData?.id === null || techData?.id === undefined) {
        dispatch(actions.exist("gerencia@refriactivebyg.com"))
      }
    }, [dispatch])

    useEffect(() => {
      if(userExiste?.id !== undefined) {
        setUserInfo({
          id: userExiste.id,
          name: userExiste.name === "Gerencia" ? "Refriactive" : userExiste.name,
          email: userExiste.email
        })
      }
    }, [userExiste])
    
    Intercom({
      app_id: 'vyyy9s5s',
      user_id: userData?.id || techData?.id || userInfo.id,
      name: userData?.name || techData?.name || userInfo.name,
      email: userData?.email || techData?.email || userInfo.email,
      user_hash: userIntercomHash
    });
  

   

  }