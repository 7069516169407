import { useEffect, useState } from "react"
import style from "./search.module.css"
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from "react-redux"
import * as actions from "../../redux/actions"



const Search = (props) => {
    const { section } = props
    //Importations
    const dispatch = useDispatch()
    const { handleSubmit, control, trigger, reset } = useForm()
    //States
    const [actualSection, setActualSection] = useState("")
    const [isChecked, setIsChecked] = useState(false);
    const handleToggle = () => {
        setIsChecked(!isChecked);
    };
    useEffect(() => {
        setActualSection(section)
    }, [])
    const onSubmit = async (data) => {
        if (actualSection === "parts") {
            const name = data.search
            dispatch(actions.searchParts(name))
        }
        if (actualSection === "stores") {
            const name = data.search
            dispatch(actions.searchStores(name))
        }
        if (actualSection === "quotes") {
            const id = parseInt(data.search)
            dispatch(actions.searchQuotes(id))
        }
        if (actualSection === "preBills") {
            const id = parseInt(data.search)
            dispatch(actions.searchPreBills(id))
        }
        if (actualSection === "services") {
            const bill = parseInt(data.search)
            const preBill = parseInt(data.searchService)
            const element = {
                billId: bill,
                preBillId: preBill
            }
            dispatch(actions.searchServicesByIds(element))
        }
        if (actualSection === "bills") {
            const quote = parseInt(data.search)
            const preBill = parseInt(data.searchService)
            const element = {
                quoteId: quote,
                preBillId: preBill
            }
            dispatch(actions.searchBills(element))
        }
        if (actualSection === "counts") {
            const id = data.searchCount
            dispatch(actions.searchCounts(id))
        }
    }
    const clearFilter = () => {
        if (actualSection === "parts") {
            dispatch(actions.clearParts())
            reset()
        }
        if (actualSection === "stores") {
            dispatch(actions.clearStores())
            reset()
        }
        if (actualSection === "quotes") {
            dispatch(actions.clearQuotes())
            reset()
        }
        if (actualSection === "preBills") {
            dispatch(actions.clearPreBills())
            reset()
        }
        if (actualSection === "services") {
            dispatch(actions.clearServices())
            reset()
        }
        if (actualSection === "bills") {
            dispatch(actions.clearBills())
            reset()
        }
        if (actualSection === "counts") {
            dispatch(actions.clearCounts())
            reset()
        }
    }
    return (
        <div className={style.contenedor}>
            <div className={style.contenedor}>
                <div className={style.filtro}>
                    <label className={style.label}> Búsqueda </label>
                    <label className={`${style.switch} ${isChecked ? style.checked : ''}`}>
                        <input type="checkbox" onChange={handleToggle} />
                        <span className={style.slider}></span>
                    </label>
                </div>
            </div>
            {isChecked === true ? (<form className={style.form} onSubmit={handleSubmit(onSubmit)}>
                {section !== "counts" && <div className={style.divCampo}>
                    <label className={style.label} htmlFor="search">Búsqueda por {section === "quotes" ? ("cotización") : (section === "parts" ? ("nombre repuesto") : (section === "stores" ? ("nombre almacen") : (section === "preBills" ? ("prefactura") : (section === "bills" ? ("cotización") : (section === "services" ? ("factura"): null)))))}</label>
                    <div className={style.divInput}>
                        <Controller
                            name="search"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <input className={style.input} type={section === "quotes" || section === "preBills" ? ("number") : ("text")} placeholder={section === "parts" ? ("Búsqueda por nombre") : (section === "stores" ? ("Búsqueda por nombre") : (section === "quotes" ? ("Búsqueda por número") : (section === "services" ? ("Búsqueda por número") : ("Búsqueda por número"))))} {...field} onChange={(e) => { field.onChange(e); trigger("searcht") }} />)} />
                    </div>
                </div>}
                {section === "services" || section === "bills" ? (
                    <div className={style.divCampo}>
                        <label className={style.label} htmlFor="searchService">Búsqueda por prefactura</label>
                        <div className={style.divInput}>
                            <Controller
                                name="searchService"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <input className={style.input} type="number" placeholder="Búsqueda por número " {...field} onChange={(e) => { field.onChange(e); trigger("searcht") }} />)} />
                        </div>
                    </div>
                ) : (null)}
                {section === "counts" ? (
                    <div className={style.divCampo}>
                        <label className={style.label} htmlFor="searchCount">Búsqueda por número de factura</label>
                        <div className={style.divInput}>
                            <Controller
                                name="searchCount"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <input className={style.input} type="text" placeholder="Búsqueda por número de factura" {...field} onChange={(e) => { field.onChange(e); trigger("searchCount") }} />)} />
                        </div>
                    </div>
                ) : (null)}
                <div className={style.divBotones}>
                    <button type="submit" className={style.formbutton} ><img className={style.icon} src="https://api.iconify.design/material-symbols:manage-search.svg?color=%23313372" alt="editbuttom" /></button>
                    <button type="button" className={style.backbutton} onClick={() => clearFilter()}><img className={style.icon} src="https://api.iconify.design/ic:baseline-clear.svg?color=%23313372" alt="editbuttom" /></button>
                </div>
            </form>):(null)}
        </div>
    )
}

export default Search