import style from "./createsupplier.module.css"
import NavBar from "../../../../components/NavBar/NavBar"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link, useParams } from "react-router-dom"
import { useState, useEffect } from "react"
import { useForm, Controller } from 'react-hook-form';
import * as actions from "../../../../redux/actions"
import axios from "axios"
import { url, urlFront } from "../../../../App";
import { ALERT_SESSION } from "../../../../redux/action-types";
import AlertSession from "../../../../components/AlertSession/AlertSession";



const UpdateSupplier = () => {
  // Importations
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Sumar 1 porque los meses van de 0 a 11
  const day = String(currentDate.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  const { handleSubmit, control, formState: { errors }, trigger, reset, watch, register, setValue } = useForm()
  // Global States
  const userData = useSelector((state) => state.userLoginNow)
  const allClients = useSelector((state) => state.allClients)
  const allParts = useSelector((state) => state.allParts)
  const allCitys = useSelector((state) => state.allCitys)
  const allDepartments = useSelector((state) => state.allDepartments)
  const allStores = useSelector((state) => state.allStores)
  const alertSession = useSelector((state) => state.alertSession)
  let client = watch("clientId")
  // Local states
  const [formDisabled, setFormDisabled] = useState(null)
  const [filterCity, setFilterCity] = useState([])
  const [filterStore, setFilterStore] = useState([])
  const [actualClient, setActualClient] = useState("")
  const [selectedPart, setSelectedPart] = useState("");
  const [quantity, setQuantity] = useState("");
  const [serviceDescription, setServiceDescription] = useState("");
  const [servicePrice, setServicePrice] = useState("");
  const [quoteList, setQuoteList] = useState([])
  const [countItem, setCountItem] = useState(1)
  const [confirmRegistro, setConfirmRegistro] = useState(null);
  const [actualQuote, setActualQuote] = useState({})
  const [quote, setQuote] = useState({})
  // Get de quote
  const initialDepartment = (id, allCitys) => {
    const city = allCitys && allCitys.find((city) => city.id === parseInt(id))
    if (city) {
      return city.departmentId
    }
  }
  const getActualQuote = async (quoteId) => {
    try {
      setFormDisabled(true);
      const config = {
        method: 'get',
        url: `${url}/suppliers/${quoteId}`,
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      };
      const response = await axios(config)
      if (response.data) {
        if (response.data.success === true) {
          const quote = response.data.element
          setActualQuote(quote)
        }
      }
    } catch (error) {
      console.error("Error al crear tienda:", error);
      setFormDisabled(false);
      setConfirmRegistro(false)

      if(error.response.status === 401) {
        dispatch({
            type: ALERT_SESSION,
            payload: true
        })

        setTimeout(() => {
            localStorage.clear('globalState')
            window.location.replace(urlFront)
        }, [3000])
        
    }  
    }
  }
  useEffect(() => {
    setValue('name', actualQuote.name);
    setValue('departmentId', initialDepartment(actualQuote.cityId, allCitys))
    setValue("cityId", actualQuote.cityId)
    setValue('nit', actualQuote.nit);
    setValue('telefonoPrincipal', actualQuote.telefonoPrincipal);
    setValue("emailPrincipal", actualQuote.emailPrincipal)
    setValue("direccion", actualQuote.direccion)
  }, [actualQuote, setValue]);
  useEffect(() => {
    getActualQuote(id)
  }, [dispatch])
  useEffect(() => {
    // Calcula el máximo valor de 'item'
    const maxItem = Math.max(...quoteList.map(obj => obj.item), 0);
    setCountItem(maxItem + 1);
  }, [quoteList]);
  //UseEffect
  useEffect(() => {
    dispatch(actions.allStores())
    dispatch(actions.allClients())
    dispatch(actions.allParts())
  }, [dispatch])
  useEffect(() => {
    setActualClient(client)
  }, [client])
  // Funciones
  const sumOfTotalPrice = quoteList.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.totalCost;
  }, 0);
  const departmentIdValue = watch("departmentId");
  useEffect(() => {
    const citiesById = allCitys.filter(city => city.departmentId === parseInt(departmentIdValue));
    setFilterCity(citiesById)
  }, [departmentIdValue, allCitys, setFilterCity]);
  const cityIdValue = watch("cityId");
  useEffect(() => {
    const storesById = allStores.filter((store) => store.cityId === parseInt(cityIdValue))
    const storesByClient = storesById.filter((store) => store.clientId === parseInt(actualQuote.clientId))
    setFilterStore(storesByClient)
  }, [departmentIdValue, allCitys, setFilterCity]);
  const handleChange = (e, allCitys) => {
    const departmentIdValue = e.target.value
    const citysById = allCitys.filter((city) => city.departmentId === parseInt(departmentIdValue))
    setFilterCity(citysById)
  }
  const handleChangeStore = (e, allStores) => {
    const cityIdValue = e.target.value
    const storesById = allStores.filter((store) => store.cityId === parseInt(cityIdValue))
    const storesByClient = storesById.filter((store) => store.clientId === parseInt(actualClient))
    setFilterStore(storesByClient)
  }
  const onSubmit = async (data) => {
    try {
      setFormDisabled(true);
      const config = {
        method: 'patch',
        url: `${url}/suppliers/update/${id}`,
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
        data: data
      };
      const response = await axios(config)
      if (response.data) {
        if (response.data.success === true) {
          setConfirmRegistro(true)
        }
        else {
          setConfirmRegistro(false)
        }
      }
      setTimeout(() => {
        setConfirmRegistro(null);
        reset();
        setFormDisabled(false);
        setTimeout(() => {
          navigate("/data/supplier");
        }, 100);
      }, 2000);
    } catch (error) {
      console.error("Error al actualizar supplier:", error);
      setFormDisabled(false);
      setConfirmRegistro(false)

      if(error.response.status === 401) {
        dispatch({
            type: ALERT_SESSION,
            payload: true
        })

        setTimeout(() => {
            localStorage.clear('globalState')
            window.location.replace(urlFront)
        }, [3000])
        
    }  

    }
  }
  const handleGoBack = () => {
    navigate(-1); // Navegar hacia atrás, equivalente a history.goBack()
  }
  // Renderizado
  return (
    <div className={style.divContenedor}>
      <div className={style.divNavBar}>
        <NavBar />
      </div>
      <div className={style.divPrincipal}>
      {alertSession && <AlertSession text1="Su sesión ha vencido" text2="Por favor, vuelva a iniciar sesión" disabledBtn={false}/>}
        <h1 className={style.tituloSeccion}>Proveedores</h1>
        <h4 className={style.subtitulo}>Actualizar proveedor # {actualQuote.id}</h4>
        <div className={style.divForm}>
          <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
            <div className={style.rowform}>
              <div className={style.divDatos}>
                <div className={style.divCampo}>
                  <label className={style.label} htmlFor="name"> Proveedor </label>
                  <div className={style.divInput}>
                    <Controller name="name"
                      control={control}
                      defaultValue=""
                      rules={{ required: 'Este campo es obligatorio', maxLength: { value: 30, message: 'Maximo 30 caracteres' }, minLength: { value: 4, message: 'Minimo 4 caracteres' } }}
                      render={({ field }) => (
                        <input className={style.input} type="text" {...field} onChange={(e) => { field.onChange(e); trigger("name"); }} />)} />
                    <div className={style.errorMenssage}>
                      {errors.name && <p className={style.errorText}>{errors.name.message}</p>}
                    </div>
                  </div>
                </div>
                <div className={style.divCampo}>
                  <label className={style.label} htmlFor="nit">NIT/CC/DOC</label>
                  <div className={style.divInput}>
                    <Controller name="nit"
                      control={control}
                      defaultValue=""
                      rules={{ required: 'Este campo es obligatorio', maxLength: { value: 30, message: 'Maximo 30 caracteres' }, minLength: { value: 4, message: 'Minimo 4 caracteres' } }}
                      render={({ field }) => (
                        <input className={style.input} type="text" {...field} onChange={(e) => { field.onChange(e); trigger("nit"); }} />)} />
                    <div className={style.errorMenssage}>
                      {errors.nit && <p className={style.errorText}>{errors.nit.message}</p>}
                    </div>
                  </div>
                </div>
                <div className={style.divCampo}>
                  <label className={style.label} htmlFor="departmentId"> Departamento </label>
                  <div className={style.divInput}>
                    <Controller name="departmentId"
                      control={control}
                      defaultValue={""}
                      rules={{ required: 'Seleccione el depertamento' }}
                      render={({ field }) => (
                        <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("departmentId"); handleChange(e, allCitys) }}>
                          <option value="" disabled> Seleccione el departamento </option>
                          {allDepartments !== undefined ? allDepartments.map((department) => (
                            <option key={department.id} value={department.id}>
                              {department.department}</option>)) : null}
                        </select>)} />
                    <div className={style.errorMenssage}>
                      {errors.departmentId && <p className={style.errorText}>{errors.departmentId.message}</p>}
                    </div>
                  </div>
                </div>
                <div className={style.divCampo}>
                  <label className={style.label} htmlFor="cityId"> Ciudad </label>
                  <div className={style.divInput}>
                    <Controller name="cityId"
                      control={control}
                      defaultValue={""}
                      rules={{ required: 'Seleccione la ciudad' }}
                      render={({ field }) => (
                        <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("cityId"); handleChangeStore(e, allStores) }}>
                          <option value="" disabled> Seleccione la ciudad </option>
                          {filterCity !== undefined ? filterCity.map((city) => (
                            <option key={city.id} value={city.id}>
                              {city.city}</option>)) : null}
                        </select>)} />
                    <div className={style.errorMenssage}>
                      {errors.cityId && <p className={style.errorText}>{errors.cityId.message}</p>}
                    </div>
                  </div>
                </div>
                <div className={style.divCampo}>
                  <label className={style.label} htmlFor="telefonoPrincipal">Telefono</label>
                  <div className={style.divInput}>
                    <Controller name="telefonoPrincipal"
                      control={control}
                      defaultValue=""
                      rules={{ required: 'Este campo es obligatorio', maxLength: { value: 30, message: 'Maximo 30 caracteres' }, minLength: { value: 4, message: 'Minimo 4 caracteres' } }}
                      render={({ field }) => (
                        <input className={style.input} type="text" {...field} onChange={(e) => { field.onChange(e); trigger("telefonoPrincipal"); }} />)} />
                    <div className={style.errorMenssage}>
                      {errors.telefonoPrincipal && <p className={style.errorText}>{errors.telefonoPrincipal.message}</p>}
                    </div>
                  </div>
                </div>
                <div className={style.divCampo}>
                  <label className={style.label} htmlFor="emailPrincipal">Correo electronico</label>
                  <div className={style.divInput}>
                    <Controller name="emailPrincipal"
                      control={control}
                      defaultValue=""
                      rules={{ required: 'Este campo es obligatorio', maxLength: { value: 40, message: 'Maximo 40 caracteres' }, minLength: { value: 5, message: 'Minimo 5 caracteres' } }}
                      render={({ field }) => (
                        <input className={style.input} type="text" {...field} onChange={(e) => { field.onChange(e); trigger("emailPrincipal"); }} />)} />
                    <div className={style.errorMenssage}>
                      {errors.emailPrincipal && <p className={style.errorText}>{errors.emailPrincipal.message}</p>}
                    </div>
                  </div>
                </div>
                <div className={style.divCampo}>
                  <label className={style.label} htmlFor="direccion">Dirección</label>
                  <div className={style.divInput}>
                    <Controller name="direccion"
                      control={control}
                      defaultValue=""
                      rules={{ required: 'Este campo es obligatorio', maxLength: { value: 100, message: 'Maximo 100 caracteres' }, minLength: { value: 4, message: 'Minimo 4 caracteres' } }}
                      render={({ field }) => (
                        <textarea className={style.input} type="text" {...field} onChange={(e) => { field.onChange(e); trigger("direccion"); }} />)} />
                    <div className={style.errorMenssage}>
                      {errors.direccion && <p className={style.errorText}>{errors.direccion.message}</p>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button type="submit" className={style.formbutton} >Actualizar proveedor</button>
            {confirmRegistro === true ? (<><p className={style.positivo}>Proveedor actualizado con exito</p></>) : (confirmRegistro === false ? (<><p className={style.negativo} > No se pudo registrar, vuelve a intentarlo</p></>) : (null))}
            <button type="button" className={style.backbutton} onClick={handleGoBack}>Volver</button>
          </form>
        </div>
      </div>
    </div>
  )

}
export default UpdateSupplier