import style from "./store.module.css"
import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useForm, Controller } from 'react-hook-form';
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import NavBar from "../../../components/NavBar/NavBar";
import * as actions from "../../../redux/actions"
import {url, urlFront} from "../../../App";
import { ALERT_SESSION } from "../../../redux/action-types";
import AlertSession from "../../../components/AlertSession/AlertSession";


const UpdateStore = (props) => {
    const {id} = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {handleSubmit, control, formState: {errors}, trigger, reset, watch, setValue} = useForm()
    let allStores = useSelector((state) => state.allStores)
    let actualStore = allStores.filter((store) => store.id === parseInt(id));
    let dataStore = actualStore[0]
    const allCitys = useSelector((state) => state.allCitys)
    const allClients = useSelector((state) => state.allClients)
    const allDepartments = useSelector((state) => state.allDepartments)
    const alertSession = useSelector((state) => state.alertSession)
    const [filterCity, setFilterCity] = useState([])
    useEffect(()=> {
        if (!allDepartments) {
            dispatch(actions.allDepartments())
        }
        if (allClients.length === 0) {
          dispatch(actions.allClients())
        }
    }, [])
    const handleChange = (e, allCitys) => {
        const departmentIdValue = e.target.value
        const citysById = allCitys.filter((city) => city.departmentId === parseInt(departmentIdValue))
        setFilterCity(citysById)
    }
    const handleChangeClientId = (e) => {
        setValue('departmentId', "");
        trigger('departmentId');
        setValue('cityId', "");
        trigger('cityId');
        setValue('storeId', "");
        trigger('storeId');
      };
    const [confirmRegistro, setConfirmRegistro] = useState(null);
    const [formDisabled, setFormDisabled] = useState(false);
    const onSubmit = async (data) => {
        data.id = id
        try {
            setFormDisabled(true);
            const config = {
                method: 'patch',
                url: `${url}/stores/update/${data.id}`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                data: data
            };
            const response = await axios(config)
            if (response.data) {
                if (response.data.success === true) {
                    setConfirmRegistro(true)
                }
                else {
                    setConfirmRegistro(false)
                }
            }
            setTimeout(()=> {navigate("/data/store");reset()}, 2000)
        } catch (error) {
          console.error("Error al crear usuario:", error);
          setFormDisabled(false);
          setConfirmRegistro(false)
          reset()
          if(error.response.status === 401) {
            dispatch({
                type: ALERT_SESSION,
                payload: true
            })

            setTimeout(() => {
                localStorage.clear('globalState')
                window.location.replace(urlFront)
            }, [3000])
            
        }  
        }
      };
      useEffect(() => {
        dispatch(actions.allClients())
      }, [])
    if (!dataStore) {
        navigate("/data/store")
    }
    return (
        <div className={style.divContenedor}>
            <NavBar/>
        <div className={style.contenedor}>
        {alertSession && <AlertSession text1="Su sesión ha vencido" text2="Por favor, vuelva a iniciar sesión" disabledBtn={false}/>}
          <div className={style.divComplementario}>
            <h1 className={style.titulo}>Almacenes</h1>
            <h4 className={style.subtitulo}>Actualizar almacen</h4>
          </div>
            <div className={style.newUser}>
                    <div>
                        <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
                            <div className={style.divDatos}>
                            <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="name">Almacen</label>
                                    <div className={style.divInput}>
                                        <Controller name="name"
                                        control={control}
                                        defaultValue={dataStore ? dataStore.name : ""}
                                        rules={{ required: 'Este campo es obligatorio', maxLength: {value: 30,message: 'Máximo 30 caracteres'}, minLength: {value: 2,message: 'Minímo 2 caracteres'}} }
                                        render={({ field }) => (
                                        <input className={style.input} type="text" {...field} value={field.value} onChange={(e) => {field.onChange(e); trigger("name"); }}/>)}/>
                                        <div className={style.errorMenssage}>
                                            {errors.name && <p className={style.errorText}>{errors.name.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="clientId"> Cliente </label>
                                    <div className={style.divInput}>
                                        <Controller name="clientId"
                                        control={control}
                                        defaultValue={""}
                                        rules={{ required: 'Seleccione el cliente' }}
                                        render={({ field }) => (
                                    <select className={style.select} {...field} onChange={(e) => {field.onChange(e); trigger("clientId");handleChangeClientId(e) }}> 
                                        <option value="" disabled> Seleccione el cliente </option>
                                        {allClients !== undefined ? allClients.map((client) => (
                                        <option key={client.id} value={client.id}>
                                            {client.name}</option>)):null}
                                        </select>)} />
                                        <div className={style.errorMenssage}>
                                            {errors.clientId && <p className={style.errorText}>{errors.clientId.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="departmentId"> Departamento </label>
                                    <div className={style.divInput}>
                                        <Controller name="departmentId"
                                        control={control}
                                        defaultValue={""}
                                        rules={{ required: 'Seleccione el depertamento' }}
                                        render={({ field }) => (
                                    <select className={style.select} {...field} onChange={(e) => {field.onChange(e); trigger("departmentId"); handleChange(e, allCitys) }}> 
                                        <option value="" disabled> Seleccione el departamento </option>
                                        {allDepartments !== undefined ? allDepartments.map((department) => (
                                        <option key={department.id} value={department.id}>
                                            {department.department}</option>)):null}
                                        </select>)} />
                                        <div className={style.errorMenssage}>
                                            {errors.departmentId && <p className={style.errorText}>{errors.departmentId.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="cityId"> Ciudad </label>
                                    <div className={style.divInput}>
                                        <Controller name="cityId"
                                        control={control}
                                        defaultValue={""}
                                        rules={{ required: 'Seleccione la ciudad' }}
                                        render={({ field }) => (
                                    <select className={style.select} {...field} onChange={(e) => {field.onChange(e); trigger("cityId"); }}> 
                                        <option value="" disabled> Seleccione la ciudad </option>
                                        {filterCity.map((city) => (
                                        <option key={city.id} value={city.id}>
                                            {city.city}</option>))}
                                        </select>)} />
                                        <div className={style.errorMenssage}>
                                            {errors.cityId && <p className={style.errorText}>{errors.cityId.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="town">Barrio</label>
                                    <div className={style.divInput}>
                                        <Controller name="town"
                                        control={control}
                                        defaultValue={dataStore ? dataStore.town : ""}
                                        rules={{ required: 'Este campo es obligatorio', maxLength: {value: 30,message: 'Máximo 30 caracteres'}, minLength: {value: 4,message: 'El Minímo 4 caracteres'}} }
                                        render={({ field }) => (
                                        <input className={style.input} type="text" {...field} value={field.value} onChange={(e) => {field.onChange(e); trigger("town"); }}/>)}/>
                                        <div className={style.errorMenssage}>
                                            {errors.town && <p className={style.errorText}>{errors.town.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                {/* <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="zoneId">Zona</label>
                                    <div className={style.divInput}>
                                        <Controller name="zoneId"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: 'Este campo es obligatorio'} }
                                        render={({ field }) => (
                                        <input className={style.input} type="number" {...field} onChange={(e) => {field.onChange(e); trigger("zoneId"); }}/>)}/>
                                        <div className={style.errorMenssage}>
                                            {errors.zoneId && <p className={style.errorText}>{errors.zoneId.message}</p>}
                                        </div>
                                    </div>
                                </div> */}
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="address">Dirección</label>
                                    <div className={style.divInput}>
                                        <Controller name="address"
                                        control={control}
                                        defaultValue={dataStore ? dataStore.address : ""}
                                        rules={{ required: 'Este campo es obligatorio', maxLength: {value: 30,message: 'Máximo 30 caracteres'}, minLength: {value: 4,message: 'El Minímo 4 caracteres'}} }
                                        render={({ field }) => (
                                        <input className={style.input} type="text" {...field} value={field.value} onChange={(e) => {field.onChange(e); trigger("address"); }}/>)}/>
                                        <div className={style.errorMenssage}>
                                            {errors.address && <p className={style.errorText}>{errors.address.message}</p>}
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div>
                            <button type="submit" className={style.formbutton} disabled={formDisabled} > Actualizar almacen </button>
                            {confirmRegistro === true ? (<><p className={style.positivo}>Almacen actualizado con exito</p></>):(confirmRegistro === false ? (<><p className={style.negativo} > No se pudo actualizar, vuelve a intentarlo</p></>):(null))}
                        </div>
                        <Link to="/data/store">
                            <button type="button" className={style.backbutton}>Volver</button>
                        </Link>
                        </form>
                    </div>
            </div>
        </div>
        </div>
      );
}

export default UpdateStore
