import style from "./reports.module.css"
import NavBar from "../../components/NavBar/NavBar"
import { NavLink, Link, useParams } from "react-router-dom"
import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/actions"
import axios from "axios";
import { url, urlFront } from "../../App";
import { ALERT_SESSION } from "../../redux/action-types";
import AlertSession from "../../components/AlertSession/AlertSession";


const PuReportsFilter = () => {
  const dispatch = useDispatch()
  const allQuotes = useSelector((state) => state.allReports)
  const allClients = useSelector((state) => state.allClients)
  const allStores = useSelector((state) => state.allStores)
  const allCitys = useSelector((state) => state.allCitys)
  const allDeparments = useSelector((state) => state.allDeparments)
  const filterQuotes = useSelector((state) => state.filterReports)
  const allTechnicals = useSelector((state) => state.allTechnicals)
  const alertSession = useSelector((state) => state.alertSession)
  const [tecnicos, setTecnicos] = useState([])
  const [data, setData] = useState(allQuotes)
  const { zone, client } = useParams();
  useEffect(() => {
    if (filterQuotes !== undefined && filterQuotes.length > 0 && filterQuotes[0] !== "Sin resultados") {
      if (zone && zones[zone-1].departmentId.length < 2) {
        filterQuotes.filter(obj => obj.departmentId === zone && zones[zone-1].departmentId[0]);
      }
      setData(filterQuotes)
    }
    else if (filterQuotes !== undefined && filterQuotes.length > 0 && filterQuotes[0] === "Sin resultados") {
      setData([])
    }
    else {
      setData(allQuotes)
    }
  }, [allQuotes, filterQuotes])
  // UseEffect 
  useEffect(() => {
    if (!allCitys || allCitys.length === 0) {
      dispatch(actions.allCitys())
    }
    if (!allClients || allClients.length === 0) {
      dispatch(actions.allClients())
    }
    if (!allStores || allStores.length === 0) {
      dispatch(actions.allStores())
    }
    if (!allDeparments || allDeparments.length === 0) {
      dispatch(actions.allDepartments())
    }
  }, [])
  useEffect(() => {
    dispatch(actions.allReports())
  }, [dispatch])
  useEffect(() => {
    if (tecnicos.length === 0) {
      dispatch(actions.allTechnical())
      setTecnicos(allTechnicals)
    }
  }, [dispatch, allTechnicals])
  const zones = [
    {
      id: 1,
      name: "Zona Santanderes",
      departmentId: [22, 27]
    },
    {
      id: 2,
      name: "Zona Antioquia",
      departmentId: [2]
    },
    {
      id: 3,
      name: "Zona Costa",
      departmentId: [4,5,28,18,19,14,11]
    }
  ]
  useEffect(() => {
    const dataSend = {
      departmentId: zone && zones[zone-1].departmentId,
      clientId: client,
    }
    dispatch(actions.filterActionReports(dataSend))
  }, [dispatch, zone, client])
  // Traer nombres
  const getClientName = (allClients, clientId) => {
    const client = allClients.find((client) => client.id === parseInt(clientId));
    return client ? client.name : 'Cliente desconocido';
  };
  const getStoreName = (allStores, storeId) => {
    const store = allStores.find((store) => store.id === storeId);
    return store ? store.name : 'Tienda desconocido';
  };
  const getCityName = (allCitys, cityId) => {
    const city = allCitys && allCitys.find((city) => city.id === cityId);
    return city ? city.city : 'Ciudad Desconocida';
  };
  const getDepartmentName = (allDepartments, cityId) => {
    const department = allDepartments && allDepartments.find((city) => city.id === cityId);
    return department ? department.department : 'Departamento desconocido';
  };
  // Paginado
  const sortedData = data !== undefined && data.length > 0 &&
    [...data]
      .filter(a => parseInt(a.id) >= 150000) // Filtra para tomar solo los elementos con id >= 150000
      .sort((a, b) => new Date(b.id) - new Date(a.id)); // Ordena los elementos por id de forma descendente
  const [pagina, setPagina] = useState(1)
  const porPagina = 8
  const ultimoElemento = pagina * porPagina
  const primerElemento = ultimoElemento - porPagina
  const actualQuotes = sortedData !== undefined && sortedData.length > 0 && sortedData.slice(primerElemento, ultimoElemento)
  const totalPages = sortedData !== undefined && sortedData.length > 0 && Math.ceil(sortedData.length / porPagina);
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }
  useEffect(() => { setPagina(1) }, [totalPages]);
  const maxPaginasAMostrar = 4; // Número máximo de páginas que se mostrarán en la barra de paginación
  const inicioPaginas = pagina <= Math.ceil(maxPaginasAMostrar / 2) ? 1 : Math.max(1, pagina - Math.floor(maxPaginasAMostrar / 2))
  const finPaginas = Math.min(inicioPaginas + maxPaginasAMostrar - 1, totalPages);
  const handleChangeTech = (id, tecnicos) => {
    const tecnicoActual = tecnicos.find((tecnico) => tecnico.id === id)
    return tecnicoActual
  };
  async function convertImageToBase64(url) {
    try {
      const response = await fetch(url);
      const blob = await response.blob();

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          // El resultado contiene el prefijo de Data URL que es necesario para imágenes base64
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      throw new Error(error.message);
    }
  }

  // Generar pdf
  const handleGeneratePDF = async (data) => {
    const urldata = url
    try {
      const tecnicoPdf = await handleChangeTech(data.technician, tecnicos)
      data.clientId = getClientName(allClients, data.clientId)
      data.storeId = getStoreName(allStores, data.storeId)
      data.state = data.state === true ? "Activo" : "Cerrado"
      data.stateMachine = data.stateMachine === true ? "Sí" : "No"
      data.quoteState = data.stateMachine === true ? "Sí" : "No"
      data.date = data.date && new Date(data.date).toLocaleDateString('en-CA')
      data.technician = tecnicoPdf.name + " " + tecnicoPdf.surname
      data.id = data.id
      data.priority = data.priority === null || data.priority === undefined || data.priority === "" ? "Sin prioridad, no se requiere repuestos" : data.priority
      const config2 = {
        method: 'get',
        url: `https://apirefriactive.tecde.co/reports/collage/${data.pdf}`,
        headers: {
          'Content-Type': 'application/json',
        }
      };
      let valor = undefined
      const response2 = await axios(config2);
      if (response2.data) {
        if (response2.status === 200) {
          valor = response2.data.pdf
        } else {
          console.log("Error con la respuesta del servidor");
        }
      }
      data.imagen = await convertImageToBase64(valor)
      const config = {
        method: 'get',
        url: `${urldata}/quotes/generate`,
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      };
      const res = await axios(config)
      const apiKey = res.data.respuesta
      const response = await fetch('https://api.prexview.com/v1/transform', {
        method: 'POST',
        headers: {
          'Authorization': apiKey,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          json: JSON.stringify(data),
          template: 'refriactivereports',
          output: 'pdf'
        })
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `Reporte #${data.id}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    catch (error) {
      console.error('Error:', error);

      if(error.response.status === 401) {
        dispatch({
            type: ALERT_SESSION,
            payload: true
        })

        setTimeout(() => {
            localStorage.clear('globalState')
            window.location.replace(`${urlFront}/inicio`)
        }, [3000])
      }  
    }
  };
  // Renderizado
  return (
    <div className={style.divContenedor}>
      {alertSession && <AlertSession text1="Su sesión ha vencido" text2="Por favor, vuelva a iniciar sesión" disabledBtn={false}/>}
      <div className={style.divPrincipal}>
        <img className={style.logo} src="https://res.cloudinary.com/dfmsezslx/image/upload/v1700522308/TECDE/RefriActive_Img_zfhpl2.png" alt="LogoRefriActive" />
        <h1 className={style.tituloSeccion}>Reportes</h1>
        <div className={style.divTabla}>
          <h4 className={style.subtituloSeccion}>Reportes {zone && zones[zone-1].name} </h4>
          <h4 className={style.subtituloSeccion}>Reportes del cliente {client && getClientName(allClients, client)} </h4>
          <table className={style.table}>
            <tr className={style.tr}>
              <th className={style.th}>#</th>
              <th className={style.th}>Fecha</th>
              <th className={style.th}>Cliente</th>
              <th className={style.th}>Ciudad</th>
              <th className={style.th}>Almacen</th>
              <th className={style.th}>Servicio</th>
              <th className={style.th}>Acciones</th>
              <th className={style.thres}>#</th>
              <th className={style.thres}>Fecha</th>
              <th className={style.thres}>Cliente</th>
              <th className={style.thres}>Almacen</th>
              <th className={style.thres}>Servicio</th>
              <th className={style.thres}>Acciones</th>
            </tr>
            {actualQuotes !== undefined && actualQuotes.length > 0 && actualQuotes.map((orden) => (
              <tr className={style.tr} key={orden.id}>
                <td className={style.td}>{orden.id}</td>
                <td className={style.td}>
                  {orden.date && orden.date.split('T')[0]}
                </td>
                <td className={style.td}>{getClientName(allClients, orden.clientId)}</td>
                <td className={style.td}>{getCityName(allCitys, orden.cityId)}</td>
                <td className={style.td}>{getStoreName(allStores, orden.storeId)}</td>
                <td className={style.td}>{orden.serviceType}</td>
                <td className={style.tdres}>{orden.id}</td>
                <td className={style.tdres}>
                  {orden.date && orden.date.split('T')[0]}
                </td>
                <td className={style.tdres}>{getClientName(allClients, orden.clientId)}</td>
                <td className={style.tdres}>{getStoreName(allStores, orden.storeId)}</td>
                <td className={style.tdres}>{orden.serviceType}</td>
                <td className={style.tdetail}>
                  <Link to={`/reports/public/view/${orden.id}`}>
                    <button className={style.botonUpdate}><img className={style.icon} src="https://api.iconify.design/material-symbols:open-in-new.svg?color=%23313372" alt="editbuttom" /></button>
                  </Link>
                </td>
              </tr>))}
          </table>
          {filterQuotes[0] === "Sin resultados" &&
            <div className={style.paginado}>
              <p className={style.mensaje}>Sin resultados en la búsqueda</p>
            </div>}
          {sortedData !== undefined && sortedData.length > 0 &&
            <div className={style.paginado}>
              <button className={style.botonpag} onClick={() => setPagina(1)} disabled={pagina === 1}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:move-last-rtl.svg?color=%23313372" alt="editbuttom" /></button>
              <button className={style.botonpag} onClick={() => setPagina(pagina - 1)} disabled={pagina === 1}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:previous-ltr.svg?color=%23313372" alt="editbuttom" /></button>
              {pageNumbers.slice(inicioPaginas - 1, finPaginas).map((pageNumber) => (
                <button key={pageNumber} className={pageNumber === pagina ? style.pagina : style.paginaboton} onClick={() => setPagina(pageNumber)}>{pageNumber}</button>))}
              <button className={style.botonpag} onClick={() => setPagina(pagina + 1)} disabled={ultimoElemento >= sortedData.length}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:previous-rtl.svg?color=%23313372" alt="editbuttom" /></button>
              <button className={style.botonpag} onClick={() => setPagina(totalPages)} disabled={ultimoElemento >= sortedData.length}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:move-last-ltr.svg?color=%23313372" alt="editbuttom" /></button>
            </div>}
          <NavLink to="/reports/public/all">
            <button className={style.backbutton}>Ver todo</button>
          </NavLink>
        </div>
      </div>
    </div>
  )
}


export default PuReportsFilter
