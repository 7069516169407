import style from "./reportCreate.module.css"
import { useDispatch, useSelector } from "react-redux";
import {Link} from "react-router-dom"
import { useState, useEffect } from "react"
import * as actions from "../../../redux/actions"

const CreateReport = () => {
    // Importations
    const dispatch = useDispatch()
    // Global States
    const allTechnicals = useSelector((state) => state.allTechnicals)
    const [tecnicos,setTecnicos] = useState([])
    useEffect(() => {
        if (tecnicos.length === 0) {
            dispatch(actions.allTechnical())
            const uniqueTechnicals = Array.from(new Map(allTechnicals.map(tech => [tech.name.toLowerCase(), tech])).values());
            setTecnicos(uniqueTechnicals);
        }
    }, [allTechnicals, dispatch])
    // Local states
    const [inputValue, setInputValue] = useState({});
    // Funciones
    const handleChange = (event) => {
        const tecnicoActual = tecnicos.find((tecnico) => tecnico.id === event.target.value)
        localStorage.setItem("techId", JSON.stringify(tecnicoActual.id));

        dispatch(actions.technicalById(tecnicoActual.id))

        setInputValue(tecnicoActual);
    };
    // Renderizado
    return (
        <div className={style.divContenedor2}>
            <div className={style.divIngreso}>
                <img className={style.logo2} src="https://res.cloudinary.com/dfmsezslx/image/upload/v1700522308/TECDE/RefriActive_Img_zfhpl2.png" alt="LogoRefriActive" />
                <h2 className={style.tituloSeccion}>Bievenido equipo técnico RefriActive</h2>
                <p className={style.parrafo}>Por favor seleccione su información para iniciar</p>
                <select className={style.select} defaultValue={""} onChange={handleChange} >
                    <option value="" disabled> Seleccione su nombre </option>
                    {tecnicos.map((tecnico) => (
                        tecnico.authorized &&
                        <option key={tecnico.id} value={tecnico.id}>
                            {tecnico.name + " " + tecnico.surname}</option>))}
                </select>
                {inputValue !== undefined && inputValue.name !== undefined ? (
                    <div className={style.mensaje}>
                        <p className={style.parrafo}>Vas a crear este reporte como <strong>{inputValue.name + " " + inputValue.surname}</strong> </p>                        
                        <Link to={`/reportes/new/${inputValue.id}`}>
                            <button className={style.formbutton}>Iniciar reporte</button>
                        </Link>
                    </div>
                ) : (null)
                }
                {inputValue.name === undefined ? (
                    <div className={style.mensaje}>
                        <p className={style.parrafo}>¿No encuentras tu nombre en la lista? Crea tu perfil</p>
                        <Link to="/reportes/techprofile">
                            <button className={style.formbutton}>Crear perfil</button>
                        </Link>
                    </div>
                ) : (null)
                }
            </div>
        </div>
    )

}
export default CreateReport