import style from "./services.module.css"
import NavBar from "../../components/NavBar/NavBar"
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"
import * as actions from "../../redux/actions"

const MyServices = () => {
  // Importations
  const userData = useSelector((state) => state.userLoginNow)
  const myId = userData.id
  const [myQuotes, setMyQuotes] = useState([]);
  const dispatch = useDispatch()
  const allClients = useSelector((state) => state.allClients)
  const allServices = useSelector((state) => state.allServices)
  const data = allServices
  const sortedData = data !== undefined && data.length > 0 && [...data].sort((a, b) => new Date(b.aplicationDate) - new Date(a.aplicationDate));
  // useEffect
  // Traer servicios
  useEffect(() => {
    dispatch(actions.allServices())
  }, [dispatch])
  // Filtrar servicios por usuario
  useEffect(() => {
    const userQuotes = sortedData !== undefined && sortedData.length > 0 && sortedData.filter((quote) => quote.userId === myId);
    const userSort = userQuotes !== undefined && userQuotes.length > 0 && userQuotes.sort((a, b) => new Date(b.aplicationDate) - new Date(a.aplicationDate));
    setMyQuotes(userSort);
  }, [myId])
  // Paginado
  const [pagina, setPagina] = useState(1)
  const porPagina = 8
  const ultimoElemento = pagina * porPagina
  const primerElemento = ultimoElemento - porPagina
  const actualQuotes = myQuotes !== undefined && myQuotes.length > 0 && myQuotes.slice(primerElemento, ultimoElemento)
  const totalPages = Math.ceil(myQuotes.length / porPagina);
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }
  useEffect(() => { setPagina(1) }, [totalPages]);
  const maxPaginasAMostrar = 4; // Número máximo de páginas que se mostrarán en la barra de paginación
  const inicioPaginas = pagina <= Math.ceil(maxPaginasAMostrar / 2) ? 1 : Math.max(1, pagina - Math.floor(maxPaginasAMostrar / 2))
  const finPaginas = Math.min(inicioPaginas + maxPaginasAMostrar - 1, totalPages);
  // Funciones
  const navigate = useNavigate()
  const handleGoBack = () => {
    navigate(-1); // Navegar hacia atrás, equivalente a history.goBack()
  };
  // Traer nombres 
  const getClientName = (allClients, clientId) => {
    const client = allClients.find((client) => client.id === clientId);
    return client ? client.name : 'Cliente desconocido';
  };
  // Renderizado
  return (
    <div className={style.divContenedor}>
      <div className={style.divNavBar}>
        <NavBar />
      </div>
      <div className={style.divPrincipal}>
        <h1 className={style.tituloSeccion}>Servicios</h1>
        <h4 className={style.subtitulo}>Mis servicios</h4>
        <div className={style.divTabla}>
          <h5 className={style.subtituloTabla}>Servicios creadas por {userData.name + " " + userData.surname}</h5>
          <table className={style.table}>
            <tr className={style.tr}>
              <th className={style.th}>#</th>
              <th className={style.th}>Reporte</th>
              <th className={style.th}>Cotización</th>
              <th className={style.th}>PreFactura</th>
              <th className={style.th}>Factura</th>
              <th className={style.th}>Cliente</th>
              <th className={style.th}>Estado</th>
              <th className={style.th}>Pago</th>
              <th className={style.th}>Acciones</th>
              <th className={style.thres}>PreFactura</th>
              <th className={style.thres}>Factura</th>
              <th className={style.thres}>Cliente</th>
              <th className={style.thres}>Estado</th>
              <th className={style.thres}>Pago</th>
              <th className={style.thres}>Acciones</th>
            </tr>
            {actualQuotes !== undefined && actualQuotes.length > 0 && actualQuotes.map((orden) => (
              <tr className={style.tr} key={orden.id}>
                <td className={style.td}>00{orden.id}</td>
                <td className={style.td}>0{orden.reports !== undefined && orden.reports !== null && orden.reports[0] !== undefined && orden.reports[0]}</td>
                <td className={style.td}>0{orden.quotes !== undefined && orden.quotes !== null && orden.quotes[0] !== undefined && orden.quotes[0]}</td>
                <td className={style.td}>0{orden.preBills !== undefined && orden.preBills !== null && orden.preBills[0] !== undefined && orden.preBills[0]}</td>
                <td className={style.td}>{orden.bills !== undefined && orden.bills !== null && orden.bills[orden.bills.length - 1] !== 0 ? (orden.bills[orden.bills.length - 1]) : ("000")}</td>
                <td className={style.td}>{getClientName(allClients, orden.clientId)}</td>
                <td className={style.td} style={{ color: orden.state ? 'purple' : 'blue' }}>
                  {orden.state === true ? "Activo" : "Cerrado"}
                </td>
                <td className={style.td} style={{ color: orden.paymentState ? 'green' : 'red' }}>
                  {orden.paymentState === false ? "Pendiente" : "Confirmado"}
                </td>
                <td className={style.tdres}>0{orden.preBills !== undefined && null && orden.preBills[0] !== undefined && orden.preBills[0]}</td>
                <td className={style.tdres}>{orden.bills !== undefined && orden.bills !== null ? (orden.bills[orden.bills.length - 1]) : ("000")}</td>
                <td className={style.tdres}>{getClientName(allClients, orden.clientId)}</td>
                <td className={style.tdres}>{orden.state === true ? ("Activo") : ("Cerrado")}</td>
                <td className={style.tdres}>{orden.paymentState === false ? ("Pendiente") : ("Confirmado")}</td>
                <td className={style.tdetail}>
                  <Link to={`/services/view/${orden.id}`}>
                    <button className={style.botonUpdate}><img className={style.icon} src="https://api.iconify.design/material-symbols:open-in-new.svg?color=%23313372" alt="editbuttom" /></button>
                  </Link>
                  {orden.preBills !== null ? (<Link to={`/bills/create/${orden.preBills !== undefined && orden.preBills !== null && orden.preBills[0] !== undefined && orden.preBills[0]}/${orden.id}`}>
                    <button className={style.botonUpdate}> <img className={style.icon} src="https://api.iconify.design/material-symbols:add-circle-rounded.svg?color=%23313372" alt="editbuttom" /></button>
                  </Link>) : (null)}
                </td>
              </tr>))}
          </table>
          {sortedData !== undefined && sortedData.length > 0 &&
            <div className={style.paginado}>
              <button className={style.botonpag} onClick={() => setPagina(1)} disabled={pagina === 1}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:move-last-rtl.svg?color=%23313372" alt="editbuttom" /></button>
              <button className={style.botonpag} onClick={() => setPagina(pagina - 1)} disabled={pagina === 1}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:previous-ltr.svg?color=%23313372" alt="editbuttom" /></button>
              {pageNumbers.slice(inicioPaginas - 1, finPaginas).map((pageNumber) => (
                <button key={pageNumber} className={pageNumber === pagina ? style.pagina : style.paginaboton} onClick={() => setPagina(pageNumber)}>{pageNumber}</button>))}
              <button className={style.botonpag} onClick={() => setPagina(pagina + 1)} disabled={ultimoElemento >= sortedData.length}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:previous-rtl.svg?color=%23313372" alt="editbuttom" /></button>
              <button className={style.botonpag} onClick={() => setPagina(totalPages)} disabled={ultimoElemento >= sortedData.length}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:move-last-ltr.svg?color=%23313372" alt="editbuttom" /></button>
            </div>}
          <button type="button" className={style.backbutton} onClick={handleGoBack}>Volver</button>
        </div>
      </div>
    </div>
  )
}
export default MyServices