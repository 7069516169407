import style from "./createServices.module.css"
import NavBar from "../../../components/NavBar/NavBar"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom"
import { useState, useEffect } from "react"
import { useForm, Controller } from 'react-hook-form';
import * as actions from "../../../redux/actions"
import axios from "axios"
import { url, urlFront } from "../../../App";
import { ALERT_SESSION } from "../../../redux/action-types";
import AlertSession from "../../../components/AlertSession/AlertSession";

const CreateServices = () => {
    // Importations
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { handleSubmit, control, formState: { errors }, trigger, reset, watch, register, setValue } = useForm()
    // Global States
    const userData = useSelector((state) => state.userLoginNow)
    const allClients = useSelector((state) => state.allClients)
    const alertSession = useSelector((state) => state.alertSession)
    let client = watch("clientId")
    // Local states
    const [formDisabled, setFormDisabled] = useState(null)
    const [actualClient, setActualClient] = useState("")
    const [confirmRegistro, setConfirmRegistro] = useState(null);
    //UseEffect
    useEffect(() => {
        dispatch(actions.allStores())
        dispatch(actions.allClients())
        dispatch(actions.allParts())
    }, [dispatch])
    useEffect(() => {
        setActualClient(client)
    }, [client])
    // Funciones
    const onSubmit = async (data) => {
        data.userId = userData.id
        data.old = true
        try {
            setFormDisabled(true);
            const config = {
                method: 'post',
                url: `${url}/serviceReport/create`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                data: data
            };
            const response = await axios(config)
            if (response.data) {
                if (response.data.success === true) {
                    setConfirmRegistro(true)
                    setTimeout(() => {
                        setConfirmRegistro(null);
                        reset();
                        setFormDisabled(false);
                        setTimeout(() => {
                            navigate(`/services/edit/${response.data.serviceReport.id}`);
                        }, 100);
                    }, 2000);
                }
                else {
                    setConfirmRegistro(false)
                }
            }
        } catch (error) {
            console.error("Error al crear el servicio:", error);
            setFormDisabled(false);
            setConfirmRegistro(false)

            if(error.response.status === 401) {
                dispatch({
                    type: ALERT_SESSION,
                    payload: true
                })

                setTimeout(() => {
                    localStorage.clear('globalState')
                    window.location.replace(urlFront)
                }, [3000])
                
            }  
        }
    }
    // Renderizado
    return (
        <div className={style.divContenedor}>
            <div className={style.divNavBar}>
                <NavBar />
            </div>
            <div className={style.divPrincipal}>
            {alertSession && <AlertSession text1="Su sesión ha vencido" text2="Por favor, vuelva a iniciar sesión" disabledBtn={false}/>}
                <h1 className={style.tituloSeccion}>Servicio</h1>
                <h4 className={style.subtitulo}>Crear servicio</h4>
                <div className={style.divForm}>
                    <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
                        <div className={style.rowform}>
                            <div className={style.divDatos}>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="clientId"> Cliente </label>
                                    <div className={style.divInput}>
                                        <Controller name="clientId"
                                            control={control}
                                            defaultValue={""}
                                            rules={{ required: 'Seleccione el cliente' }}
                                            render={({ field }) => (
                                                <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("clientId") }}>
                                                    <option value="" disabled> Seleccione el cliente </option>
                                                    {allClients !== undefined ? allClients.map((client) => (
                                                        <option key={client.id} value={client.id}>
                                                            {client.name}</option>)) : null}
                                                </select>)} />
                                        <div className={style.errorMenssage}>
                                            {errors.clientId && <p className={style.errorText}>{errors.clientId.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="state"> Estado de servicio </label>
                                    <div className={style.divInput}>
                                        <Controller name="state"
                                            control={control}
                                            defaultValue={""}
                                            rules={{ required: 'Este campo es obligatorio' }}
                                            render={({ field }) => (
                                                <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("state"); }}>
                                                    <option value="" disabled> Seleccione el estado </option>
                                                    <option value={true}> Activo </option>
                                                    <option value={false}> Cerrado </option>
                                                </select>)} />
                                        <div className={style.errorMenssage}>
                                            {errors.state && <p className={style.errorText}>{errors.state.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="paymentState"> Estado de pago </label>
                                    <div className={style.divInput}>
                                        <Controller name="paymentState"
                                            control={control}
                                            defaultValue={""}
                                            rules={{ required: 'Este campo es obligatorio' }}
                                            render={({ field }) => (
                                                <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("paymentState"); }}>
                                                    <option value="" disabled> Seleccione el pago </option>
                                                    <option value={true} > Confirmado </option>
                                                    <option value={false} > Pendiente </option>
                                                </select>)} />
                                        <div className={style.errorMenssage}>
                                            {errors.paymentState && <p className={style.errorText}>{errors.paymentState.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="codeIdentificator">Identificador</label>
                                    <div className={style.divInput}>
                                        <Controller name="codeIdentificator"
                                            control={control}
                                            defaultValue={""}
                                            // rules={{ required: 'Este campo es obligatorio', maxLength: { value: 15, message: 'Máximo 15 caracteres' }, minLength: { value: 4, message: 'Minimo 4 caracteres' } }}
                                            render={({ field }) => (
                                                <input className={style.input} type="number" {...field} onChange={(e) => { field.onChange(e); trigger("codeIdentificator"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.codeIdentificator && <p className={style.errorText}>{errors.codeIdentificator.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="saleOrder">Orden de compra</label>
                                    <div className={style.divInput}>
                                        <Controller name="saleOrder"
                                            control={control}
                                            defaultValue={""}
                                            rules={{ required: 'Este campo es obligatorio', maxLength: { value: 15, message: 'Máximo 15 caracteres' }, minLength: { value: 1, message: 'Minimo 1 caracteres' } }}
                                            render={({ field }) => (
                                                <input className={style.input} type="number" {...field} onChange={(e) => { field.onChange(e); trigger("saleOrder"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.saleOrder && <p className={style.errorText}>{errors.saleOrder.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="stateDescription">Descripción de estado</label>
                                    <div className={style.divInput}>
                                        <Controller name="stateDescription"
                                            control={control}
                                            defaultValue={""}
                                            // rules={{ required: 'Este campo es obligatorio', maxLength: { value: 30, message: 'Maximo 30 caracteres' }, minLength: { value: 4, message: 'Minimo 4 caracteres' } }}
                                            render={({ field }) => (
                                                <input className={style.input} type="text" {...field} maxLength={30} onChange={(e) => { field.onChange(e); trigger("stateDescription"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.stateDescription && <p className={style.errorText}>{errors.stateDescription.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="total">Valor total</label>
                                    <div className={style.divInput}>
                                        <Controller name="total"
                                            control={control}
                                            defaultValue={""}
                                            rules={{ required: 'Este campo es obligatorio', maxLength: { value: 30, message: 'Maximo 30 caracteres' }, minLength: { value: 4, message: 'Minimo 4 caracteres' } }}
                                            render={({ field }) => (
                                                <input className={style.input} type="number" {...field} maxLength={30} onChange={(e) => { field.onChange(e); trigger("total"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.total && <p className={style.errorText}>{errors.total.message}</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="submit" className={style.formbutton} >Crear servicio</button>
                        {confirmRegistro === true ? (<><p className={style.positivo}>Servicio creado con exito</p></>) : (confirmRegistro === false ? (<><p className={style.negativo} > No se pudo registrar, vuelve a intentarlo</p></>) : (null))}
                        <Link to="/services">
                            <button type="button" className={style.backbutton}>Volver</button>
                        </Link>
                    </form>
                </div>
            </div>
        </div>
    )

}
export default CreateServices