import style from "./error.module.css"

const Error = () => {
    return (
        <>
        <div className={style.contenedor}>
            <div className={style.principal}>
            <img className={style.imagen}src="https://res.cloudinary.com/dfmsezslx/image/upload/v1700522308/TECDE/RefriActive_Img_zfhpl2.png" alt="error" />
            <h1 className={style.titulo}>ERROR 404</h1>
            <h2 className={style.subtitulo}>Page not found</h2>
            </div>
        </div>
        </>
    )

}

export default Error