import style from "./parts.module.css"
import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useForm, Controller } from 'react-hook-form';
import { Link } from "react-router-dom";
import axios from "axios";
import NavBar from "../../../components/NavBar/NavBar";
import * as actions from "../../../redux/actions"
import {url, urlFront} from "../../../App";
import Filtro from "../../../components/Filtro/Filtro";
import Search from "../../../components/Search/Search";
import { ALERT_SESSION } from "../../../redux/action-types";
import AlertSession from "../../../components/AlertSession/AlertSession";

const Parts = () => {
    //Importations
    const dispatch = useDispatch()
    const {handleSubmit, control, formState: {errors}, trigger, reset, watch} = useForm()
    //States
    const allParts = useSelector((state) => state.allParts)
    const allCitys = useSelector((state) => state.allCitys)
    const allDepartments = useSelector((state) => state.allDepartments)
    const alertSession = useSelector((state) => state.alertSession)
    const [filterCity, setFilterCity] = useState([])
    const [createClients, setCreateClients] = useState(false) 
    const [confirmRegistro, setConfirmRegistro] = useState(null);
    const [formDisabled, setFormDisabled] = useState(false);
    const filter = useSelector((state) => state.filterParts)
    const [data, setData] = useState(allParts)
    useEffect(()=> {
        if (filter !== undefined && filter.length > 0 && filter[0] !== "Sin resultados") {
          setData(filter)
        }
        else if (filter !== undefined && filter.length > 0 && filter[0] === "Sin resultados") {
          setData([])
        } 
        else if (filter !== undefined && filter.length === 0) {
          setData(allParts)
        }
    }, [allParts, filter])
    // UseEffect
    useEffect(()=> {
      if (!allDepartments || allDepartments.length === 0) {
          dispatch(actions.allDepartments())
      }
      if (!allCitys || allCitys.length === 0) {
        dispatch(actions.allCitys())
    }}, [])
    useEffect(() => {
      dispatch(actions.allParts())
    }, [dispatch])
    // Funtions
    const handleNewUser = () => {
        if (createClients) {
          setCreateClients(false)
        }
        else {
          setCreateClients(true)
        }
    }
    const handleChange = (e, allCitys) => {
        const departmentIdValue = e.target.value
        const citysById = allCitys.filter((city) => city.departmentId === parseInt(departmentIdValue))
        setFilterCity(citysById)
    }
    const onSubmit = async (data) => {
      try {
          setFormDisabled(true);
          const config = {
            method: 'post',
            url: `${url}/parts/create`,
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
            data: data
            };
          const response = await axios(config)
          if (response.data) {
              if (response.data.success === true) {
                  setConfirmRegistro(true)
              }
              else {
                  setConfirmRegistro(false)
              }
          }
          setTimeout(() => {
            setConfirmRegistro(null);
            reset();
            setFormDisabled(false);
            setTimeout(() => {
              window.location.reload();
            }, 100);
          }, 2000);
      } catch (error) {
        console.error("Error al crear almacen:", error);
        setFormDisabled(false);
        setConfirmRegistro(false)
        reset()

        if(error.response.status === 401) {
            dispatch({
                type: ALERT_SESSION,
                payload: true
            })

            setTimeout(() => {
                localStorage.clear('globalState')
                window.location.replace(urlFront)
            }, [3000])
            
        }  
      }
    };
    const getCityName = (allCitys, cityId) => {
        const city = allCitys && allCitys.find((city) => city.id === cityId);
        return city ? city.city : 'Ciudad Desconocida';
    };
    const getDeparmentsName = (allDepartments, departmentId) => {
      const department = allDepartments && allDepartments.find((department) => department.id === departmentId);
      return department ? department.department : 'Ciudad Desconocida'
    };
    // Paginado
    const [pagina, setPagina] = useState(1)
    const porPagina = 8
    const ultimoElemento = pagina*porPagina
    const primerElemento = ultimoElemento - porPagina
    const sortedData = data !== undefined && data.length > 0 && [...data].sort((a, b) => new Date(b.id) - new Date(a.id));
    const actualQuotes = sortedData !== undefined && sortedData.length > 0 && sortedData.slice(primerElemento, ultimoElemento)
    const totalPages = data !== undefined && data.length > 0 && Math.ceil(data.length / porPagina);
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);}
    useEffect(() => {setPagina(1)}, [totalPages]);
    const maxPaginasAMostrar = 3; // Número máximo de páginas que se mostrarán en la barra de paginación
    const inicioPaginas = pagina <= Math.ceil(maxPaginasAMostrar / 2) ? 1 : Math.max(1, pagina - Math.floor(maxPaginasAMostrar / 2))
    const finPaginas = Math.min( inicioPaginas + maxPaginasAMostrar - 1,totalPages);
    // Renderización
    return (
        <div className={style.divContenedor}>
          <div className={style.divNavBar}>
          <NavBar/>
          </div>
        <div className={style.contenedor}>
        {alertSession && <AlertSession text1="Su sesión ha vencido" text2="Por favor, vuelva a iniciar sesión" disabledBtn={false}/>}
          <div className={style.divComplementario}>
            <h1 className={style.titulo}>Repuestos</h1>
          </div>
          <Filtro section="parts"/>
          <Search section="parts" />
          <div className={style.divTabla}>
            <table className={style.table}>
              <tr className={style.tr}>
              <th className={style.th}>#</th>
                <th className={style.th}>Repuesto</th>
                <th className={style.th}>Categoria</th>
                <th className={style.th}>Descripcion</th>
                <th className={style.th}>Marca</th>
                <th className={style.th}>Provedor</th>
                <th className={style.th}>Departamento</th>
                <th className={style.th}>Ciudad</th>
                <th className={style.th}>Costo</th>
                <th className={style.th}>Precio</th>
                <th className={style.th}>Acciones</th>
                <th className={style.thres}>Repuesto</th>
                <th className={style.thres}>Ciudad</th>
                <th className={style.thres}>Costo</th>
                <th className={style.thres}>Acciones</th>
              </tr>
              {actualQuotes && actualQuotes.length > 0 && actualQuotes.map((part) => ( <>
                <tr className={style.tr} key={part.id}>
                    <td className={style.td}>{part.id}</td>
                    <td className={style.td}>{part.name}</td>
                    <td className={style.td}>{part.subCategory}</td>
                    <td className={style.td}>{part.description}</td>
                    <td className={style.td}>{part.brand}</td>
                    <td className={style.td}>{part.proveedor}</td>
                    <td className={style.td}>{getDeparmentsName(allDepartments, part.departmentId)}</td>
                    <td className={style.td}>{getCityName(allCitys, part.cityId)}</td>
                    <td className={style.td}>${part.cost.toLocaleString()}</td>
                    <td className={style.td}>${part.price.toLocaleString()}</td>
                    <td className={style.tdres}>{part.name}</td>
                    <td className={style.tdres}>{getCityName(allCitys, part.cityId)}</td>
                    <td className={style.tdres}>${part.cost.toLocaleString()}</td>
                  <td className={style.tdetail}>
                    <Link to={`/data/part/${part.id}`}>
                        <button className={style.botonUpdate} disabled={createClients}><img className={style.icon} src="https://api.iconify.design/material-symbols:edit.svg?color=%23313372" alt="editbuttom" /></button>
                    </Link>
                  </td>
                </tr>
                </>))}
            </table>
            {filter[0] === "Sin resultados" && 
            <div className={style.paginado}>
                <p className={style.mensaje}>Sin resultados en la búsqueda</p>
            </div> }
            {data !== undefined && data.length > 0 && 
                    <div className={style.paginado}>
                    <button className= {style.botonpag} onClick={() => setPagina(1)} disabled={pagina === 1}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:move-last-rtl.svg?color=%23313372" alt="editbuttom" /></button>
                    <button className= {style.botonpag} onClick={() => setPagina(pagina - 1)} disabled={pagina === 1}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:previous-ltr.svg?color=%23313372" alt="editbuttom" /></button>
                    {pageNumbers.slice(inicioPaginas - 1, finPaginas).map((pageNumber) => (
                    <button key={pageNumber} className={pageNumber === pagina ? style.pagina : style.paginaboton} onClick={() => setPagina(pageNumber)}>{pageNumber}</button>))}
                    <button className= {style.botonpag} onClick={() => setPagina(pagina + 1)} disabled={ultimoElemento >= data.length}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:previous-rtl.svg?color=%23313372" alt="editbuttom" /></button>
                    <button className= {style.botonpag} onClick={() => setPagina(totalPages)} disabled={ultimoElemento >= data.length}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:move-last-ltr.svg?color=%23313372" alt="editbuttom" /></button>
                  </div>}
            </div>
            <div className={style.newUser}>
                {createClients === false ? (<button className={style.formbutton} onClick={handleNewUser}> + Añadir nuevo repuesto</button>):(<button className={style.botonClose} onClick={handleNewUser}> X </button>)}
                {createClients === true ? (
                    <div className={style.divCreate}>
                        <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
                            <div className={style.divDatos}>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="name">Repuesto</label>
                                    <div className={style.divInput}>
                                        <Controller name="name"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: 'Este campo es obligatorio', maxLength: {value: 30,message: 'Máximo 30 caracteres'}, minLength: {value: 2,message: 'Minímo 2 caracteres'}} }
                                        render={({ field }) => (
                                        <input className={style.input} type="text" {...field} onChange={(e) => {field.onChange(e); trigger("name"); }}/>)}/>
                                        <div className={style.errorMenssage}>
                                            {errors.name && <p className={style.errorText}>{errors.name.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="subCategory"> Categoria </label>
                                    <div className={style.divInput}>
                                        <Controller name="subCategory"
                                        control={control}
                                        defaultValue={""}
                                        rules={{ required: 'Seleccione la categoría' }}
                                        render={({ field }) => (
                                    <select className={style.select} {...field} onChange={(e) => {field.onChange(e); trigger("subCategory") }}> 
                                        <option value="" disabled> Seleccione la categoría </option>
                                        <option value="Iluminación" > Iluminación </option>
                                        <option value="Refrigeración" > Refrigeración </option>
                                        <option value="Limpieza" > Limpieza </option>
                                        <option value="Otro" > Otro </option>
                                        </select>)} />
                                        <div className={style.errorMenssage}>
                                            {errors.subCategory && <p className={style.errorText}>{errors.subCategory.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="description">Descripción</label>
                                    <div className={style.divInput}>
                                        <Controller name="description"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: 'Este campo es obligatorio', maxLength: {value: 200,message: 'Máximo 200 caracteres'}, minLength: {value: 20,message: 'Minímo 20 caracteres'}} }
                                        render={({ field }) => (
                                        <textarea className={style.input} rows={Math.ceil(200/30)} {...field} onChange={(e) => {field.onChange(e); trigger("description"); }}/>)}/>
                                        <div className={style.errorMenssage}>
                                            {errors.description && <p className={style.errorText}>{errors.description.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="brand">Marca</label>
                                    <div className={style.divInput}>
                                        <Controller name="brand"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: 'Este campo es obligatorio', maxLength: {value: 30,message: 'Máximo 30 caracteres'}, minLength: {value: 2, message: 'Minímo 2 caracteres'}} }
                                        render={({ field }) => (
                                        <input className={style.input} type="text" {...field} onChange={(e) => {field.onChange(e); trigger("brand"); }}/>)}/>
                                        <div className={style.errorMenssage}>
                                            {errors.brand && <p className={style.errorText}>{errors.brand.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="proveedor">Proveedor</label>
                                    <div className={style.divInput}>
                                        <Controller name="proveedor"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: 'Este campo es obligatorio', maxLength: {value: 30,message: 'Máximo 30 caracteres'}, minLength: {value: 4,message: 'El Minímo 4 caracteres'}} }
                                        render={({ field }) => (
                                        <input className={style.input} type="text" {...field} onChange={(e) => {field.onChange(e); trigger("proveedor"); }}/>)}/>
                                        <div className={style.errorMenssage}>
                                            {errors.proveedor && <p className={style.errorText}>{errors.proveedor.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="departmentId"> Departamento </label>
                                    <div className={style.divInput}>
                                        <Controller name="departmentId"
                                        control={control}
                                        defaultValue={""}
                                        rules={{ required: 'Seleccione el depertamento' }}
                                        render={({ field }) => (
                                    <select className={style.select} {...field} onChange={(e) => {field.onChange(e); trigger("departmentId"); handleChange(e, allCitys) }}> 
                                        <option value="" disabled> Seleccione el departamento </option>
                                        {allDepartments !== undefined ? allDepartments.map((department) => (
                                        <option key={department.id} value={department.id}>
                                            {department.department}</option>)):null}
                                        </select>)} />
                                        <div className={style.errorMenssage}>
                                            {errors.departmentId && <p className={style.errorText}>{errors.departmentId.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="cityId"> Ciudad </label>
                                    <div className={style.divInput}>
                                        <Controller name="cityId"
                                        control={control}
                                        defaultValue={""}
                                        rules={{ required: 'Seleccione la ciudad' }}
                                        render={({ field }) => (
                                    <select className={style.select} {...field} onChange={(e) => {field.onChange(e); trigger("cityId"); }}> 
                                        <option value="" disabled> Seleccione la ciudad </option>
                                        {filterCity.map((city) => (
                                        <option key={city.id} value={city.id}>
                                            {city.city}</option>))}
                                        </select>)} />
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="cost">Costo</label>
                                    <div className={style.divInput}>
                                        <Controller name="cost"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: 'Este campo es obligatorio'} }
                                        render={({ field }) => (
                                        <input className={style.input} type="number" {...field} placeholder="COP $" onChange={(e) => {field.onChange(e); trigger("cost"); }}/>)}/>
                                        <div className={style.errorMenssage}>
                                            {errors.cost && <p className={style.errorText}>{errors.cost.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="price">Precio</label>
                                    <div className={style.divInput}>
                                        <Controller name="price"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: 'Este campo es obligatorio'} }
                                        render={({ field }) => (
                                        <input className={style.input} type="number" {...field} placeholder="COP $" onChange={(e) => {field.onChange(e); trigger("price"); }}/>)}/>
                                        <div className={style.errorMenssage}>
                                            {errors.price && <p className={style.errorText}>{errors.price.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                {/* <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="zoneId">Zona</label>
                                    <div className={style.divInput}>
                                        <Controller name="zoneId"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: 'Este campo es obligatorio'} }
                                        render={({ field }) => (
                                        <input className={style.input} type="number" {...field} onChange={(e) => {field.onChange(e); trigger("zoneId"); }}/>)}/>
                                        <div className={style.errorMenssage}>
                                            {errors.zoneId && <p className={style.errorText}>{errors.zoneId.message}</p>}
                                        </div>
                                    </div>
                                </div> */}
                        </div>
                        <div>
                            <button type="submit" className={style.formbutton} disabled={formDisabled} >Crear nuevo repuesto</button>
                            {confirmRegistro === true ? (<><p className={style.positivo}>Repuesto creado con exito</p></>):(confirmRegistro === false ? (<><p className={style.negativo} > No se pudo registrar, vuelve a intentarlo</p></>):(null))}
                        </div>
                        </form>
                    </div>):(null)}
                    <Link to="/data">
                    <button type="button" className={style.backbutton}>Volver</button>
                    </Link>
            </div>
        </div>

        </div>
      );
}

export default Parts





// const generatePassword = () => {
//     const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()-_=+[]{}|;:,.<>?';
//     const passwordLength = 8;
//     let password = '';
//     for (let i = 0; i < passwordLength; i++) {
//       const randomIndex = Math.floor(Math.random() * charset.length);
//       password += charset.charAt(randomIndex);
//     }
//     setGeneratedPassword(password);
//   };