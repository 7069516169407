import {ALL_SERVICES, SEARCH_SERVICES, FILTER_SERVICES, CLEAR_FILTER_SERVICES, ALERT_SESSION} from "../action-types.js";
import axios from "axios"
import {url, urlFront} from "../../App.js";

export const getAllServices = () => {
    return async (dispatch) => {
        try {
            const config = {
                method: 'get',
                url: `${url}/serviceReport`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            };
            const response = await axios(config)
            dispatch({
                type: ALL_SERVICES,
                payload: response.data.serviceReports
            })
        } catch (error) {
            const errorResponse = {};
            errorResponse.error = error.message;
            if(error.response.status === 401) {
                dispatch({
                    type: ALERT_SESSION,
                    payload: true
                })

                setTimeout(() => {
                    localStorage.clear('globalState')
                    window.location.replace(urlFront)
                }, [3000])
                
            }  
            return errorResponse;    
        } 
    }
}
export const getFilterServices = (filtros) => {
    return async (dispatch) => {
        try {
            const config = {
                method: 'post',
                url: `${url}/serviceReport/filter`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                data: filtros
            };
            const response = await axios(config)
            if (response.status === 200) {
                dispatch({
                    type: FILTER_SERVICES,
                    payload: response.data.quotes
                })
            }
            else if (response.status === 204) {
                dispatch({
                    type: FILTER_SERVICES,
                    payload: ["Sin resultados"]
                })
            }
        } catch (error) {
            const errorResponse = {};
            errorResponse.error = error.message;
            if(error.response.status === 401) {
                dispatch({
                    type: ALERT_SESSION,
                    payload: true
                })

                setTimeout(() => {
                    localStorage.clear('globalState')
                    window.location.replace(urlFront)
                }, [3000])
                
            }  
            return errorResponse;    
        } 
    }
}
export const getSearchServices = (partialId) => {
    return async (dispatch) => {
        try {
            const config = {
                method: 'post',
                url: `${url}/serviceReport/search/${partialId}`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            };
            const response = await axios(config)
            if (response.status === 200) {
                dispatch({
                    type: SEARCH_SERVICES,
                    payload: response.data.serviceReports
                })
            }
            else if (response.status === 204) {
                dispatch({
                    type: SEARCH_SERVICES,
                    payload: ["Sin resultados"]
                })
            }
        } catch (error) {
            const errorResponse = {};
            errorResponse.error = error.message;
            if(error.response.status === 401) {
                dispatch({
                    type: ALERT_SESSION,
                    payload: true
                })

                setTimeout(() => {
                    localStorage.clear('globalState')
                    window.location.replace(urlFront)
                }, [3000])
                
            }  
            return errorResponse;    
        } 
    }
}

export const getSearchServicesbyQuoteandPrebill = (datos) => {
    return async (dispatch) => {
        try {
            const config = {
                method: 'post',
                url: `${url}/serviceReport/idFilter`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                data: datos
            };
            const response = await axios(config)
            if (response.status === 200) {
                dispatch({
                    type: SEARCH_SERVICES,
                    payload: response.data.serviceReports
                })
            }
            else if (response.status === 204) {
                dispatch({
                    type: SEARCH_SERVICES,
                    payload: ["Sin resultados"]
                })
            }
        } catch (error) {
            const errorResponse = {};
            errorResponse.error = error.message;
            if(error.response.status === 401) {
                dispatch({
                    type: ALERT_SESSION,
                    payload: true
                })

                setTimeout(() => {
                    localStorage.clear('globalState')
                    window.location.replace(urlFront)
                }, [3000])
                
            }  
            return errorResponse;    
        } 
    }
}

export const clearFilterServices = () => {
    return {
      type: CLEAR_FILTER_SERVICES,
    };
  };