import { useEffect, useState } from "react"
import style from "./filtro.module.css"
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux"
import * as actions from "../../redux/actions"
import Client from "../../pages/Datos/Clientes/Client";


const FiltroAvanzado = (props) => {
    const { section } = props
    //Importations
    const dispatch = useDispatch()
    const { handleSubmit, control, trigger, reset, watch } = useForm()
    //States
    const allCitys = useSelector((state) => state.allCitys)
    const allDepartments = useSelector((state) => state.allDepartments)
    const allClients = useSelector((state) => state.allClients)
    const allStores = useSelector((state) => state.allStores)
    const allTechnicals = useSelector((state) => state.allTechnicals)
    const allSuppliers = useSelector((state) => state.allSuppliers)
    const [filterCity, setFilterCity] = useState([])
    const [filterStore, setFilterStore] = useState([])
    const [isChecked, setIsChecked] = useState(false);
    const handleToggle = () => {
        setIsChecked(!isChecked);
    };
    let client = watch("clientId")
    const [actualClient, setActualClient] = useState("")
    useEffect(() => {
        if (!allDepartments || allDepartments.length === 0) {
            dispatch(actions.allDepartments())
        }
        if (!allCitys || allCitys.length === 0) {
            dispatch(actions.allCitys())
        }
        if (!allSuppliers || allSuppliers.length === 0) {
            dispatch(actions.allSuppliers())
        }
    }, [])
    const [actualSection, setActualSection] = useState("")
    useEffect(() => {
        setActualSection(section)
    }, [])
    const clearFilter = () => {
        if (actualSection === "counts") {
            dispatch(actions.clearCounts())
            reset()
        }
    }
    const onSubmit = async (data) => {
        if (actualSection === "counts") {
            const dataSend = {
                supplierId: data.supplierId || undefined,
                expirationDate: (data.expirationDateStart && data.expirationDateEnd) ? [data.expirationDateStart, data.expirationDateEnd] : undefined,
                paymentDate: (data.paymentDateStart && data.paymentDateEnd) ? [data.paymentDateStart, data.paymentDateEnd] : undefined,
                state: data.state || undefined
            }
            dispatch(actions.filterActionCounts(dataSend))
        }
    }
    return (
        <div>
            <div className={style.contenedor}>
                <div className={style.filtro}>
                    <label className={style.label}>Filtros</label>
                    <label className={`${style.switch} ${isChecked ? style.checked : ''}`}>
                        <input type="checkbox" onChange={handleToggle} />
                        <span className={style.slider}></span>
                    </label>
                </div>
                {isChecked === true ? (
                    <div>
                        {actualSection === "counts" ? (
                            <div className={style.newUser}>
                                <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
                                    <div className={style.divDatos}>
                                        <div className={style.divCampo}>
                                            <label className={style.label} htmlFor="supplierId">Cliente</label>
                                            <div className={style.divInput}>
                                                <Controller name="supplierId"
                                                    control={control}
                                                    defaultValue={""}
                                                    render={({ field }) => (
                                                        <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("supplierId") }}>
                                                            <option value="" disabled>Seleccione el cliente</option>
                                                            {allSuppliers !== undefined ? allSuppliers.map((client) => (
                                                                <option key={client.id} value={client.id}>
                                                                    {client.name}</option>)) : null}
                                                        </select>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className={style.divCampo}>
                                            <label className={style.label} htmlFor="expirationDateStart">Fecha de Vencimiento (Inicio)</label>
                                            <div className={style.divInput}>
                                                <Controller name="expirationDateStart"
                                                    control={control}
                                                    defaultValue={""}
                                                    render={({ field }) => (
                                                        <input type="date" className={style.input} {...field} />
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <div className={style.divCampo}>
                                            <label className={style.label} htmlFor="expirationDateEnd">Fecha de Vencimiento (Fin)</label>
                                            <div className={style.divInput}>
                                                <Controller name="expirationDateEnd"
                                                    control={control}
                                                    defaultValue={""}
                                                    render={({ field }) => (
                                                        <input type="date" className={style.input} {...field} />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className={style.divCampo}>
                                            <label className={style.label} htmlFor="paymentDateStart">Fecha de Pago (Inicio)</label>
                                            <div className={style.divInput}>
                                                <Controller name="paymentDateStart"
                                                    control={control}
                                                    defaultValue={""}
                                                    render={({ field }) => (
                                                        <input type="date" className={style.input} {...field} />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className={style.divCampo}>
                                            <label className={style.label} htmlFor="paymentDateEnd">Fecha de Pago (Fin)</label>
                                            <div className={style.divInput}>
                                                <Controller name="paymentDateEnd"
                                                    control={control}
                                                    defaultValue={""}
                                                    render={({ field }) => (
                                                        <input type="date" className={style.input} {...field} />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className={style.divCampo}>
                                            <label className={style.label} htmlFor="state">Estado</label>
                                            <div className={style.divInput}>
                                                <Controller name="state"
                                                    control={control}
                                                    defaultValue={""}
                                                    render={({ field }) => (
                                                        <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("state") }}>
                                                            <option value="" disabled>Seleccione el estado</option>
                                                            <option value="true">Pagado</option>
                                                            <option value="false">Pendiente</option>
                                                        </select>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={style.divBotones}>
                                        <button type="submit" className={style.formbutton}>
                                            <img className={style.icon} src="https://api.iconify.design/material-symbols:manage-search.svg?color=%23313372" alt="editbutton" />
                                        </button>
                                        <button type="button" className={style.backbutton} onClick={() => clearFilter()}>
                                            <img className={style.icon} src="https://api.iconify.design/ic:baseline-clear.svg?color=%23313372" alt="editbutton" />
                                        </button>
                                    </div>
                                </form>
                            </div>
                        ) : null}
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default FiltroAvanzado