import style from "./alert.module.css"

const AlertSession = ({text1, text2, disabledBtn, handlerClickYes, handlerClickNo}) => {
    return (
        <>
        <div className={style.modal}>
                <div className={style.mcontent}>
                    <p>{text1}</p>
                    <p>{text2}</p>
                    {disabledBtn && 
                        <>
                            <button type="button" className={style.backbutton} onClick={handlerClickYes}>Si</button>
                            <button type="button" className={style.backbutton} onClick={handlerClickNo}>No</button>
                        </>
                    }
                </div>
            </div>
        </>
    )

}

export default AlertSession