import { LOGIN, LOGOUT, SET_LOGIN_STATUS, SET_USER_DATA, USER_EXIST, ALL_CLIENTS, GET_CITYS, GET_DEPARTMENTS, ALL_STORES, ALL_PARTS, ALL_QUOTES, ALL_USERS, FILTER_QUOTES, CLEAR_FILTER_QUOTES, CLEAR_FILTER_PARTS, CLEAR_FILTER_STORES, FILTER_PARTS, FILTER_STORES, SEARCH_PARTS, SEARCH_QUOTES, SEARCH_STORES, ALL_PREBILLS, FILTER_PREBILLS, SEARCH_PREBILLS, CLEAR_FILTER_PREBILLS, ALL_SERVICES, SEARCH_SERVICES, FILTER_SERVICES, CLEAR_FILTER_SERVICES, ALL_BILLS, FILTER_BILLS, SEARCH_BILLS, CLEAR_FILTER_BILLS, LOGREPORTS, SET_LOGIN_REPORT_STATUS, ALL_TECHNICAL, ALL_REPORTS, FILTER_REPORTS, SEARCH_REPORTS, CLEAR_FILTER_REPORTS, ALL_SUPPLIERS, SEARCH_SUPPLIERS, FILTER_SUPPLIERS, CLEAR_FILTER_SUPPLIERS, FILTER_COUNTS, ALL_COUNTS, SEARCH_COUNTS, CLEAR_FILTER_COUNTS, ALERT_SESSION } from "./action-types"

const initialState = {
  login: null,
  userExist: null,
  loginReport: null,
  userLoginNow: {},
  allClients: [],
  allDepartments: [],
  allCitys: [],
  allStores: [],
  allParts: [],
  allQuotes: [],
  allUsers: [],
  filterQuotes: [],
  filterParts: [],
  filterStores: [],
  allPreBill: [],
  filterPreBills: [],
  allServices: [],
  filterServices: [],
  allBills: [],
  filterBills: [],
  allTechnicals: [],
  filterReports: [],
  allReports: [],
  filterSuppliers: [],
  allSuppliers: [],
  allCounts: [],
  filterCounts: [],
  alertSession: false,
}

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    // User cases
    case ALL_USERS: {
      return {
        ...state,
        allUsers: action.payload.users
      }
    }
    case LOGIN: {
      return {
        ...state,
        login: action.payload.success,
        userLoginNow: action.payload.userLogin,
        intercomHash: action.payload.hash
      };
    }
    case LOGREPORTS: {
      return {
        ...state,
        loginReport: action.payload.success,
        techLoginReport: action.payload.byId,
        intercomHash: action.payload.hash
      };
    }
    case USER_EXIST: {
      return {
        ...state,
        userExist: action.payload.success,
        bodyUser: action.payload.userDto,
        intercomHash: action.payload.hash
      }
    }
    case LOGOUT: {
      return {
        ...state,
        login: action.payload,
        userLoginNow: initialState.userLoginNow
      }
    }
    case SET_USER_DATA: {
      return {
        ...state,
        userLoginNow: action.payload,
      }
    };
    case SET_LOGIN_STATUS: {
      return {
        ...state,
        login: action.payload,
      }
    };
    case SET_LOGIN_REPORT_STATUS: {
      return {
        ...state,
        loginReport: action.payload,
      }
    };
    // Clients cases 
    case ALL_CLIENTS: {
      return {
        ...state,
        allClients: action.payload.clients
      }
    }
    // Demography cases
    case GET_CITYS: {
      return {
        ...state,
        allCitys: action.payload.citys
      }
    }
    case GET_DEPARTMENTS: {
      return {
        ...state,
        allDepartments: action.payload.departments
      }
    }
    // Store cases
    case ALL_STORES: {
      return {
        ...state,
        allStores: action.payload.stores
      }
    }
    case FILTER_STORES: {
      if (state.filterStores.length > 0 && state.filterStores[0] !== "Sin resultados") {
        const payloadIds = action.payload.map(item => item.id);
        const filteredStores = state.filterStores.filter(store => payloadIds.includes(store.id));
        if (filteredStores.length === 0) {
          return {
            ...state,
            filterStores: ["Sin resultados"]
          };
        }
        else {
          return {
            ...state,
            filterStores: filteredStores
          };
        }
      }
      else if (state.filterStores.length === 0) {
        return {
          ...state,
          filterStores: action.payload
        }
      }
    }
    case SEARCH_STORES: {
      if (state.filterStores.length > 0 && state.filterStores[0] !== "Sin resultados") {
        const payloadIds = action.payload.map(item => item.id);
        const filteredStores = state.filterStores.filter(store => payloadIds.includes(store.id));
        if (filteredStores.length === 0) {
          return {
            ...state,
            filterStores: ["Sin resultados"]
          };
        }
        else {
          return {
            ...state,
            filterStores: filteredStores
          };
        }
      }
      else if (state.filterStores.length === 0) {
        return {
          ...state,
          filterStores: action.payload
        }
      }
    }
    case CLEAR_FILTER_STORES: {
      return {
        ...state,
        filterStores: []
      }
    }
    // Parts cases
    case ALL_PARTS: {
      return {
        ...state,
        allParts: action.payload.parts
      }
    }
    case FILTER_PARTS: {
      if (state.filterParts.length > 0 && state.filterParts[0] !== "Sin resultados") {
        const payloadIds = action.payload.map(item => item.id);
        const filteredParts = state.filterParts.filter(part => payloadIds.includes(part.id));
        if (filteredParts.length === 0) {
          return {
            ...state,
            filterParts: ["Sin resultados"]
          };
        }
        else {
          return {
            ...state,
            filterParts: filteredParts
          };
        }
      } else if (state.filterParts.length === 0) {
        return {
          ...state,
          filterParts: action.payload
        };
      }
    }
    case SEARCH_PARTS: {
      if (state.filterParts.length > 0 && state.filterParts[0] !== "Sin resultados") {
        const payloadIds = action.payload.map(item => item.id);
        const filteredParts = state.filterParts.filter(part => payloadIds.includes(part.id));
        if (filteredParts.length === 0) {
          return {
            ...state,
            filterParts: ["Sin resultados"]
          };
        }
        else {
          return {
            ...state,
            filterParts: filteredParts
          };
        }
      } else if (state.filterParts.length === 0) {
        return {
          ...state,
          filterParts: action.payload
        };
      }
    }
    case CLEAR_FILTER_PARTS: {
      return {
        ...state,
        filterParts: []
      }
    }
    // Quotes cases
    case ALL_QUOTES: {
      return {
        ...state,
        allQuotes: action.payload.quotes
      }
    }
    case FILTER_QUOTES: {
      return {
        ...state,
        filterQuotes: action.payload
      }
    }
    case SEARCH_QUOTES: {
      return {
        ...state,
        filterQuotes: action.payload
      }
    }
    case CLEAR_FILTER_QUOTES: {
      return {
        ...state,
        filterQuotes: []
      }
    }
    // PreBills cases
    case ALL_PREBILLS: {
      return {
        ...state,
        allPreBill: action.payload
      }
    }
    case FILTER_PREBILLS: {
      return {
        ...state,
        filterPreBills: action.payload
      }
    }
    case SEARCH_PREBILLS: {
      return {
        ...state,
        filterPreBills: action.payload
      }
    }
    case CLEAR_FILTER_PREBILLS: {
      return {
        ...state,
        filterPreBills: []
      }
    }
    // Services cases
    case ALL_SERVICES: {
      return {
        ...state,
        allServices: action.payload
      }
    }
    case FILTER_SERVICES: {
      return {
        ...state,
        filterServices: action.payload
      }
    }
    case SEARCH_SERVICES: {
      return {
        ...state,
        filterServices: action.payload
      }
    }
    case CLEAR_FILTER_SERVICES: {
      return {
        ...state,
        filterServices: []
      }
    }
    // Bills cases
    case ALL_BILLS: {
      return {
        ...state,
        allBills: action.payload
      }
    }
    case FILTER_BILLS: {
      return {
        ...state,
        filterBills: action.payload
      }
    }
    case SEARCH_BILLS: {
      return {
        ...state,
        filterBills: action.payload
      }
    }
    case CLEAR_FILTER_BILLS: {
      return {
        ...state,
        filterBills: []
      }
    }
    // Technical cases
    case ALL_TECHNICAL: {
      return {
        ...state,
        allTechnicals: action.payload
      }
    }
    // Reports cases 
    case ALL_REPORTS: {
      return {
        ...state,
        allReports: action.payload.reports
      }
    }
    case FILTER_REPORTS: {
      return {
        ...state,
        filterReports: action.payload
      }
    }
    case SEARCH_REPORTS: {
      return {
        ...state,
        filterReports: action.payload
      }
    }
    case CLEAR_FILTER_REPORTS: {
      return {
        ...state,
        filterReports: []
      }
    }
    // Suppliers cases 
    case ALL_SUPPLIERS: {
      return {
        ...state,
        allSuppliers: action.payload.elements
      }
    }
    case FILTER_SUPPLIERS: {
      return {
        ...state,
        filterSuppliers: action.payload
      }
    }
    case SEARCH_SUPPLIERS: {
      return {
        ...state,
        filterSuppliers: action.payload
      }
    }
    case CLEAR_FILTER_SUPPLIERS: {
      return {
        ...state,
        filterSuppliers: []
      }
    }
    // Counts cases 
    case ALL_COUNTS: {
      return {
        ...state,
        allCounts: action.payload.elements
      }
    }
    case FILTER_COUNTS: {
      return {
        ...state,
        filterCounts: action.payload
      }
    }
    case SEARCH_COUNTS: {
      return {
        ...state,
        filterCounts: action.payload
      }
    }
    case CLEAR_FILTER_COUNTS: {
      return {
        ...state,
        filterCounts: []
      }
    }
    case ALERT_SESSION: {
      return {
        ...state,
        alertSession: action.payload
      }
    }
    default:
      return state;
  }
};

export default rootReducer;