import React, { useEffect, useState } from "react"
import { useForm, Controller } from 'react-hook-form';
import NavBar from "../../../components/NavBar/NavBar"
import { useDispatch, useSelector } from "react-redux"
import { NavLink, Link, useNavigate} from "react-router-dom"
import style from "./users.module.css"
import * as actions from "../../../redux/actions"
import { url, urlFront } from "../../../App";
import axios from "axios";
import { ALERT_SESSION } from "../../../redux/action-types";
import AlertSession from "../../../components/AlertSession/AlertSession";


const Users = () => {
    const dispatch= useDispatch()
    const {handleSubmit, control, formState: {errors}, trigger, reset, watch} = useForm()
    const userData = useSelector((state) => state.userLoginNow)
    const allUsers = useSelector((state) => state.allUsers)
    const alertSession = useSelector((state) => state.alertSession)
    const [createUsers, setCreateUsers] = useState(false)
    const [confirmRegistro, setConfirmRegistro] = useState(null);
    const [formDisabled, setFormDisabled] = useState(false);
    const [generatedPassword, setGeneratedPassword] = useState('');
    const navigate = useNavigate()

    const generatePassword = () => {
        const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()-_=+[]{}|;:,.<>?';
        const passwordLength = 12;
        let password = '';
        for (let i = 0; i < passwordLength; i++) {
          const randomIndex = Math.floor(Math.random() * charset.length);
          password += charset.charAt(randomIndex);
        }
        setGeneratedPassword(password);
      };
    const handleNewUser = () => {
        if (createUsers) {
            setCreateUsers(false)
        }
        else {
            setCreateUsers(true)
        }
    }
    useEffect(() => {
        dispatch(actions.allUsers())
    }, [dispatch])
    const verifyEmail = async (email) => {
        try {
            const config = {
                method: 'get',
                url: `${url}/users/email/exist/${email}`,
                headers: {
                    'Content-Type': 'application/json',
                }
            };
            const res = await axios(config);
            console.log(res.data.success)
            const { success } = res.data;
            if (success === true) {
                return true
            }
            else {
                return false
            }
        } catch (error) {

            if(error.response.status === 401) {
                dispatch({
                    type: ALERT_SESSION,
                    payload: true
                })

                setTimeout(() => {
                    localStorage.clear('globalState')
                    window.location.replace(urlFront)
                }, [3000])
                
            }  
            return error.message;
        }
    }
    const onSubmit = async (data) => {
        data.password = generatedPassword;
        try {
            setFormDisabled(true);
            const config = {
                method: 'post',
                url: `${url}/users/create`,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: data
            };
            const response = await axios(config)
            if (response.data) {
                if (response.data.success === true) {
                    setConfirmRegistro(true)
                }
                else {
                    setConfirmRegistro(false)
                }
            }
            setTimeout(() => {
                setConfirmRegistro(null);
                setFormDisabled(false);
                setCreateUsers(false);
                reset()
              setTimeout(() => {
                window.location.reload();
                navigate("/data/user")
              }, 100);
            }, 2000);
        } catch (error) {
          console.error("Error al crear usuario:", error);
          setFormDisabled(false);
          setConfirmRegistro(false)
          reset()

          if(error.response.status === 401) {
            dispatch({
                type: ALERT_SESSION,
                payload: true
            })

            setTimeout(() => {
                localStorage.clear('globalState')
                window.location.replace(urlFront)
            }, [3000])
            
        }  
        }
    };
    const authHandle = async (id, authorized) => {
        const data = {authorized: !authorized}
        try {
            const config = {
                method: 'patch',
                url: `${url}/users/update-authorization/${id}`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                data: data
            };
            const response = await axios(config)
            if (response.data) {
                if (response.data.success === true) {
                    setTimeout(() => {
                          window.location.reload();
                          navigate("/data/user")
                      }, 500);
                }
            }
        } catch (error) {
          console.error("Error al actualizar usuario:", error);

          if(error.response.status === 401) {
            dispatch({
                type: ALERT_SESSION,
                payload: true
            })

            setTimeout(() => {
                localStorage.clear('globalState')
                window.location.replace(urlFront)
            }, [3000])
            
        }  
        }
    }
    return (
        <div className={style.divContenedor}>
          <div className={style.divNavBar}>
          <NavBar/>
          </div>
        <div className={style.contenedor}>
        {alertSession && <AlertSession text1="Su sesión ha vencido" text2="Por favor, vuelva a iniciar sesión" disabledBtn={false}/>}
          <div className={style.divComplementario}>
            <h1 className={style.titulo}>Usuarios</h1>
          </div>
          <div className={style.divTabla}>
            <table className={style.table}>
              <tr className={style.tr}>
              <th className={style.th}>#</th>
                <th className={style.th}>Nombre</th>
                <th className={style.th}>Apellido</th>
                <th className={style.th}>Email</th>
                <th className={style.th}>Perfil</th>
                <th className={style.th}>Activo</th>
                <th className={style.th}>Acciones</th>
                <th className={style.thres}>Nombre</th>
                <th className={style.thres}>Perfil</th>
                <th className={style.thres}>Acciones</th>
              </tr>
              {allUsers && allUsers.length > 0 && allUsers.map((store) => ( <>
                <tr className={style.tr} key={store.id}>
                <td className={style.td}>{store.id}</td>
                  <td className={style.td}>{store.name}</td>
                  <td className={style.td}>{store.surname}</td>
                  <td className={style.td}>{store.email}</td>
                  <td className={style.td}>{store.profile}</td>
                  <td className={style.tdres}>{store.name + " " + store.surname}</td>
                  <td className={style.tdres}>{store.profile}</td>
                  <td className={style.td}>{store.authorized === true ? "Sí":"No"}</td>
                  <td className={style.tdetail}>
                      <button className={style.botonUpdate} disabled={createUsers} onClick={()=>authHandle(store.id, store.authorized)}> {store.authorized === true ? <img className={style.iconPaginado} src="https://api.iconify.design/material-symbols:disabled-by-default-outline.svg?color=%23313372" alt="" />: <img className={style.iconPaginado} src="https://api.iconify.design/material-symbols:check-box-outline.svg?color=%23313372" alt="" /> } </button>
                  </td>
                </tr>
                </>))}
            </table>
            </div>
            <div className={style.newUser}>
                {createUsers === false ? (<button className={style.formbutton} onClick={handleNewUser}> + Nuevo usuario</button>):(<button className={style.botonClose} onClick={handleNewUser}> X </button>)}
                {createUsers === true ? (
                    <div>
                        <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
                            <div className={style.divDatos}>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="name">Nombre</label>
                                    <div className={style.divInput}>
                                        <Controller name="name"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: 'Este campo es obligatorio', maxLength: {value: 30,message: 'El nombre no puede tener más de 30 caracteres'}, minLength: {value: 4,message: 'El nombre no puede tener menos de 4 caracteres'}} }
                                        render={({ field }) => (
                                        <input className={style.input} type="text" {...field} onChange={(e) => {field.onChange(e); trigger("name"); }}/>)}/>
                                        <div className={style.errorMenssage}>
                                            {errors.name && <p className={style.errorText}>{errors.name.message}</p>}
                                        </div>
                                    </div>
                                </div> 
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="surname">Apellido</label>
                                    <div className={style.divInput}>
                                        <Controller name="surname"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: 'Este campo es obligatorio', maxLength: {value: 30,message: 'El apellido no puede tener más de 30 caracteres'}, minLength: {value: 4,message: 'El apellido no puede tener menos de 4 caracteres'}} }
                                        render={({ field }) => (
                                        <input className={style.input} type="text" {...field} onChange={(e) => {field.onChange(e); trigger("surname"); }}/>)}/>
                                        <div className={style.errorMenssage}>
                                            {errors.surname && <p className={style.errorText}>{errors.surname.message}</p>}
                                        </div>
                                    </div>
                                </div>
                        <div className={style.divCampo}>
                            <label className={style.label} htmlFor="email"> Correo electronico </label>
                            <div className={style.divInput}>
                                <Controller name="email"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: 'Este campo es obligatorio',
                                    pattern: {
                                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                      message: 'Este no es un correo electrónico válido',
                                    },
                                    validate: async (value) => {
                                        try {
                                          const success = await verifyEmail(value);
                                          if (success === true) {
                                            return "Correo ya registrado";
                                          } else {
                                            return true;
                                          }
                                        } catch (error) {
                                          console.error("Error al verificar el correo:", error);
                                          return "Error al verificar el correo";
                                        }
                                      }
                                }}
                                render={({ field }) => (
                                    <input className={style.input} type="text" {...field} onChange={(e) => {field.onChange(e); trigger("email")}}/>)}/>
                                <div className={style.errorMenssage}>
                                    {errors.email && <p className={style.errorText}>{errors.email.message}</p>}
                                </div>
                            </div>
                        </div>
                        <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="profile">Perfil</label>
                                    <div className={style.divInput}>
                                        <Controller name="profile"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: 'Este campo es obligatorio'} }
                                        render={({ field }) => (
                                            <select className={style.select} {...field} onChange={(e) => {field.onChange(e); trigger("cityId"); }}> 
                                            <option value="" disabled> Seleccione el perfil </option>
                                            <option value="superadmin" > Super-Administrador </option>
                                            <option value="admin" > Administrador </option>
                                            <option value="user" > Usuario </option>
                                            </select>)} />
                                        <div className={style.errorMenssage}>
                                            {errors.profile && <p className={style.errorText}>{errors.profile.message}</p>}
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <button type="button" className={style.formbutton} onClick={generatePassword} >Generar contraseña</button>
                        <p className={style.label}> Contraseña de usuario:   <span style={{ color: 'green' }}>  {generatedPassword}</span></p>
                        <div>
                            <button type="submit" className={style.formbutton} disabled={formDisabled} >Crear usuario</button>
                            {confirmRegistro === true ? (<><p className={style.positivo}>Usuario registrado con exito</p></>):(confirmRegistro === false ? (<><p className={style.negativo} >No se pudo registrar, vuelve a intentarlo</p></>):(null))}
                        </div>
                        </form>
                    </div>):(null)}
                    <Link to="/data">
                    <button type="button" className={style.backbutton}>Volver</button>
                    </Link>
            </div>
        </div>

        </div>
      );
}


export default Users