import style from "./createpreBill.module.css"
import NavBar from "../../../components/NavBar/NavBar"
import { useDispatch, useSelector } from "react-redux";
import { useLocation, NavLink, useNavigate, Link, useParams } from "react-router-dom"
import { useState, useEffect } from "react"
import { useForm, Controller } from 'react-hook-form';
import * as actions from "../../../redux/actions"
import axios from "axios"
import { url, urlFront } from "../../../App";
import { ALERT_SESSION } from "../../../redux/action-types";
import AlertSession from "../../../components/AlertSession/AlertSession";


const CreatePreBillByQuote = () => {
    // Importations
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams()
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Sumar 1 porque los meses van de 0 a 11
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    const { handleSubmit, control, formState: { errors }, trigger, reset, watch, register, setValue } = useForm()
    // Global States
    const userData = useSelector((state) => state.userLoginNow)
    const allClients = useSelector((state) => state.allClients)
    const allParts = useSelector((state) => state.allParts)
    const allCitys = useSelector((state) => state.allCitys)
    const allDepartments = useSelector((state) => state.allDepartments)
    const allStores = useSelector((state) => state.allStores)
    const alertSession = useSelector((state) => state.alertSession)
    let client = watch("clientId")
    let city = watch("cityId")
    let store = watch("storeId")
    // Local states
    const [formDisabled, setFormDisabled] = useState(null)
    const [filterCity, setFilterCity] = useState([])
    const [filterStore, setFilterStore] = useState([])
    const [actualClient, setActualClient] = useState("")
    const [actualCity, setActualCity] = useState("")
    const [actualStore, setActualStore] = useState("")
    const [selectedPart, setSelectedPart] = useState("");
    const [quantity, setQuantity] = useState("");
    const [serviceDescription, setServiceDescription] = useState("");
    const [servicePrice, setServicePrice] = useState("");
    const [quoteList, setQuoteList] = useState([])
    const [countItem, setCountItem] = useState(1)
    const [confirmRegistro, setConfirmRegistro] = useState(null);
    const [actualQuote, setActualQuote] = useState({})
    const [isDisabled, setIsDisabled] = useState(true);
    // Get de quote
    const handleChangeStore = () => {
        const storesById = allStores.filter((store) => store.cityId === parseInt(city))
        const storesByClient = storesById.filter((store) => store.clientId === parseInt(actualClient))
        setFilterStore(storesByClient)
    }
    const handleChangeClientId = (e) => {
        setValue('departmentId', "");
        trigger('departmentId');
        setValue('cityId', "");
        trigger('cityId');
        setValue('storeId', "");
        trigger('storeId');
    };
    useEffect(() => {
        const getActualQuote = async (quoteId) => {
            try {
                setFormDisabled(true);
                const config = {
                    method: 'get',
                    url: `${url}/quotes/${quoteId}`,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    withCredentials: true
                };
                const response = await axios(config)
                if (response.data) {
                    if (response.data.success === true) {
                        const quote = response.data.quote
                        setActualQuote(quote)
                        setQuoteList(quote.quoteParts)
                    }
                }
            } catch (error) {
                console.error("Error al crear tienda:", error);
                setFormDisabled(false);
                setConfirmRegistro(false)

                if(error.response.status === 401) {
                    dispatch({
                        type: ALERT_SESSION,
                        payload: true
                    })
    
                    setTimeout(() => {
                        localStorage.clear('globalState')
                        window.location.replace(urlFront)
                    }, [3000])
                    
                }  
            }
        }
        getActualQuote(id)
    }, [])
    useEffect(() => {
        setValue('reportId', actualQuote.reportId === 0 ? ("") : (actualQuote.reportId))
        setValue('quoteId', actualQuote.id);
        setValue("paymentMeth", actualQuote.paymentMeth)
        setValue("clientId", actualQuote.clientId)
        setValue("cityId", actualQuote.cityId)
        setValue("storeId", actualQuote.storeId)
    }, [actualQuote, setValue]);
    useEffect(() => {
        // Calcula el máximo valor de 'item'
        const maxItem = Math.max(...quoteList.map(obj => obj.item), 0);
        setCountItem(maxItem + 1);
    }, [quoteList]);
    //UseEffect
    useEffect(() => {
        dispatch(actions.allStores())
        dispatch(actions.allClients())
        dispatch(actions.allParts())
        dispatch(actions.allCitys())
    }, [dispatch])
    useEffect(() => {
        setActualClient(client)
        setActualCity(city)
        setActualStore(store)
    }, [client, city, store])
    // Funciones
    const sumOfTotalPrice = quoteList.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.totalCost;
    }, 0);
    const onSubmit = async (data) => {
        data.quoteParts = quoteList
        data.subtotal = sumOfTotalPrice
        data.iva = sumOfTotalPrice * 0.19
        data.total = data.subtotal + data.iva
        data.reportId = parseInt(data.reportId, 10)
        const dataClient = allClients.filter((client) => client.id === actualClient)
        data.clientData = dataClient[0]
        data.userId = userData.id
        const datos = data
        try {
            setFormDisabled(true);
            const get = {
                method: 'get',
                url: `${url}/reports`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true
            }
            const responses = await axios(get)
            if (responses.data) {
                if (responses.data.success === true) {
                    const tieneIdEspecifico = responses.data.reports.some(objeto => objeto.id === data.reportId);
                    if (tieneIdEspecifico) {
                        try {
                            setFormDisabled(true);
                            const config = {
                                method: 'post',
                                url: `${url}/preBills/create`,
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                withCredentials: true,
                                data: datos
                            };
                            const response = await axios(config)
                            if (response.data) {
                                if (response.data.success === true) {
                                    setConfirmRegistro(true)
                                    const newPreBill = response.data.created
                                    const dataService = {
                                        preBills: [newPreBill.id],
                                        quotes: [actualQuote.id],
                                        reports: [data.reportId],
                                        paymentState: false,
                                        state: true,
                                        total: actualQuote.total,
                                        clientId: data.clientData.id,
                                        userId: userData.id
                                    }
                                    try {
                                        setFormDisabled(true);
                                        const config = {
                                            method: 'post',
                                            url: `${url}/serviceReport/create`,
                                            headers: {
                                                'Content-Type': 'application/json',
                                            },
                                            withCredentials: true,
                                            data: dataService
                                        };
                                        const response = await axios(config)
                                        if (response.data) {
                                            if (response.data.success === true) {
                                                setConfirmRegistro(true)
                                            }
                                            else {
                                                setConfirmRegistro(false)
                                            }
                                        }
                                        setTimeout(() => {
                                            setConfirmRegistro(null);
                                            reset();
                                            setFormDisabled(false);
                                            setTimeout(() => {
                                                navigate("/preBills");
                                            }, 100);
                                        }, 2000);
                                    } catch (error) {
                                        console.error("Error al crear prefactura:", error);
                                        setFormDisabled(false);
                                        setConfirmRegistro(false)

                                        if(error.response.status === 401) {
                                            dispatch({
                                                type: ALERT_SESSION,
                                                payload: true
                                            })
                            
                                            setTimeout(() => {
                                                localStorage.clear('globalState')
                                                window.location.replace(urlFront)
                                            }, [3000])
                                            
                                        }  
                                    }
                                }
                                else {
                                    setConfirmRegistro(false)
                                }
                            }
                            setTimeout(() => {
                                setConfirmRegistro(null);
                                reset();
                                setFormDisabled(false);
                                setTimeout(() => {
                                    navigate("/preBills");
                                }, 100);
                            }, 2000);
                        } catch (error) {
                            console.error("Error al crear prefactura:", error);
                            setFormDisabled(false);
                            setConfirmRegistro(false)

                            if(error.response.status === 401) {
                                dispatch({
                                    type: ALERT_SESSION,
                                    payload: true
                                })
                
                                setTimeout(() => {
                                    localStorage.clear('globalState')
                                    window.location.replace(urlFront)
                                }, [3000])
                                
                            }  
                        }
                    }
                    else {
                        try {
                            const config = {
                                method: 'post',
                                url: `${url}/reports/create`,
                                headers: { 
                                    'Content-Type': 'application/json',
                                },
                                withCredentials: true,
                                data: { id: data.reportId },
                            };
                            const response = await axios(config)
    
                            if (response.data) {
                                if (response.data.success === true) {
                                    try {
                                        setFormDisabled(true);
                                        const config = {
                                            method: 'post',
                                            url: `${url}/preBills/create`,
                                            headers: {
                                                'Content-Type': 'application/json',
                                            },
                                            withCredentials: true,
                                            data: datos
                                        };
                                        const response = await axios(config)
                                        if (response.data) {
                                            if (response.data.success === true) {
                                                setConfirmRegistro(true)
                                                const newPreBill = response.data.created
                                                const dataService = {
                                                    preBills: [newPreBill.id],
                                                    quotes: [actualQuote.id],
                                                    reports: [data.reportId],
                                                    paymentState: false,
                                                    state: true,
                                                    total: actualQuote.total,
                                                    clientId: data.clientData.id,
                                                    userId: userData.id
                                                }
                                                try {
                                                    setFormDisabled(true);
                                                    const config = {
                                                        method: 'post',
                                                        url: `${url}/serviceReport/create`,
                                                        headers: {
                                                            'Content-Type': 'application/json',
                                                        },
                                                        withCredentials: true,
                                                        data: dataService
                                                    };
                                                    const response = await axios(config)
                                                    if (response.data) {
                                                        if (response.data.success === true) {
                                                            setConfirmRegistro(true)
                                                        }
                                                        else {
                                                            setConfirmRegistro(false)
                                                        }
                                                    }
                                                    setTimeout(() => {
                                                        setConfirmRegistro(null);
                                                        reset();
                                                        setFormDisabled(false);
                                                        setTimeout(() => {
                                                            navigate("/preBills");
                                                        }, 100);
                                                    }, 2000);
                                                } catch (error) {
                                                    console.error("Error al crear prefactura:", error);
                                                    setFormDisabled(false);
                                                    setConfirmRegistro(false)
    
                                                    if(error.response.status === 401) {
                                                        dispatch({
                                                            type: ALERT_SESSION,
                                                            payload: true
                                                        })
                                        
                                                        setTimeout(() => {
                                                            localStorage.clear('globalState')
                                                            window.location.replace(urlFront)
                                                        }, [3000])
                                                        
                                                    }  
                                                }
                                            }
                                            else {
                                                setConfirmRegistro(false)
                                            }
                                        }
                                        setTimeout(() => {
                                            setConfirmRegistro(null);
                                            reset();
                                            setFormDisabled(false);
                                            setTimeout(() => {
                                                navigate("/preBills");
                                            }, 100);
                                        }, 2000);
                                    } catch (error) {
                                        console.error("Error al crear prefactura:", error);
                                        setFormDisabled(false);
                                        setConfirmRegistro(false)
    
                                        if(error.response.status === 401) {
                                            dispatch({
                                                type: ALERT_SESSION,
                                                payload: true
                                            })
                            
                                            setTimeout(() => {
                                                localStorage.clear('globalState')
                                                window.location.replace(urlFront)
                                            }, [3000])
                                            
                                        }  
                                    }
                                }
                                else {
                                    setConfirmRegistro(false)
                                }
                            }
                        } catch (error) {
                            console.log(error);
                            if(error.response.status === 401) {
                                dispatch({
                                    type: ALERT_SESSION,
                                    payload: true
                                })
                
                                setTimeout(() => {
                                    localStorage.clear('globalState')
                                    window.location.replace(urlFront)
                                }, [3000])
                                
                            }  
                        }
                    }
                }
                else {
                    setConfirmRegistro(false)
                }
            }
        } catch (error) {
            console.error("Error al crear prefactura:", error);
            setFormDisabled(false);
            setConfirmRegistro(false)

            if(error.response.status === 401) {
                dispatch({
                    type: ALERT_SESSION,
                    payload: true
                })

                setTimeout(() => {
                    localStorage.clear('globalState')
                    window.location.replace(urlFront)
                }, [3000])
                
            }  
        }
    }
    // Renderizado
    return (
        <div className={style.divContenedor}>
            <div className={style.divNavBar}>
                <NavBar />
            </div>
            <div className={style.divPrincipal}>
            {alertSession && <AlertSession text1="Su sesión ha vencido" text2="Por favor, vuelva a iniciar sesión" disabledBtn={false}/>}
                <h1 className={style.tituloSeccion}>PreFacturas</h1>
                <h4 className={style.subtitulo}>Crear prefactura </h4>
                <h5 className={style.subtitulo}>A partir de Cotización # {actualQuote.id}</h5>
                <div className={style.divForm}>
                    <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
                        <div className={style.rowform}>
                            <div className={style.divDatos}>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="reportId">Número de reporte</label>
                                    <div className={style.divInput}>
                                        <Controller name="reportId"
                                            control={control}
                                            defaultValue={actualQuote.reportId}
                                            rules={{ required: 'Este campo es obligatorio', maxLength: { value: 15, message: 'Máximo 15 caracteres' }, minLength: { value: 4, message: 'Minimo 4 caracteres' } }}
                                            render={({ field }) => (
                                                <input className={style.input} type="number" {...field} onChange={(e) => { field.onChange(e); trigger("reportId"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.reportId && <p className={style.errorText}>{errors.reportId.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="quoteId">Número de cotización</label>
                                    <div className={style.divInput}>
                                        <Controller name="quoteId"
                                            control={control}
                                            defaultValue={actualQuote.id}
                                            disabled={isDisabled}
                                            rules={{ required: 'Este campo es obligatorio', maxLength: { value: 15, message: 'Máximo 15 caracteres' }, minLength: { value: 4, message: 'Minimo 4 caracteres' } }}
                                            render={({ field }) => (
                                                <input className={style.input} type="number" {...field} onChange={(e) => { field.onChange(e); trigger("quoteId"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.quoteId && <p className={style.errorText}>{errors.quoteId.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="reportRum">RUM</label>
                                    <div className={style.divInput}>
                                        <Controller name="reportRum"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: 'Este campo es obligatorio', maxLength: { value: 15, message: 'Máximo 15 caracteres' }, minLength: { value: 4, message: 'Minimo 4 caracteres' } }}
                                            render={({ field }) => (
                                                <input className={style.input} type="text" {...field} onChange={(e) => { field.onChange(e); trigger("reportRum"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.reportRum && <p className={style.errorText}>{errors.reportRum.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="actaEntrega">Acta de entrega</label>
                                    <div className={style.divInput}>
                                        <Controller name="actaEntrega"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: 'Este campo es obligatorio', maxLength: { value: 50, message: 'Máximo 50 caracteres' }, minLength: { value: 4, message: 'Minimo 4 caracteres' } }}
                                            render={({ field }) => (
                                                <input className={style.input} type="text" {...field} onChange={(e) => { field.onChange(e); trigger("actaEntrega"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.actaEntrega && <p className={style.errorText}>{errors.actaEntrega.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="date">Fecha</label>
                                    <div className={style.divInput}>
                                        <Controller
                                            name="date"
                                            control={control}
                                            defaultValue={formattedDate}
                                            rules={{ required: 'Este campo es obligatorio' }}
                                            render={({ field }) => (
                                                <input type="date" className={style.select} {...field} value={field.value} onChange={(e) => { field.onChange(e); trigger("date"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.date && <p className={style.errorText}>{errors.date.message}</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={style.divDatos}>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="clientId"> Cliente </label>
                                    <div className={style.divInput}>
                                        <Controller name="clientId"
                                            control={control}
                                            defaultValue={actualQuote.clientId}
                                            disabled={isDisabled}
                                            rules={{ required: 'Seleccione el cliente' }}
                                            render={({ field }) => (
                                                <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("clientId"); handleChangeClientId(e) }}>
                                                    <option value="" disabled> Seleccione el cliente </option>
                                                    {allClients !== undefined ? allClients.map((client) => (
                                                        <option key={client.id} value={client.id}>
                                                            {client.name}</option>)) : null}
                                                </select>)} />
                                        <div className={style.errorMenssage}>
                                            {errors.clientId && <p className={style.errorText}>{errors.clientId.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="cityId"> Ciudad </label>
                                    <div className={style.divInput}>
                                        <Controller name="cityId"
                                            control={control}
                                            defaultValue={actualQuote.cityId}
                                            disabled={isDisabled}
                                            rules={{ required: 'Seleccione la ciudad' }}
                                            render={({ field }) => (
                                                <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("cityId"); handleChangeStore(e, allStores) }}>
                                                    <option value="" disabled> Seleccione la ciudad </option>
                                                    {allCitys !== undefined ? allCitys.map((city) => (
                                                        <option key={city.id} value={city.id}>
                                                            {city.city}</option>)) : null}
                                                </select>)} />
                                        <div className={style.errorMenssage}>
                                            {errors.cityId && <p className={style.errorText}>{errors.cityId.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="storeId"> Almacen </label>
                                    <div className={style.divInput}>
                                        <Controller name="storeId"
                                            control={control}
                                            defaultValue={actualQuote.storeId}
                                            disabled={isDisabled}
                                            rules={{ required: 'Seleccione el almacen' }}
                                            render={({ field }) => (
                                                <select disabled={isDisabled} className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("storeId") }}>
                                                    <option value="" disabled> Seleccione el almacen </option>
                                                    {allStores.map((store) => (
                                                        <option key={store.id} value={store.id}>
                                                            {store.name}</option>))}
                                                </select>)} />
                                        <div className={style.errorMenssage}>
                                            {errors.storeId && <p className={style.errorText}>{errors.storeId.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="paymentMeth">Forma de Pago</label>
                                    <div className={style.divInput}>
                                        <Controller
                                            name="paymentMeth"
                                            control={control}
                                            defaultValue={actualQuote.paymentMeth}
                                            rules={{ required: 'Seleccione su forma de pago' }}
                                            render={({ field }) => (
                                                <select className={style.select} {...field} value={field.value} onChange={(e) => { field.onChange(e); trigger("paymentMeth"); }}>
                                                    <option value="" disabled>Seleccione la forma de pago</option>
                                                    <option value="A convenir">A convenir</option>
                                                    <option value="Contado">Contado</option>
                                                    <option value="Crédito hasta 30 días">Crédito a 30 días</option>
                                                    <option value="Crédito 45 días">Crédito a 45 días</option>
                                                    <option value="Crédito 60 días">Crédito a 60 días</option>
                                                    <option value="Crédito 90 días">Crédito a 90 días</option>
                                                </select>)} />
                                        <div className={style.errorMenssage}>
                                            {errors.paymentMeth && <p className={style.errorText}>{errors.paymentMeth.message}</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={style.divDatos}>
                            <h3 className={style.subtitulo}>Resumen de prefactura</h3>
                            <div className={style.divTabla}>
                                <table className={style.table}>
                                    <tr className={style.tr}>
                                        <th className={style.th}>Item</th>
                                        <th className={style.th}>Descripción</th>
                                        <th className={style.th}>Unidad</th>
                                        <th className={style.th}>Cantidad</th>
                                        <th className={style.th}>Valor unitario</th>
                                        <th className={style.th}>Valor total</th>
                                        <th className={style.thres}>Descripción</th>
                                        <th className={style.thres}>Cantidad</th>
                                        <th className={style.thres}>Valor unitario</th>
                                    </tr>
                                    {quoteList.map((orden) => (
                                        <tr className={style.tr} key={orden.item}>
                                            <td className={style.td}>{orden.item}</td>
                                            <td className={style.td}>{orden.description}</td>
                                            <td className={style.td}>{orden.unidad}</td>
                                            <td className={style.td}>{orden.quantity}</td>
                                            <td className={style.td}>$ {orden.costUnit.toLocaleString()}</td>
                                            <td className={style.td}>$ {orden.totalCost.toLocaleString()}</td>
                                            <td className={style.tdres}>{orden.description}</td>
                                            <td className={style.tdres}>{orden.quantity}</td>
                                            <td className={style.tdres}>$ {orden.costUnit.toLocaleString()}</td>
                                        </tr>))}
                                </table>
                            </div>
                        </div>
                        <button type="submit" className={style.formbutton} disabled={quoteList.length < 1} >Crear prefactura</button>
                        {confirmRegistro === true ? (<><p className={style.positivo}>Prefactura creada con éxito</p></>) : (confirmRegistro === false ? (<><p className={style.negativo} > No se pudo registrar, vuelve a intentarlo</p></>) : (null))}
                        <Link to="/quotes">
                            <button type="button" className={style.backbutton}>Volver</button>
                        </Link>
                    </form>
                </div>
            </div>
        </div>
    )

}
export default CreatePreBillByQuote