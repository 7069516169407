import style from "./createcount.module.css"
import NavBar from "../../../../components/NavBar/NavBar"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link, useParams } from "react-router-dom"
import { useState, useEffect } from "react"
import { useForm, Controller } from 'react-hook-form';
import * as actions from "../../../../redux/actions"
import axios from "axios"
import { url, urlFront } from "../../../../App";
import { ALERT_SESSION } from "../../../../redux/action-types";
import AlertSession from "../../../../components/AlertSession/AlertSession";



const UpdateCount = () => {
  // Importations
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Sumar 1 porque los meses van de 0 a 11
  const day = String(currentDate.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  const { handleSubmit, control, formState: { errors }, trigger, reset, watch, register, setValue } = useForm()
  // Global States
  const userData = useSelector((state) => state.userLoginNow)
  const allClients = useSelector((state) => state.allClients)
  const allParts = useSelector((state) => state.allParts)
  const allCitys = useSelector((state) => state.allCitys)
  const allSuppliers = useSelector((state) => state.allSuppliers)
  const allStores = useSelector((state) => state.allStores)
  const alertSession = useSelector((state) => state.alertSession)
  let state = watch("state")
  let retencion = watch("retencion")
  // Local states
  const [formDisabled, setFormDisabled] = useState(null)
  const [filterCity, setFilterCity] = useState([])
  const [filterStore, setFilterStore] = useState([])
  const [actualState, setActualState] = useState("")
  const [selectedPart, setSelectedPart] = useState("");
  const [quantity, setQuantity] = useState("");
  const [prePayDate, setPrePayDate] = useState("");
  const [quoteList, setQuoteList] = useState([])
  const [countItem, setCountItem] = useState(1)
  const [confirmRegistro, setConfirmRegistro] = useState(null);
  const [actualQuote, setActualQuote] = useState({})
  const [quote, setQuote] = useState({})
  const [valueServ, setValueServ] = useState("");
  // Get de quote
  const getSupplierName = (allSuppliers, id) => {
    const supplier = allSuppliers.find((supplier) => supplier.id === id);
    return supplier ? supplier.name : 'Proveedor desconocido';
  };
  const getActualQuote = async (quoteId) => {
    try {
      setFormDisabled(true);
      const config = {
        method: 'get',
        url: `${url}/counts/${quoteId}`,
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      };
      const response = await axios(config)
      if (response.data) {
        if (response.data.success === true) {
          const quote = response.data.element
          setActualQuote(quote)
          if (quote.prepays) {
            setQuoteList(quote.prepays)
          }
        }
      }
    } catch (error) {
      console.error("Error al crear tienda:", error);
      setFormDisabled(false);
      setConfirmRegistro(false)

      if(error.response.status === 401) {
        dispatch({
            type: ALERT_SESSION,
            payload: true
        })

        setTimeout(() => {
            localStorage.clear('globalState')
            window.location.replace(urlFront)
        }, [3000])
        
    }  
    }
  }
  useEffect(() => {
    setValue('name', actualQuote.name);
    setValue('supplierId', actualQuote.supplierId)
    setValue("date", actualQuote.date)
    setValue('expirationDate', actualQuote.expirationDate);
    setValue('state', actualQuote.state === null ? "" : (actualQuote.state === true ? "true": "false"));
    setValue('valor', actualQuote.valor);
    setValue("paymentDate", actualQuote.paymentDate)
    setValue("description", actualQuote.description)
    setValue("comments", actualQuote.comments)
    setValue("retencion", actualQuote.retencion === null ? "" : (actualQuote.retencion === true ? "true": "false"))
    setValue("valorRetencion", actualQuote.valorRetencion)
  }, [actualQuote, setValue]);
  useEffect(() => {
    getActualQuote(id)
  }, [dispatch])
  useEffect(() => {
    // Calcula el máximo valor de 'item'
    const maxItem = Math.max(...quoteList.map(obj => obj.item), 0);
    setCountItem(maxItem + 1);
  }, [quoteList]);
  //UseEffect
  useEffect(() => {
    dispatch(actions.allStores())
    dispatch(actions.allClients())
    dispatch(actions.allParts())
    dispatch(actions.allSuppliers())
  }, [dispatch])
  useEffect(() => {
    setActualState(state)
  }, [state])
  // Funciones
  const addServiceToQuote = () => {
    const service = {
      item: countItem,
      date: prePayDate,
      valor: parseInt(valueServ),
    };
    quoteList.push(service)
    setCountItem(countItem + 1)
  }
  const handleServiceChange = (e) => {
    setPrePayDate(e.target.value);
  };
  const handleQuantiServiceChange = (e) => {
    setValueServ(e.target.value);
  };
  function removeItemById(array, id) {
    const index = array.findIndex(item => item.item === id);

    if (index !== -1) {
        // El elemento con el ID proporcionado fue encontrado, procedemos a eliminarlo
        array.splice(index, 1);
    }
    setCountItem(countItem - 1)
}
  const onSubmit = async (data) => {
    try {
      setFormDisabled(true);
      data.prepays = quoteList
      const config = {
        method: 'patch',
        url: `${url}/counts/update/${id}`,
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
        data: data
      };
      const response = await axios(config)
      if (response.data) {
        if (response.data.success === true) {
          setConfirmRegistro(true)
        }
        else {
          setConfirmRegistro(false)
        }
      }
      setTimeout(() => {
        setConfirmRegistro(null);
        reset();
        setFormDisabled(false);
        setTimeout(() => {
          navigate("/accounting/counts");
        }, 100);
      }, 2000);
    } catch (error) {
      console.error("Error al actualizar cuenta:", error);
      setFormDisabled(false);
      setConfirmRegistro(false)
      if(error.response.status === 401) {
        dispatch({
            type: ALERT_SESSION,
            payload: true
        })

        setTimeout(() => {
            localStorage.clear('globalState')
            window.location.replace(urlFront)
        }, [3000])
        
    }  
    }
  }
  const handleGoBack = () => {
    navigate(-1); // Navegar hacia atrás, equivalente a history.goBack()
  }
  // Renderizado
  return (
    <div className={style.divContenedor}>
      <div className={style.divNavBar}>
        <NavBar />
      </div>
      <div className={style.divPrincipal}>
        {alertSession && <AlertSession text1="Su sesión ha vencido" text2="Por favor, vuelva a iniciar sesión" disabledBtn={false}/>}
        <h1 className={style.tituloSeccion}>Cuentas por pagar</h1>
        <h4 className={style.subtitulo}>Actualizar cuenta # {actualQuote.id}</h4>
        <div className={style.divForm}>
          <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
            <div className={style.rowform}>
              <div className={style.divDatos}>
                <div className={style.divCampo}>
                  <label className={style.label} htmlFor="supplierId"> Proovedor </label>
                  <div className={style.divInput}>
                    <Controller name="supplierId"
                      control={control}
                      defaultValue={""}
                      rules={{ required: 'Seleccione el proveedor' }}
                      render={({ field }) => (
                        <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("supplierId") }}>
                          <option value="" disabled> Seleccione el proveedor </option>
                          {allSuppliers !== undefined ? allSuppliers.map((client) => (
                            <option key={client.id} value={client.id}>
                              {client.name}</option>)) : null}
                        </select>)} />
                    <div className={style.errorMenssage}>
                      {errors.supplierId && <p className={style.errorText}>{errors.supplierId.message}</p>}
                    </div>
                  </div>
                </div>
                <div className={style.divCampo}>
                  <label className={style.label} htmlFor="valor">Valor de la cuenta</label>
                  <div className={style.divInput}>
                    <Controller name="valor"
                      control={control}
                      defaultValue=""
                      rules={{ required: 'Este campo es obligatorio', maxLength: { value: 30, message: 'Maximo 30 caracteres' }, minLength: { value: 4, message: 'Minimo 4 caracteres' } }}
                      render={({ field }) => (
                        <input className={style.input} type="number" {...field} onChange={(e) => { field.onChange(e); trigger("valor"); }} />)} />
                    <div className={style.errorMenssage}>
                      {errors.valor && <p className={style.errorText}>{errors.valor.message}</p>}
                    </div>
                  </div>
                </div>
                <div className={style.divCampo}>
                  <label className={style.label} htmlFor="date">Fecha de registro</label>
                  <div className={style.divInput}>
                    <Controller
                      name="date"
                      control={control}
                      defaultValue={formattedDate}
                      rules={{ required: 'Este campo es obligatorio' }}
                      render={({ field }) => (
                        <input type="date" className={style.select} {...field} value={field.value} onChange={(e) => { field.onChange(e); trigger("date"); }} />)} />
                    <div className={style.errorMenssage}>
                      {errors.date && <p className={style.errorText}>{errors.date.message}</p>}
                    </div>
                  </div>
                </div>
                <div className={style.divCampo}>
                  <label className={style.label} htmlFor="expirationDate">Fecha de vencimiento</label>
                  <div className={style.divInput}>
                    <Controller
                      name="expirationDate"
                      control={control}
                      defaultValue={formattedDate}
                      rules={{ required: 'Este campo es obligatorio' }}
                      render={({ field }) => (
                        <input type="date" className={style.select} {...field} value={field.value} onChange={(e) => { field.onChange(e); trigger("expirationDate"); }} />)} />
                    <div className={style.errorMenssage}>
                      {errors.expirationDate && <p className={style.errorText}>{errors.expirationDate.message}</p>}
                    </div>
                  </div>
                </div>
                <div className={style.divCampo}>
                  <label className={style.label} htmlFor="state">Estado de Pago</label>
                  <div className={style.divInput}>
                    <Controller
                      name="state"
                      control={control}
                      defaultValue=""
                      rules={{ required: 'Seleccione su forma de pago' }}
                      render={({ field }) => (
                        <select className={style.select} {...field} value={field.value} onChange={(e) => { field.onChange(e); trigger("state"); }}>
                          <option value="" disabled>Seleccione el estado</option>
                          <option value={true}>Pagado</option>
                          <option value={false}>Pendiente</option>
                        </select>)} />
                    <div className={style.errorMenssage}>
                      {errors.state && <p className={style.errorText}>{errors.state.message}</p>}
                    </div>
                  </div>
                </div>
                {state === "true" && <div className={style.divCampo}>
                  <label className={style.label} htmlFor="paymentDate">Fecha de pago efectuado</label>
                  <div className={style.divInput}>
                    <Controller
                      name="paymentDate"
                      control={control}
                      defaultValue={formattedDate}
                      rules={{ required: 'Este campo es obligatorio' }}
                      render={({ field }) => (
                        <input type="date" className={style.select} {...field} value={field.value} onChange={(e) => { field.onChange(e); trigger("date"); }} />)} />
                    <div className={style.errorMenssage}>
                      {errors.paymentDate && <p className={style.errorText}>{errors.paymentDate.message}</p>}
                    </div>
                  </div>
                </div>}
                <div className={style.divCampo}>
                  <label className={style.label} htmlFor="retencion">¿Aplica retención?</label>
                  <div className={style.divInput}>
                    <Controller
                      name="retencion"
                      control={control}
                      defaultValue=""
                      rules={{ required: 'Seleccione su forma de pago' }}
                      render={({ field }) => (
                        <select className={style.select} {...field} value={field.value} onChange={(e) => { field.onChange(e); trigger("retencion"); }}>
                          <option value="" disabled>Seleccione</option>
                          <option value={true}>Aplica</option>
                          <option value={false}>No aplica</option>
                        </select>)} />
                    <div className={style.errorMenssage}>
                      {errors.retencion && <p className={style.errorText}>{errors.retencion.message}</p>}
                    </div>
                  </div>
                </div>
                {retencion === "true" && <div className={style.divCampo}>
                  <label className={style.label} htmlFor="valorRetencion">Porcentaje de retención</label>
                  <div className={style.divInput}>
                    <Controller name="valorRetencion"
                      control={control}
                      defaultValue=""
                      rules={{ required: 'Este campo es obligatorio', maxLength: { value: 3, message: 'Maximo 2 caracteres' } }}
                      render={({ field }) => (
                        <input className={style.input} type="number" placeholder="Numero entero, ejemplo 6 si es 6%" {...field} onChange={(e) => { field.onChange(e); trigger("valorRetencion"); }} />)} />
                    <div className={style.errorMenssage}>
                      {errors.valorRetencion && <p className={style.errorText}>{errors.valorRetencion.message}</p>}
                    </div>
                  </div>
                </div>}
                <div className={style.divCampo}>
                  <label className={style.label} htmlFor="description"> Número de factura </label>
                  <div className={style.divInput}>
                    <Controller name="description"
                      control={control}
                      defaultValue=""
                      rules={{ maxLength: { value: 100, message: 'Maximo 100 caracteres' } }}
                      render={({ field }) => (
                        <textarea className={style.input} rows={1} type="text" {...field} onChange={(e) => { field.onChange(e); trigger("description"); }} />)} />
                    <div className={style.errorMenssage}>
                      {errors.description && <p className={style.errorText}>{errors.description.message}</p>}
                    </div>
                  </div>
                </div>
                <div className={style.divCampo}>
                  <label className={style.label} htmlFor="comments"> Comentarios/descripcion </label>
                  <div className={style.divInput}>
                    <Controller name="comments"
                      control={control}
                      defaultValue=""
                      rules={{ maxLength: { value: 100, message: 'Maximo 100 caracteres' } }}
                      render={({ field }) => (
                        <textarea className={style.input} rows={Math.ceil(100 / 30)} type="text" {...field} onChange={(e) => { field.onChange(e); trigger("comments"); }} />)} />
                    <div className={style.errorMenssage}>
                      {errors.comments && <p className={style.errorText}>{errors.comments.message}</p>}
                    </div>
                  </div>
                </div>
              </div>
              <div className={style.divDatos}>
                <label className={style.label}>Añadir anticipo</label>
                <div className={style.divCampo}>
                  <label className={style.label}>Fecha</label>
                  <div className={style.divInput}>
                    <input type="date" className={style.input} rows={Math.ceil(150 / 30)} maxLength={500} onChange={(e) => { handleServiceChange(e) }} />
                  </div>
                </div>
                <div className={style.divCampo}>
                  <label className={style.label}>Valor</label>
                  <div className={style.divInput}>
                    <input className={style.input} maxLength={10} type="number" onChange={(e) => { handleQuantiServiceChange(e) }} />
                  </div>
                </div>
                <button className={style.formbutton} onClick={addServiceToQuote} type="button" disabled={valueServ === "" || prePayDate === ""} >Añadir servicio</button>
              </div>
            </div>
            <div className={style.divDatos}>
              <h3 className={style.subtitulo}>Resumen de anticipos</h3>
              <div className={style.divTabla}>
                <table className={style.table}>
                  <tr className={style.tr}>
                    <th className={style.th}>Item</th>
                    <th className={style.th}>Fecha</th>
                    <th className={style.th}>Valor</th>
                    <th className={style.th}>Acciones</th>
                    <th className={style.thres}>Item</th>
                    <th className={style.thres}>Fecha</th>
                    <th className={style.thres}>Valor</th>
                    <th className={style.thres}>Acciones</th>
                  </tr>
                  {quoteList.map((orden) => (
                    <tr className={style.tr} key={orden.item}>
                      <td className={style.td}>{orden.item}</td>
                      <td className={style.td}>{orden.date}</td>
                      <td className={style.td}>${orden.valor.toLocaleString()}</td>
                      <td className={style.tdres}>{orden.item}</td>
                      <td className={style.tdres}>{orden.date}</td>
                      <td className={style.tdres}>$ {orden.valor.toLocaleString()}</td>
                      <td className={style.tdetail}> <button type="button" className={style.deleteItem} onClick={() => removeItemById(quoteList, orden.item)}> Eliminar </button> </td>
                    </tr>))}
                </table>
              </div>
              </div>
              <button type="submit" className={style.formbutton} >Actualizar cuenta</button>
              {confirmRegistro === true ? (<><p className={style.positivo}>Cuenta actualizada con exito</p></>) : (confirmRegistro === false ? (<><p className={style.negativo} > No se pudo registrar, vuelve a intentarlo</p></>) : (null))}
              <button type="button" className={style.backbutton} onClick={handleGoBack}>Volver</button>
          </form>
        </div>
      </div>
    </div>
  )
}
export default UpdateCount