import style from "./quotes.module.css"
import NavBar from "../../components/NavBar/NavBar"
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"
import * as actions from "../../redux/actions"
import Filtro from "../../components/Filtro/Filtro";
import Search from "../../components/Search/Search";


const AllQuotes = () => {
  const dispatch = useDispatch()
  const allQuotes = useSelector((state) => state.allQuotes)
  const allClients = useSelector((state) => state.allClients)
  const allStores = useSelector((state) => state.allStores)
  const allCitys = useSelector((state) => state.allCitys)
  const filterQuotes = useSelector((state) => state.filterQuotes)
  const [data, setData] = useState(allQuotes)
  useEffect(() => {
    if (filterQuotes !== undefined && filterQuotes.length > 0 && filterQuotes[0] !== "Sin resultados") {
      setData(filterQuotes)
    }
    else if (filterQuotes !== undefined && filterQuotes.length > 0 && filterQuotes[0] === "Sin resultados") {
      setData([])
    }
    else {
      setData(allQuotes)
    }
  }, [allQuotes, filterQuotes])
  const sortedData = data !== undefined && data.length > 0 && [...data].sort((a, b) => new Date(b.id) - new Date(a.id));
  // UseEffect 
  useEffect(() => {
    if (!allCitys || allCitys.length === 0) {
      dispatch(actions.allCitys())
    }
    if (!allClients || allClients.length === 0) {
      dispatch(actions.allClients())
    }
    if (!allStores || allStores.length === 0) {
      dispatch(actions.allStores())
    }
  }, [])
  useEffect(() => {
    dispatch(actions.allQuotes())
  }, [dispatch])
  // Paginado
  const [pagina, setPagina] = useState(1)
  const porPagina = 10
  const ultimoElemento = pagina * porPagina
  const primerElemento = ultimoElemento - porPagina
  const actualQuotes = sortedData !== undefined && sortedData.length > 0 && sortedData.slice(primerElemento, ultimoElemento)
  const totalPages = sortedData !== undefined && sortedData.length > 0 && Math.ceil(sortedData.length / porPagina);
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }
  useEffect(() => { setPagina(1) }, [totalPages]);
  const maxPaginasAMostrar = 4; // Número máximo de páginas que se mostrarán en la barra de paginación
  const inicioPaginas = pagina <= Math.ceil(maxPaginasAMostrar / 2) ? 1 : Math.max(1, pagina - Math.floor(maxPaginasAMostrar / 2))
  const finPaginas = Math.min(inicioPaginas + maxPaginasAMostrar - 1, totalPages);
  // Traer nombres 
  const getClientName = (allClients, clientId) => {
    const client = allClients.find((client) => client.id === clientId);
    return client ? client.name : 'Cliente desconocido';
  };
  const getStoreName = (allStores, storeId) => {
    const store = allStores.find((store) => store.id === storeId);
    return store ? store.name : 'Tienda desconocido';
  };
  const getCityName = (allCitys, cityId) => {
    const city = allCitys && allCitys.find((city) => city.id === cityId);
    return city ? city.city : 'Ciudad Desconocida';
  };
  const navigate = useNavigate()
  const handleGoBack = () => {
    navigate(-1); // Navegar hacia atrás, equivalente a history.goBack()
  };
  // Renderizado
  return (
    <div className={style.divContenedor}>
      <div className={style.divNavBar}>
        <NavBar />
      </div>
      <div className={style.divPrincipal}>
        <h1 className={style.tituloSeccion}>Cotizaciones</h1>
        <h4 className={style.subtitulo}>Todas las cotizaciones</h4>
        <Filtro section="quotes" />
        <Search section="quotes" />
        <div className={style.divTabla}>
          <table className={style.table}>
            <tr className={style.tr}>
              <th className={style.th}>#</th>
              <th className={style.th}>Reporte</th>
              <th className={style.th}>Fecha</th>
              <th className={style.th}>Cliente</th>
              <th className={style.th}>Ciudad</th>
              <th className={style.th}>Almacen</th>
              <th className={style.th}>Total</th>
              <th className={style.th}>Acciones</th>
              <th className={style.thres}>#</th>
              <th className={style.thres}>Fecha</th>
              <th className={style.thres}>Cliente</th>
              <th className={style.thres}>Almacen</th>
              <th className={style.thres}>Total</th>
              <th className={style.thres}>Acciones</th>
            </tr>
            {actualQuotes !== undefined && actualQuotes.length > 0 && actualQuotes.map((orden) => (
              <tr className={style.tr} key={orden.id}>
                <td className={style.td}>{orden.id}</td>
                <td className={style.td}>{orden.reportId}</td>
                <td className={style.td}>{orden.aplicationDate}</td>
                <td className={style.td}>{getClientName(allClients, orden.clientId)}</td>
                <td className={style.td}>{getCityName(allCitys, orden.cityId)}</td>
                <td className={style.td}>{getStoreName(allStores, orden.storeId)}</td>
                <td className={style.td}>$ {orden.total.toLocaleString()}</td>
                <td className={style.tdres}>{orden.id}</td>
                <td className={style.tdres}>
                  {(() => {
                    // Supongamos que orden.aplicationDate es una cadena de fecha en el formato "YYYY-MM-DD"
                    var fechaString = orden.aplicationDate;
                    var fecha = new Date(fechaString);
                    var mes = fecha.getMonth() + 1;
                    var dia = fecha.getDate();

                    // Crear una nueva cadena con el formato deseado (MM-DD)
                    var nuevaFechaString = (mes < 10 ? '0' : '') + mes + '-' + (dia < 10 ? '0' : '') + dia;

                    return nuevaFechaString;
                  })()}
                </td>
                <td className={style.tdres}>{getClientName(allClients, orden.clientId)}</td>
                <td className={style.tdres}>{getStoreName(allStores, orden.storeId)}</td>
                <td className={style.tdres}>$ {orden.total.toLocaleString()}</td>
                <td className={style.tdetail}>
                  <Link to={`/quotes/view/${orden.id}`}>
                    <button className={style.botonUpdate}><img className={style.icon} src="https://api.iconify.design/material-symbols:open-in-new.svg?color=%23313372" alt="editbuttom" /></button>
                  </Link>
                  <Link to={`/quotes/${orden.id}`}>
                    <button className={style.botonUpdate}><img className={style.icon} src="https://api.iconify.design/material-symbols:edit.svg?color=%23313372" alt="editbuttom" /></button>
                  </Link>
                  <Link to={`/preBills/create/${orden.id}`}>
                    <button className={style.botonUpdate}> <img className={style.icon} src="https://api.iconify.design/material-symbols:add-circle-rounded.svg?color=%23313372" alt="editbuttom" /></button>
                  </Link>
                </td>
              </tr>))}
          </table>
          {filterQuotes[0] === "Sin resultados" &&
            <div className={style.paginado}>
              <p className={style.mensaje}>Sin resultados en la búsqueda</p>
            </div>}
          {sortedData !== undefined && sortedData.length > 0 &&
            <div className={style.paginado}>
              <button className={style.botonpag} onClick={() => setPagina(1)} disabled={pagina === 1}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:move-last-rtl.svg?color=%23313372" alt="editbuttom" /></button>
              <button className={style.botonpag} onClick={() => setPagina(pagina - 1)} disabled={pagina === 1}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:previous-ltr.svg?color=%23313372" alt="editbuttom" /></button>
              {pageNumbers.slice(inicioPaginas - 1, finPaginas).map((pageNumber) => (
                <button key={pageNumber} className={pageNumber === pagina ? style.pagina : style.paginaboton} onClick={() => setPagina(pageNumber)}>{pageNumber}</button>))}
              <button className={style.botonpag} onClick={() => setPagina(pagina + 1)} disabled={ultimoElemento >= sortedData.length}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:previous-rtl.svg?color=%23313372" alt="editbuttom" /></button>
              <button className={style.botonpag} onClick={() => setPagina(totalPages)} disabled={ultimoElemento >= sortedData.length}><img className={style.iconPaginado} src="https://api.iconify.design/ooui:move-last-ltr.svg?color=%23313372" alt="editbuttom" /></button>
            </div>}
          <div>
            <button type="button" className={style.backbutton} onClick={handleGoBack}>Volver</button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default AllQuotes