import { ALL_BILLS, FILTER_BILLS, SEARCH_BILLS, CLEAR_FILTER_BILLS, COMBINED_FILTER_BILLS, ALERT_SESSION} from "../action-types.js";
import axios from "axios"
import {url, urlFront} from "../../App.js";


export const getAllBills = () => {
    return async (dispatch) => {
        try {
            
            const config = {
                method: 'get',
                url: `${url}/bills`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            };
            const response = await axios(config)
            dispatch({
                type: ALL_BILLS,
                payload: response.data.allElements
            })
            
        } catch (error) {
            const errorResponse = {};
            errorResponse.error = error.message;

            if(error.response.status === 401) {
                dispatch({
                    type: ALERT_SESSION,
                    payload: true
                })

                setTimeout(() => {
                    localStorage.clear('globalState')
                    window.location.replace(urlFront)
                }, [3000])
                
            }  
            return errorResponse;    
        }
    } 
}
export const getFilterBills = (filtros) => {
    return async (dispatch) => {
        try {

            const config = {
                method: 'post',
                url: `${url}/bills/filter`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                data: filtros
            };
            const response = await axios(config)
            if (response.status === 200) {
                dispatch({
                    type: FILTER_BILLS,
                    payload: response.data.bills
                })
            }
            else if (response.status === 204) {
                dispatch({
                    type: FILTER_BILLS,
                    payload: ["Sin resultados"]
                })
            }
        
        } catch (error) {
            const errorResponse = {};
            errorResponse.error = error.message;

            if(error.response.status === 401) {
                dispatch({
                    type: ALERT_SESSION,
                    payload: true
                })

                setTimeout(() => {
                    localStorage.clear('globalState')
                    window.location.replace(urlFront)
                }, [3000])
                
            }  
            return errorResponse;    
        }
    } 
}
export const getSearchBills = (filter) => {
    return async (dispatch) => {
    try {
        const config = {
            method: 'post',
            url: `${url}/bills/idFilter`,
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
            data: filter
        };
        const response = await axios(config)
        if (response.status === 200) {
            dispatch({
                type: SEARCH_BILLS,
                payload: response.data.bills
            })
        }
        else if (response.status === 204) {
            dispatch({
                type: SEARCH_BILLS,
                payload: ["Sin resultados"]
            })
        }
    } catch (error) {
            const errorResponse = {};
            errorResponse.error = error.message;
            if(error.response.status === 401) {
                dispatch({
                    type: ALERT_SESSION,
                    payload: true
                })

                setTimeout(() => {
                    localStorage.clear('globalState')
                    window.location.replace(urlFront)
                }, [3000])
                
            }  
            return errorResponse;    
        }
    }
} 
export const clearFilterBills = () => {
    return {
      type: CLEAR_FILTER_BILLS,
    };
  };